export default {
  methods: {
    viewTask: function (item) {
      this.routeTo({
        name: "Task",
        query: {
          milestoneLine: item.milestoneLine,
          documentNo: item.documentNo,
          activityLine: item.activityLine,
          lineNo: item.lineNo,
        },
      });
    },
    scrollLeft: function () {
      this.$refs.ganttRef.scrollTo({
        left: this.$refs.ganttRef.scrollLeft - 200,
        behavior: "smooth",
      });
    },
    scrollRight: function () {
      this.$refs.ganttRef.scrollTo({
        left: this.$refs.ganttRef.scrollLeft + 200,
        behavior: "smooth",
      });
    },
  },
};
