<template>
  <div>
    <v-card class="mx-auto mt-2" flat v-if="isData">
      <v-row>
        <v-col cols="12" md="12" lg="12" sm="12">
          <v-card-title v-if="category.length === 0 && location.length === 0">
            <span class="text-body-1 font-weight-bold">Inventory Items</span>
            <v-spacer />
            <v-btn
              v-if="selectedItems.length > 0"
              class="primary text-capitalize mx-2"
              @click="replenishItems"
            >
              Replenish Selected Items
            </v-btn>
            <span class="text-body-2"
              >Showing page {{ page }} of {{ total_pages }}</span
            >
          </v-card-title>
          <v-divider />
          <v-card class="mx-auto" flat v-for="(item, i) in itemsData" :key="i">
            <v-card-title>
              <span class="secondary--text text-body-1 font-weight-bold"
                >Items in {{ i }}</span
              >
              <v-spacer />
              <span class="text-body-2"
                >Showing Items {{ item.length }} of
                <span v-if="category.length === 0 && location.length === 0">
                  {{ totalLocationItems(i) }}</span
                ></span
              >
            </v-card-title>
            <v-divider />
            <v-data-table
              :headers="headers"
              :items="item"
              :items-per-page="items_per_page"
              class="elevation-0 mt-1"
              :item-class="styleRows"
              disable-pagination
              hide-default-footer
              v-model="selectedItems"
              :single-select="false"
              show-select
              item-key="Item_No"
            >
            </v-data-table>
            <v-divider :key="i" />
          </v-card>
          <v-card-actions>
            <v-spacer />
            <div class="d-flex justify-center mb-10 mt-2">
              <v-btn
                class="text-lowercase pa-2 mx-2 primary"
                @click="goToPrevious"
                :disabled="!$route.query.cursor"
              >
                <v-icon>mdi-chevron-left</v-icon>
                previous
              </v-btn>
              <v-btn
                class="text-lowercase pa-2 mx-2 primary"
                @click="goToNext"
                :disabled="$route.query.cursor === linkCursor"
              >
                <v-icon>mdi-chevron-right</v-icon>
                next
              </v-btn>
            </div>
            <v-spacer />
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mx-auto" flat v-else>
      <v-card-text>
        <v-skeleton-loader
          type="table"
          :loading="true"
          :rows="10"
          :headers="headers"
        ></v-skeleton-loader>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import inventoryMixin from "../inventoryMixin";
import FileMixin from "@/packages/cassandra-base/mixins/FileMixin";
export default {
  name: "InventoryList",
  mixins: [inventoryMixin, FileMixin],
  data() {
    return {
      items_per_page: 20,
      selectedItem: [],
      selectedCategory: [],
      selectedLocation: [],
      selectedItems: [],
      page: 1,
    };
  },
  computed: {
    items() {
      return this.$store.getters["Inventory/inventoryGetters"]("items");
    },
    itemsData() {
      return this.items ? (this.items.data ? this.items.data : []) : [];
    },
    linkCursor() {
      return this.items
        ? this.getParams(this.items.next_page_url).cursor
        : null;
    },
    isData() {
      return this.$store.getters["Inventory/inventoryGetters"]("isDataLoaded");
    },
    total_pages() {
      return Math.round(
        this.categories.reduce((a, b) => a + b.Items, 0) / this.items_per_page
      );
    },
    excelLink() {
      return this.$store.getters["Inventory/inventoryGetters"]("excelLink");
    },
    headers() {
      return [
        {
          text: "Name",
          value: "Description",
        },
        {
          text: "Available Quantity",
          value: "AvailableQty",
        },
        {
          text: "Qty. In Location",
          value: "Quantity",
        },
        {
          text: "Min. Replenishable",
          value: "MinimumQty",
        },
        {
          text: "Max. Replenishable",
          value: "MaximumQty",
        },
        {
          text: "Safety Stock",
          value: "SafetyQty",
        },
        // {
        //   text: "Variance",
        //   value: "Variance",
        // },
        // {
        //   text: "No.",
        //   align: "start",
        //   value: "Item_No",
        // },
        {
          text: "Actions",
          align: "start",
          value: "actions",
        },
      ];
    },
  },
  methods: {
    goToNext() {
      this.$router.push({
        query: this.getParams(this.items.next_page_url),
      });
    },
    goToPrevious() {
      if (this.$route.query.cursor) {
        this.$router.back();
      } else {
        this.$store.dispatch("Inventory/getItems");
      }
    },
    selectItem(item) {
      console.log("selected item", item);
    },
    getParams(url) {
      if (!url) return {};
      const paramsString = url.split("?");
      const params = paramsString[1].split("&");
      const paramsObj = {};
      params.forEach((param) => {
        const keyValue = param.split("=");
        paramsObj[keyValue[0]] = keyValue[1];
      });
      return paramsObj;
    },
    totalLocationItems(location) {
      const locations = this.locations.filter((l) => l.name === location);
      return locations[0] ? locations[0].Items : 0;
    },
    replenishItems() {
      this.$router.push({
        name: "RequisitionCard",
        params: {
          code: btoa("Purchase Requisition"),
        },
        query: {
          items: btoa(this.selectedItems.map((item) => item.Item_No).join(",")),
        },
      });
    },
    styleRows(event) {
      if (event.Variance <= 0) {
        return "amber--text";
      } else {
        return "blue--text";
      }
    },
  },
  watch: {
    $route: {
      handler: function (val) {
        if (val.query.cursor) {
          this.page += 1;
          this.$store.dispatch("Inventory/getItems", val.query);
        } else {
          this.$store.dispatch("Inventory/getItems");
        }
      },
      deep: true,
    },
    excelLink: {
      handler: function (val) {
        if (val) {
          this.downloadLink(`${process.env.VUE_APP_FILE_API}${val}`);
        }
      },
      deep: true,
    },
  },
};
</script>
