<template>
  <v-dialog persistent v-model="dialog" width="90%" height="100%">
    <v-card class="elevation-0" height="100%">
      <div class="report--title">
        <v-card-title>
          <v-btn text small @click="$router.back()">
            <v-icon left> mdi-arrow-left </v-icon>
            close
          </v-btn>
          <v-spacer />
          <v-btn small color="secondary" @click="downloadLink(document)">
            <v-icon left> mdi-download </v-icon>
            Download
          </v-btn>
        </v-card-title>
        <v-divider />
      </div>
      <v-row>
        <v-col cols="8" offset="2">
          <v-card-text class="elevation-0 my-1">
            <pdf
              style="width: 100%"
              v-for="i in pageCount(document)"
              :key="i"
              :page="i"
              :src="`data:application/pdf;base64,${document}`"
            />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import pdf from "vue-pdf";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";
export default {
  name: "TimesheetReport",
  mixins: [FileMixin],
  components: { pdf },
  data: function () {
    return {
      dialog: true,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Timesheet/getTimesheetReport", {
        period: to.params.period,
        employeeNo: v.user.employee,
      });
    });
  },
  computed: {
    document() {
      return this.$store.getters["Timesheet/timesheetGetters"]("report");
    },
    user: function () {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {},
};
</script>
<style>
.report--title {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
