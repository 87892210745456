<template>
  <v-dialog v-model="dialog" persistent width="50%">
    <v-card class="mx-auto">
      <v-card-title class="activity--title">
        <span class="text-lg-h5"
          >{{ isDelete ? "Update" : "Create" }} Activity</span
        >
        <v-spacer />
        <v-btn
          class="deep-orange text-capitalize white--text mx-2"
          @click="deleteActivity"
          v-if="isDelete"
        >
          <v-icon class="mx-2"> delete </v-icon>
          Delete
        </v-btn>
        <v-btn icon class="error" @click="$router.back()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form v-model="isValid" ref="activityCard">
          <v-row class="mt-3">
            <v-col cols="12" md="12">
              <v-combobox
                outlined
                v-model="activityTitle"
                dense
                placeholder="Enter Activity title"
                persistent-placeholder
                prepend-inner-icon="subtitles"
                :items="activitiesSetup"
                :rules="rules.activity"
                :return-object="false"
                @keyup="updateActivityDetails($event)"
              >
                <template v-slot:label>
                  <span class="text-capitalize">Activity Title</span>
                  <span class="red--text">*</span>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" md="12" class="mt-n6">
              <v-textarea
                outlined
                v-model="formData.description"
                dense
                placeholder="Enter description"
                persistent-placeholder
                prepend-inner-icon="subtitles"
                :rules="rules.description"
                rows="2"
              >
                <template v-slot:label>
                  <span class="text-capitalize">Description</span>
                  <span class="red--text">*</span>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <MilestoneAttachments
          :attachments="formData.attachments"
          :line="activity"
          @addAttachment="addAttachment"
          @removeAttachment="removeAttachment"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions class="activity--actions">
        <v-spacer />
        <v-btn color="primary" class="text-capitalize" @click="saveActivity">
          <v-icon class="mx-2">save</v-icon>
          Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import RouteMixin from "../mixins/RouteMixin";
import DateMixin from "../mixins/DateMixin";
import MilestoneAttachments from "./MilestoneAttachments";
export default {
  name: "MilestoneActivityCard",
  mixins: [RouteMixin, DateMixin],
  components: {
    MilestoneAttachments,
  },
  data() {
    return {
      dialog: true,
      isValid: false,
      formData: {
        activity: "",
        description: "",
        attachments: [],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (to.query.entryNo) {
        v.$store.dispatch("Projects/getActivity", {
          ...v.decryptRoute(to).query,
        });
      }
      v.$store.dispatch("Setup/getActivitiesSetup");
    });
  },
  mounted() {
    Event.$on("gotoMilestone", () => {
      this.routeTo({
        name: "Milestone",
        query: {
          documentNo: this.decryptRoute(this.$route).query.documentNo,
          milestoneLine: parseInt(this.$route.query.milestoneLine),
        },
      });
    });
  },
  computed: {
    rules() {
      return {
        description: [(v) => !!v || "Description is required"],
        activity: [(v) => !!v || "Activity is required"],
      };
    },
    activity: function () {
      return this.$store.getters["Projects/projectGetters"]("activity");
    },
    isEdit: function () {
      return this.$route.query.entryNo ? !this.formData.complete : true;
    },
    isDelete: function () {
      return this.$route.query.entryNo ? !this.formData.complete : false;
    },
    activitiesSetup: function () {
      const activity =
        this.$store.getters["Setup/setupGetter"]("activitiesSetup");
      return activity
        ? activity.map((x) => ({ text: x.description, value: x.code }))
        : [];
    },
    activityTitle: {
      get() {
        const activity = this.activitiesSetup.find(
          (x) => x.value === this.formData.activity
        );
        return activity || this.formData.activity;
      },
      set(value) {
        this.formData.activity = value;
        const activity = this.activitiesSetup.find((x) => x.value === value);
        this.formData.description = activity ? activity.text : "";
      },
    },
  },
  methods: {
    saveActivity() {
      if (this.$refs.activityCard.validate()) {
        let data = { ...this.formData };
        data.milestoneLine = parseInt(
          this.decryptRoute(this.$route).query.milestoneLine
        );
        data.documentNo = this.decryptRoute(this.$route).query.documentNo;
        data.milestone = this.decryptRoute(this.$route).query.milestone;
        // check if attachment is empty
        if (data.attachments.length === 0) delete data.attachments;
        if (this.$route.query.entryNo) {
          data = {
            ...data,
            ...this.decryptRoute(this.$route).query,
          };
          this.$store.dispatch("Projects/updateActivity", data);
        } else {
          this.$store.dispatch("Projects/saveActivity", data);
        }
      }
    },
    deleteActivity() {
      this.$confirm.show({
        title: "Delete Activity",
        text: "Are you sure you want to delete this activity?",
        onConfirm: () => {
          this.$store.dispatch("Projects/deleteActivity", {
            ...this.activity,
          });
        },
      });
    },
    updateActivityDetails(event) {
      const activity = this.activitiesSetup.find(
        (x) => x.value === event.target.value
      );
      if (!activity) this.formData.description = "";
    },
    addAttachment(attachment) {
      this.formData.attachments.push({ ...attachment });
    },
    removeAttachment(attachment) {
      this.formData.attachments.splice(
        this.formData.attachments.findIndex((a) => a.name === attachment.name),
        1
      );
    },
  },
  watch: {
    activity: {
      handler: function (val) {
        if (val) {
          this.formData = { ...val };
          this.formData.startDate === "0001-01-01"
            ? (this.formData.startDate = "")
            : "";
          this.formData.finishDate === "0001-01-01"
            ? (this.formData.finishDate = "")
            : "";
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.activity--title {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.activity--card.v-sheet.v-card {
  border-radius: 0 10px 10px 0 !important;
}

.activity--actions {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
}
</style>
