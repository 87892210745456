import projectStore from "./projectStore";
import projectRoutes from "./projectRoutes";
// import projectLinks from "./projectLinks";

export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoute(...projectRoutes);
    }

    if (options.store) {
      options.store.registerModule("Projects", projectStore);
      // options.store.commit("Dashboard/ADD_LINK", projectLinks);
    }
  },
};
