import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
const user = AuthService.user;
const caption = user
  ? user.captions
    ? user.captions.pettycash_caption
    : undefined
  : undefined;
export default [
  {
    order: 7,
    name: caption ? caption : "Petty Cash",
    icon: "credit_card",
    to: "/pettycash",
    enabled: true,
  },
];
