export default {
  getAllowance: "hrmis/allowance/leave_allowance",
  storeAllowance: "hrmis/allowance/leave_allowance",
  patchAllowance: (param) =>
    `hrmis/allowance/leave_allowance/update?${buildQuery(param)}`,
  showAllowance: (param) => `hrmis/allowance/show?applicationCode=${param}`,
  deleteAllowance: (param) =>
    `hrmis/allowance/leave_allowance/delete?${buildQuery(param)}`,
  sendApproval: "hrmis/allowance/sendApproval",
  cancelApproval: "hrmis/allowance/cancelApproval",
  allowanceDocument: "hrmis/allowance/allowanceDocument",
  reject: "hrmis/allowance/reject",
  approve: "hrmis/allowance/approve",
  pendingApprovals: "hrmis/allowance/pendingApprovals",
  dimensions: "hrmis/imprest/dimensions",
  GetEmployeeAllowance: "hrmis/allowance/GetEmployeeAllowance",
  CheckIfAppliedLeaveAllowance: "hrmis/allowance/CheckIfAppliedLeaveAllowance",
};

function buildQuery(param) {
  const query = new URLSearchParams(param);
  return query.toString();
}
