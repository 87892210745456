<template>
  <v-card class="mx-auto" flat>
    <v-card-title
      >Number of Items Per Location
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <PieChart :pieData="pieData" />
    </v-card-text>
  </v-card>
</template>
<script>
import PieChart from "@/packages/cassandra-dashboards/Charts/PieChart.vue";
import { helper } from "@/packages/cassandra-base/utilities";
import inventoryMixin from "../inventoryMixin";
export default {
  name: "InventoryCategoryChart",
  mixins: [inventoryMixin],
  components: {
    PieChart,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    pieData: function () {
      return this.locations
        ? {
            data: this.locations.map((item) => item.Items),
            labels: this.locations.map((item) => item.name),
            backgroundColor: this.locations.map((item) =>
              helper.stringToColour(item.name)
            ),
            id: btoa("Location"),
          }
        : {};
    },
  },
};
</script>
