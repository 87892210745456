import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-x-reverse-transition","appear":"","duration":{ enter: 200, leave: 800 }}},[_c('div',[_c(VListItem,{staticClass:"white--text",attrs:{"link":"","to":_vm.sideLink.mainDashboardLink.to}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.sideLink.mainDashboardLink.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.sideLink.mainDashboardLink.name))])],1),_c(VListItem,{staticClass:"white--text active--side--item",attrs:{"dense":""}},[_c(VListItemAction,[_c(VBtn,{attrs:{"small":"","color":"white","icon":"","outlined":""},on:{"click":_vm.returnToMainMenu}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-arrow-left")])],1)],1),_c(VListItemTitle,{staticClass:"white--text text-body-2 font-weight-black"},[_vm._v(" "+_vm._s(_vm.sideLink.name)+" ")])],1),_vm._l((_vm.sideLink.links),function(link,i){return _c(VList,{key:i,staticClass:"drawer-list"},[(!_vm.miniDrawer && link.name !== undefined && link.enabled)?_c(VListItem,{key:i,staticClass:"white--text ml-8",attrs:{"link":"","to":link.to}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(link.icon))])],1),_c(VListItemTitle,{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(link.name))])],1):_vm._e(),(_vm.miniDrawer && link.name !== undefined && link.enabled)?_c(VListItem,{key:i,staticClass:"text-center",attrs:{"to":link.to,"link":""}},[_c(VTooltip,{staticClass:"mini--link",attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"white"}},'v-icon',attrs,false),on),[_vm._v(_vm._s(link.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(link.name))])])],1):_vm._e()],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }