<template>
  <v-toolbar flat dense>
    <v-spacer v-if="imprestActionsPosition === 'bottom'" />
    <div class="pa-2 mr-2 d-flex justify-end" v-if="$route.params.approveCode">
      <div class="mx-2">
        <v-btn
          color="error"
          class="btnActions"
          @click="approveImprest(rejectImprest)"
        >
          <v-icon class="mx-3">thumb_down_alt </v-icon>
          reject
        </v-btn>
      </div>
      <div class="mx-2">
        <v-btn
          color="success"
          class="btnActions"
          @click.prevent="approveImprest(approve)"
        >
          <v-icon class="mx-3">thumb_up_alt</v-icon>
          Approve
        </v-btn>
      </div>
    </div>
    <div v-else>
      <v-btn
        @click="deleteImprest"
        v-if="routeParams && formData.imprestStatus === 'Draft'"
        class="mr-2"
        color="error"
        depressed
      >
        Delete
      </v-btn>
      <v-btn
        @click="sendForApproval(!isEdit ? 'post' : 'patch')"
        v-if="formData.imprestStatus === 'Draft' || !isEdit"
        class="mr-2"
        color="success"
        depressed
      >
        Send For Approval
      </v-btn>

      <v-btn
        @click="CheckBudget"
        v-if="formData.imprestStatus === 'Draft'"
        color="teal"
        class="ma-2 white--text mr-2"
        depressed
      >
        Check Budget
      </v-btn>

      <v-btn v-if="!isEdit" @click="saveImprest" color="primary" class="mr-2">
        {{ saveButtonCaption }}
      </v-btn>

      <v-btn
        v-if="isEdit && formData.imprestStatus === 'Draft'"
        @click="saveImprest"
        color="primary"
        class="mr-2"
      >
        {{ saveButtonCaption }}
      </v-btn>

      <v-btn
        v-if="routeParams && formData.imprestStatus === 'Pending'"
        @click="cancelApprovalRequest"
        color="orange darken-1"
        class="mr-2 white--text"
      >
        {{ "Cancel Approval Request" }}
      </v-btn>

      <v-btn
        v-if="
          routeParams &&
          (formData.imprestStatus === 'Rejected' ||
            formData.imprestStatus === 'Liquidation Rejected')
        "
        @click="ReopenDocument"
        color="teal darken-1 white--text"
        class="mr-2"
      >
        {{ "Reopen Imprest Request" }}
      </v-btn>

      <div v-if="isIssued">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary darken-1"
              class="white--text"
              elevation="0"
              v-on="on"
              v-bind="attrs"
              :to="{
                name: 'ImprestSurrenderDocument',
                params: { docNo: imprestsDetails.no },
              }"
            >
              Imprest Surrender Report
            </v-btn>
          </template>
          <span>view report</span>
        </v-tooltip>
      </div>
      <v-btn
        v-if="routeParams"
        color="secondary darken-1"
        class="white--text"
        elevation="0"
        :to="{
          name: 'ImprestDocument',
          params: { docNo: imprestsDetails.no },
        }"
      >
        Imprest Report
      </v-btn>

      <!-- cancel liquidation process -->
      <v-btn
        color="warning darken-1"
        label
        elevation="0"
        @click="cancelLiquidation"
        v-if="imprestsDetails.imprestStatus === 'Pending Liquidation'"
        class="mx-1 text--white"
      >
        <span style="color: #ffffff"> cancel liquidation process </span>
      </v-btn>
    </div>
  </v-toolbar>
</template>
<script>
export default {
  name: "ImprestCardActions",
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    isIssued: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    routeParams: {
      type: String,
      default: "",
    },
    imprestsDetails: {
      type: Object,
      default: () => {},
    },
    rejectImprest: {
      type: String,
      default: "rejectImprest",
    },
    approve: {
      type: String,
      default: "approve",
    },
    returnImprest: {
      type: String,
      default: "returnImprest",
    },
    saveButtonCaption: {
      type: String,
      default: "Save",
    },
    imprestActionsPosition: {
      type: String,
      default: "top",
    },
  },
  data: function () {
    return {};
  },
  methods: {
    saveImprest() {
      this.$emit("saveImprest");
    },
    sendForApproval(method) {
      this.$emit("sendForApproval", method);
    },
    deleteImprest() {
      this.$emit("deleteImprest");
    },
    approveImprest(method) {
      this.$emit("approveImprest", method);
    },
    cancelApprovalRequest() {
      this.$emit("cancelApprovalRequest");
    },
    ReopenDocument() {
      this.$emit("ReopenDocument");
    },
    CheckBudget() {
      this.$emit("CheckBudget");
    },
    cancelLiquidation() {
      this.$emit("cancelLiquidation");
    },
  },
};
</script>
