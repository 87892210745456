import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isCalender)?_c(VDataTable,{ref:"leave_list",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.applications,"sort-desc":[false, true],"multi-sort":"","search":_vm.listFilterValue,"id":"scollStyle"},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"label":"","color":_vm.getColor(item.status).color,"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.made_by",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.made_by !== undefined)?_c('div',{staticClass:"d-flex-inline justify-start"},[_c(VAvatar,_vm._g(_vm._b({staticClass:"primary lighten-4 primary--text text--darken-4",staticStyle:{"cursor":"pointer"},attrs:{"size":"28"}},'v-avatar',attrs,false),on),[_vm._v(" "+_vm._s(item.employeeInitials)+" ")]),_vm._v(" "+_vm._s(item.made_by.firstName + " " + item.made_by.middleName + " " + item.made_by.lastName)+" ")],1):_c('div')]}}],null,true)},[(item.made_by !== undefined)?_c('span',[_vm._v(_vm._s(item.made_by.firstName + " " + item.made_by.lastName))]):_vm._e()])]}},{key:"item.days_applied",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"14px","font-weight":"400"}},[_vm._v(" "+_vm._s(((item.days_applied) + " day(s)"))+" ")])]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.start_date))+" ")]}},{key:"item.return_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.return_date))+" ")]}},{key:"item.approvalEntries",fn:function(ref){
var item = ref.item;
return [(item.approvalEntries !== undefined)?_c('div',{staticClass:"text-lg-h6"},[_vm._v(" "+_vm._s(item.approvalEntries.length)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"primary lighten-4 mx-1",attrs:{"label":""},on:{"click":function($event){return _vm.viewApplication(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("pageview")])],1)]}}],null,true)},[_c('span',[_vm._v("view application")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Released' || item.status === 'Posted'),expression:"item.status === 'Released' || item.status === 'Posted'"}],staticClass:"primary lighten-4 mx-1",attrs:{"label":""},on:{"click":function($event){return _vm.getLeaveSheet(item.application_code)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("article")])],1)]}}],null,true)},[_c('span',[_vm._v("view leave sheet")])]),(!item.isApprover)?_c('div',{staticClass:"d-flex justify-start"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Open'),expression:"item.status === 'Open'"}],staticClass:"primary lighten-4 mx-1",attrs:{"label":""},on:{"click":function($event){return _vm.crudAction(item, 'approval')}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("schedule_send")])],1)]}}],null,true)},[_c('span',[_vm._v("send for approval")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Open'),expression:"item.status === 'Open'"}],staticClass:"primary lighten-4 mx-1",attrs:{"label":""},on:{"click":function($event){return _vm.editApplication(item.application_code)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("mode_edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Application")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Open'),expression:"item.status === 'Open'"}],staticClass:"primary lighten-4 mx-1",attrs:{"label":""},on:{"click":function($event){return _vm.crudAction(item, 'archive')}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("event_busy")])],1)]}}],null,true)},[_c('span',[_vm._v("delete application")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Review'),expression:"item.status === 'Review'"}],staticClass:"primary lighten-4 mx-1",attrs:{"label":"","ripple":""},on:{"click":function($event){return _vm.crudAction(item, 'cancel')}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary darken-4"}},[_vm._v("cancel_schedule_send")])],1)]}}],null,true)},[_c('span',[_vm._v("cancel approval")])])],1):_vm._e()],1)]}}],null,false,2973887445)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }