export default {
  data() {
    return {
      category: "",
      location: "",
    };
  },
  computed: {
    categories: function () {
      return this.$store.getters["Inventory/inventoryGetters"]("categories");
    },
    locations: function () {
      return this.$store.getters["Inventory/inventoryGetters"]("locations");
    },
  },
  methods: {
    filter() {
      this.$store.dispatch("Inventory/getItems", {
        Category_Code: this.category,
        Location_Code: this.location,
        Description: this.search,
        filterByVariance: this.filterByVariance,
      });
    },
    exportReplenishItemsToExcel() {
      this.$store.dispatch("Inventory/exportToExcel", {
        Category_Code: this.category,
        Location_Code: this.location,
        Description: this.search,
        filterByVariance: this.filterByVariance,
        exportToExcel: true,
      });
    },
  },
};
