import DashboardReport from "./views/DashboardReport.vue";
import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";

export default [
  {
    path: "/dashboard",
    component: AppDashboard,
    children: [
      {
        path: "/reporting/Dashboard",
        name: "DashboardReport",
        components: {
          view: DashboardReport,
        },
      },
    ],
  },
];
