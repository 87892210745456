export default {
  requisition: "requisition",
  singleRequisition: (no) => `requisition/show?no=${no}`,
  requisitionApprovalRequest: "requisition/SendApprovalRequest",
  requisitionCancelRequest: "requisition/CancelApprovalRequest",
  billingItems: "requisition/setup/billingItems",
  vendors: "requisition/setup/vendors",
  users: "requisition/setup/users",
  locations: "requisition/setup/locations",
  billingItem: "requisition/delete",
  dimension: "requisition/dimension",
  RequisitionDocument: "requisition/reqReport",
  unitsOfMeasure: "requisition/setup/units_of_measure",
  CheckBudget: "requisition/CheckBudget",
  ReopenDocument: "requisition/ReopenDocument",
  approvals: (params) => `approvals/approvals?${formatParams(params)}`,
  rejectRequest: "approvals/reject",
  approveRequest: "approvals/approve",
};

function formatParams(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}
