import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto mt-10",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v("Billing Items "),_c(VSpacer),(_vm.hideButtons)?_c(VBtn,{staticClass:"ml-2 white--text",attrs:{"color":"primary"},on:{"click":_vm.addNew}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v("Add Billing Item ")],1):_vm._e()],1),_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.filterHeader,"items":_vm.billingItemsList,"search":_vm.search,"fixed-header":""},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VAutocomplete,{staticClass:"mb-n3",attrs:{"items":_vm.billingItems,"item-text":function (item) { return ((item.code) + " - " + (item.description)); },"item-value":function (item) { return item.code; },"hide-details":false,"label":"Select Billing Item","dense":"","single-line":""},on:{"focusout":_vm.autosaveBillingItem,"change":_vm.selectBillingItemCode},model:{value:(_vm.billingItem.billingItemCode),callback:function ($$v) {_vm.$set(_vm.billingItem, "billingItemCode", $$v)},expression:"billingItem.billingItemCode"}}):_c('span',[_vm._v(_vm._s(item.billingItemCode))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VTextField,{attrs:{"hide-details":true,"dense":"","single-line":""},on:{"focusout":_vm.autosaveBillingItem},model:{value:(_vm.billingItem.description),callback:function ($$v) {_vm.$set(_vm.billingItem, "description", _vm._n($$v))},expression:"billingItem.description"}}):_c('span',[_vm._v(_vm._s(item.description))])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.displayConditionally(item))?_c(VTextField,{attrs:{"type":"number","onkeyup":"if(this.value<0){this.value= this.value * -1}","min":"1","hide-details":true,"dense":"","single-line":""},on:{"focusout":_vm.autosaveBillingItem,"input":function($event){return _vm.assignQuantity($event, index)}},model:{value:(_vm.billingItem.quantity),callback:function ($$v) {_vm.$set(_vm.billingItem, "quantity", _vm._n($$v))},expression:"billingItem.quantity"}}):_c('span',[_vm._v(_vm._s(item.quantity))])]}},{key:"item.unitOfMeasure",fn:function(ref){
var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VSelect,{staticClass:"mb-n3",attrs:{"items":_vm.unitsOfMeasure,"item-text":function (item) { return item.displayName; },"item-value":function (item) { return item.code; },"hide-details":false,"label":"Unit Of Measure","dense":"","single-line":""},on:{"focusout":_vm.autosaveBillingItem},model:{value:(_vm.billingItem.unitOfMeasure),callback:function ($$v) {_vm.$set(_vm.billingItem, "unitOfMeasure", $$v)},expression:"billingItem.unitOfMeasure"}}):_c('span',[_vm._v(_vm._s(item.unitOfMeasure))])]}},{key:"item.locationCode",fn:function(ref){
var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VSelect,{attrs:{"label":"Select Location","items":_vm.locations,"item-text":function (item) { return item.name; },"item-value":function (item) { return item.code; },"hide-details":false,"dense":"","single-line":""},on:{"focusout":_vm.autosaveBillingItem},model:{value:(_vm.billingItem.locationCode),callback:function ($$v) {_vm.$set(_vm.billingItem, "locationCode", $$v)},expression:"billingItem.locationCode"}}):_c('span',[_vm._v(_vm._s(item.locationCode))])]}},{key:"item.unitCost",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.displayConditionally(item))?_c(VTextField,{attrs:{"hide-details":true,"dense":"","single-line":"","min":"1","type":"number"},on:{"focusout":_vm.autosaveBillingItem,"input":function($event){return _vm.assignUnitCost($event, index)}},model:{value:(_vm.billingItem.unitCost),callback:function ($$v) {_vm.$set(_vm.billingItem, "unitCost", _vm._n($$v))},expression:"billingItem.unitCost"}}):_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(item.unitCost)))])]}},(
        _vm.documentType !== 'Store Requisition' ||
        _vm.requisition.documentType !== 'Store Requisition'
      )?{key:"item.amount",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(Number(item.quantity * item.unitCost))))])]}}:null,{key:"item.dimensions",fn:function(ref){
      var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"ml-2 white--text text-caption font-weight-thin",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.addDimensions(item)}}},'v-chip',attrs,false),on),[_vm._v(" Dimensions ")])]}}],null,true)},[_c('span',[_vm._v("click to edit")])])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(_vm.displayConditionally(item))?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"red"},on:{"click":_vm.close}},'v-icon',attrs,false),on),[_vm._v(" mdi-window-close ")]),_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Close Editing")])])],1):_c('div',[_c(VIcon,{staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1)]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th',{staticClass:"text-center"},[_c('h3',[_vm._v(" TOTAL "+_vm._s(_vm.documentType !== "Store Requisition" ? "AMOUNT" : "QUANTITY")+" ")])]),_c('th',[_c('h3')]),_c('th',[_c('h3')]),_c('th',[_c('h3')]),_c('th',[_c('h3')]),(_vm.documentType !== 'Store Requisition')?_c('th',{staticClass:"text-center"},[_c('h3',[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.requisitionTotal)))])]):_c('th',{staticClass:"text-center"},[_c('h3',[_vm._v(_vm._s(_vm.totalQuantity))])])])]},proxy:true}],null,true)},[_c(VDivider,{attrs:{"inset":""}})],1),_c('DimensionsDialog',{attrs:{"itemDialog":_vm.itemDialog,"selectedBillingItem":_vm.billingItem,"formData":_vm.formData,"isEdit":_vm.isEdit,"documentType":_vm.documentType,"locations":_vm.locations,"requisition":_vm.requisition,"dimensionValues":_vm.dimensionValues,"readOnlyCheck":_vm.readOnlyCheck},on:{"close-dialog":_vm.closeDialog,"updateDimensions":_vm.updateDimensions}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }