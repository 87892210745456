<template>
  <v-card class="mx-auto" flat>
    <v-card-title
      >Number of Items Per Category
      <v-spacer />
      <!-- <v-btn small class="text-capitalize text-body-1 primary">
        Detailed View
      </v-btn> -->
    </v-card-title>
    <v-card-text>
      <PieChart :pieData="pieData" />
    </v-card-text>
  </v-card>
</template>
<script>
import PieChart from "@/packages/cassandra-dashboards/Charts/PieChart.vue";
import { helper } from "@/packages/cassandra-base/utilities";
import inventoryMixin from "../inventoryMixin";
export default {
  name: "InventoryCategoryChart",
  mixins: [inventoryMixin],
  components: {
    PieChart,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    pieData: function () {
      return this.categories
        ? {
            data: this.categories.map((item) => item.Items),
            labels: this.categories.map((item) => item.Description),
            backgroundColor: this.categories.map((item) =>
              helper.stringToColour(item.Code)
            ),
            id: btoa("Category"),
          }
        : {};
    },
  },
};
</script>
