<template>
  <v-container fluid>
    <ListActionsVue />
    <v-row>
      <v-col cols="12" md="12" lg="12" sm="12" xs="12">
        <InventoryListVue />
      </v-col>
      <v-col cols="12" md="6" lg="6" sm="12" xs="12">
        <InventoryCategoryChartVue />
      </v-col>
      <v-col cols="12" md="6" lg="6" sm="12" xs="12">
        <InventoryLocationChartVue />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import InventoryListVue from "./InventoryList.vue";
import InventoryCategoryChartVue from "./InventoryCategoryChart.vue";
import ListActionsVue from "./ListActions.vue";
import InventoryLocationChartVue from "./InventoryLocationChart.vue";
export default {
  name: "InventoryDashboard",
  components: {
    InventoryListVue,
    InventoryCategoryChartVue,
    ListActionsVue,
    InventoryLocationChartVue,
  },
  data: function () {
    return {};
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Inventory/getItems", {
        filterByVariance: true,
      });
      v.$store.dispatch("Inventory/getCategories");
      v.$store.dispatch("Inventory/getLocations");
      v.$store.dispatch("Dashboard/setMiniDrawer", true);
    });
  },
};
</script>
