<template>
  <v-card flat>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="entries"
        :search="search"
        :sort-desc="[false, true]"
        multi-sort
        :single-expand="true"
        class="task--list"
        item-key="entryNo"
        :expanded.sync="expanded"
      >
        <!-- eslint-disable vue/valid-v-slot -->
        <template v-slot:item.complete="{ item }">
          <v-chip :color="item.complete ? 'success' : 'grey lighten-3'">
            {{ item.complete ? "Complete" : "Incomplete" }}
          </v-chip>
        </template>
        <!-- eslint-disable vue/valid-v-slot -->
        <template v-slot:item.activityTasks="slotData">
          <v-badge
            :content="slotData.item.activityTasks.length"
            color="secondary"
            class="white--text"
            overlap
          >
            <v-btn
              color="primary"
              class="white--text text-capitalize"
              @click="viewTasks(slotData)"
            >
              View Tasks <v-icon class="mx-2">expand_more</v-icon>
            </v-btn>
          </v-badge>
          <v-btn
            color="primary"
            class="white--text text-capitalize mx-3"
            @click="editActivity(slotData)"
          >
            Edit <v-icon right>edit</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-2">
            <v-list dense flat outlined>
              <v-list-item dense>
                <v-list-item-title>
                  {{ item.activity }} - Tasks
                </v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-data-table
                :headers="taskHeaders"
                :items="item.activityTasks"
                :search="search"
                :sort-desc="[false, true]"
                multi-sort
                class="task--list"
              >
                <template v-slot:item.startDate="{ item }">
                  {{ getDateFormat(item.startDate) }}
                </template>
                <template v-slot:item.finishDate="{ item }">
                  {{ getDateFormat(item.finishDate) }}
                </template>
                <template v-slot:item.complete="{ item }">
                  <v-chip :color="item.complete ? 'success' : 'grey lighten-3'">
                    {{ item.complete ? "Complete" : "Incomplete" }}
                  </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="primary"
                    class="white--text text-capitalize"
                    @click="viewTask(item)"
                  >
                    View Task
                  </v-btn>
                </template>
              </v-data-table>
            </v-list>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import DateMixin from "../mixins/DateMixin";
import projectMixin from "../mixins/projectMixin";
import RouteMixin from "../mixins/RouteMixin";
export default {
  name: "MilestoneTaskList",
  mixins: [projectMixin, DateMixin, RouteMixin],
  data: function () {
    return {
      expanded: [],
    };
  },
  computed: {
    headers: function () {
      return [
        {
          text: "No.",
          value: "documentNo",
          align: "left",
          sortable: false,
        },
        {
          text: "Milestone",
          value: "milestone",
          align: "left",
          sortable: false,
        },
        {
          text: "Activity",
          value: "description",
          align: "left",
          sortable: false,
        },
        {
          text: "Status",
          value: "complete",
          align: "left",
          sortable: false,
        },
        {
          text: "Tasks",
          value: "activityTasks",
          align: "left",
          sortable: false,
        },
      ];
    },
    taskHeaders() {
      return [
        {
          text: "Task",
          value: "task",
          align: "left",
          sortable: false,
        },
        {
          text: "Description",
          value: "description",
          align: "left",
          sortable: false,
        },
        {
          text: "Start Date",
          value: "startDate",
          align: "left",
          sortable: false,
        },
        {
          text: "Finish Date",
          value: "finishDate",
          align: "left",
          sortable: false,
        },
        {
          text: "Status",
          value: "complete",
          align: "left",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          align: "left",
          sortable: false,
        },
      ];
    },
    entries: function () {
      return this.$store.getters["Projects/projectGetters"]("activities");
    },
    search: function () {
      return this.$store.getters["Projects/projectGetters"]("search");
    },
  },
  methods: {
    viewTasks(slotData) {
      const indexExpanded = this.expanded.findIndex((i) => i === slotData.item);
      if (indexExpanded > -1) {
        this.expanded.splice(indexExpanded, 1);
      } else {
        this.expanded.push(slotData.item);
      }
    },
    editActivity(slotData) {
      this.routeTo({
        name: "Activity",
        query: {
          milestoneLine: parseInt(this.$route.query.milestoneLine),
          documentNo: this.decryptRoute(this.$route).query.documentNo,
          entryNo: parseInt(slotData.item.entryNo),
        },
      });
    },
  },
};
</script>
<style>
.task--list.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: 0 0 0 0 !important;
}
</style>
