import Troutes from "./timesheetRoutes";
import Tstore from "./timesheetStore";
import links from "./timesheetLinks";

export default {
  install(Vue, options) {
    /* register store and routes */
    if (options.router) options.router.addRoute(...Troutes);
    if (options.store) options.store.registerModule("Timesheet", Tstore);

    /* register side links */
    options.store.commit("Dashboard/ADD_LINK", links);
  },
};
