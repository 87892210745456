<template>
  <v-container fluid>
    <v-app-bar color="transparent" dense flat elevation="0">
      <v-breadcrumbs
        divider="\"
        :items="path"
        class="ml-n6 breadcrumb-item"
      ></v-breadcrumbs>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ attrs, on }">
          <v-chip v-on="on" v-bind="attrs">
            <v-avatar
              color="primary"
              class="text-uppercase white--text mx-1 ml-n2 breadcrumb-item"
            >
              {{ `${userData.email[0]}${userData.email[1]}` }}
            </v-avatar>
            {{ userData.employee }}
          </v-chip>
        </template>
        <span>{{
          `${userData.profile.firstName} ${userData.profile.middleName} ${userData.profile.lastName}`
        }}</span>
      </v-tooltip>
    </v-app-bar>
    <router-view></router-view>
  </v-container>
</template>

<script>
import allowanceMixins from "../allowanceMixins";

export default {
  name: "Allowance",
  mixins: [allowanceMixins],
  data: function () {
    return {
      path: this.$route.meta.breadcrumb,
    };
  },
  watch: {
    $route(to, from) {
      this.path = to.meta.breadcrumb;
    },
  },
};
</script>

<style scoped></style>
