import { AuthService } from "@kinetics254/cassandra-base/modules/auth";

const user = AuthService.user;

const caption = user
  ? user.captions
    ? user.captions.handbook_caption
    : undefined
  : undefined;

export default [
  {
    name: `${caption ? caption : "Documents"}`,
    icon: "menu_book",
    to: "/handbook/list",
    enabled: true,
  },
];
