<template>
  <div>
    <v-card flat class="mx-auto" v-for="(document, i) in documents" :key="i">
      <v-card-text>
        <v-toolbar
          color="primary"
          class="white--text text-capitalize text-body-1"
          dense
        >
          {{ document.fileName }}
          <v-spacer />
          <v-btn
            color="secondary"
            class="mx-2 text-capitalize"
            @click="openFile(document.base64Attachment)"
          >
            Download
            <v-icon class="mx-1">mdi-download</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-if="document.fileExtension === 'pdf'">
          <pdf
            v-for="i in getPages(document.base64Attachment)"
            :key="i"
            :src="document.base64Attachment"
            :page="i"
          />
        </div>
        <div v-else-if="isImage(document.fileExtension)">
          <v-img :src="document.base64Attachment" />
        </div>
        <div v-else>
          <v-card>
            <v-card-text>
              <div class="text-body-1">
                Sorry, we cannot display this file. Please download it to view
                it.
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import FileMixin from "@/packages/cassandra-base/mixins/FileMixin";
import pdf from "vue-pdf";
export default {
  name: "ApprovalDocuments",
  mixins: [FileMixin],
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
  components: {
    pdf,
  },
  data: () => ({
    numPages: undefined,
  }),
  computed: {
    isImage: function () {
      return (fileExtension) => {
        return (
          fileExtension === "jpg" ||
          fileExtension === "jpeg" ||
          fileExtension === "png"
        );
      };
    },
  },
  methods: {
    getPages: function (document) {
      if (document.length > 0) {
        document = pdf.createLoadingTask(document);
        document.promise.then((pdf) => {
          this.numPages = pdf._pdfInfo.numPages;
        });
        return this.numPages;
      }
    },
    openFile(filePath) {
      window.open(filePath, "_blank");
    },
  },
};
</script>
