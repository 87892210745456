import call from "@kinetics254/cassandra-base/service/http";
import projectConstants from "./projectConstants";
import milestoneAttachmentStore from "./milestoneAttachmentStore";
import milestoneActivityStore from "./milestoneActivityStore";
export default {
  namespaced: true,
  state: {
    ...milestoneAttachmentStore.state,
    ...milestoneActivityStore.state,
    milestoneTabs: [
      {
        text: "List View",
        icon: "list",
      },
      {
        text: "Gantt View",
        icon: "timeline",
      },
    ],
    tasks: [],
    task: {},
    search: "",
    milestones: [],
    milestone: {},
    activities: [],
    projects: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    projectGetters: (state) => (val) => state[val],
  },
  actions: {
    ...milestoneAttachmentStore.actions,
    ...milestoneActivityStore.actions,
    searchList({ commit }, payload) {
      commit("MUTATE", { state: "search", data: payload });
    },
    getProjects({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", projectConstants.projects(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "projects", data: res.data.data });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getMilestones({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", projectConstants.milestones(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "milestones", data: res.data.data });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getMilestone({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", projectConstants.showMilestone(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "milestone", data: res.data.data });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getTasks({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", projectConstants.tasks(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "tasks", data: res.data.data });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getTask({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", projectConstants.showTask(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "task", data: res.data.data.shift() });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    saveTask({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", projectConstants.task, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
          Event.$emit("gotoMilestone");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    updateTask({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", projectConstants.updateTask, payload)
        .then(() => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Task updated successfully");
          Event.$emit("gotoMilestone");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    deleteTask({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", projectConstants.deleteTask, payload)
        .then(() => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Task deleted successfully");
          Event.$emit("back");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getActivities({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", projectConstants.activities(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "activities", data: res.data.data });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
  },
};
