import ImprestLinks from "./modules/imprest/ImprestLinks";
import PettyCashLinks from "../cassandra-pettycash/links.js";
import advanceLinks from "../cassandra-advance/AdvanceLinks";

const links = [...ImprestLinks, ...PettyCashLinks, ...advanceLinks];

export default {
  order: 2,
  name: "Cash Management",
  icon: "payments",
  links,
  hasSideLinks: true,
  enabled: true,
};
