import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"label mx-4"},[_vm._v("Select Leave Type")]),_c(VAutocomplete,{staticClass:"mt-1 mx-4 leaveInputs",attrs:{"items":_vm.leaveTypes,"placeholder":"Leave Type","clearable":"","item-text":"description","item-value":"code","filled":"","rules":_vm.rules},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" toc ")])]},proxy:true}]),model:{value:(_vm.leaveTypelocal),callback:function ($$v) {_vm.leaveTypelocal=$$v},expression:"leaveTypelocal"}}),(_vm.requiresReason)?_c(VCombobox,{staticClass:"mx-4 leaveInputs",attrs:{"placeholder":"Leave reason","clearable":"","filled":"","rules":_vm.rules,"items":_vm.leaveReasons,"item-text":"description","item-value":"code","value":_vm.ReasonDescription},on:{"input":function($event){return _vm.input($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c('span',{staticClass:"subheading"},[_vm._v("hit enter to add description")])])]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [(item === Object(item))?_c('span',{staticClass:"pr-2",attrs:{"input-value":selected}},[_vm._v(" "+_vm._s(item.description)+" ")]):_c('span',[_vm._v(_vm._s(item))])]}}],null,false,2228778764)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }