export default [
  {
    name: "Leave Dashboard",
    icon: "date_range",
    to: "/leave/dashboard",
    enabled: true,
  },
  {
    name: "Application",
    icon: "pending_actions",
    to: "/leave/application",
    enabled: true,
  },
  {
    name: "Leave Approval",
    icon: "event_available",
    to: "/leave/approvals",
    enabled: true,
  },
];
