export default [
  {
    name: "Disciplinary Requests",
    icon: "group",
    to: "/disciplinary/raised",
    enabled: true,
  },
  {
    name: "My Accused Requests",
    icon: "switch_account",
    to: "/disciplinary/accused",
    enabled: true,
  },
];
