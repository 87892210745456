import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
const AppDashboard = () =>
  import("@/packages/cassandra-base/app/layout/AppDashboard");

import ExitList from "./components/ExitList";
import ExitCard from "./components/ExitCard";

export default [
  {
    path: "/exit",
    component: AppDashboard,
    children: [
      {
        path: "",
        redirect: "/exit/list",
      },
      {
        path: "list",
        name: "ExitList",
        components: {
          view: ExitList,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "card/:code?",
        name: "ExitCard",
        components: {
          view: ExitList,
          action: ExitCard,
        },
        meta: { middleware: [Auth] },
      },
    ],
  },
];
