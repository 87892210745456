<template>
  <v-data-table
    :headers="headers"
    :items="milestones"
    :search="search"
    :sort-desc="[false, true]"
    multi-sort
    class="task--list"
  >
    <!-- eslint-disable vue/valid-v-slot -->
    <template v-slot:item.actions="{ item }">
      <v-btn
        class="text-capitalize"
        color="primary"
        link
        @click="viewMilestone(item)"
      >
        View
        <v-icon class="mx-2">visibility</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import RouteMixin from "../mixins/RouteMixin";
import DateMixin from "../mixins/DateMixin";
export default {
  name: "MilestoneList",
  props: {
    milestones: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [RouteMixin, DateMixin],
  data: function () {
    return {
      search: "",
      headers: [
        {
          text: "Project Code",
          align: "start",
          filterable: false,
          value: "projectCode",
        },
        {
          text: "Document No.",
          align: "start",
          filterable: false,
          value: "documentNo",
        },
        {
          text: "description",
          align: "start",
          filterable: false,
          value: "description",
        },
        {
          text: "Due on",
          align: "start",
          filterable: false,
          value: "dueDate",
        },
        {
          text: "Completion Date",
          align: "start",
          filterable: false,
          value: "completionDate",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    user() {
      return AuthService.user;
    },
  },
  methods: {
    viewMilestone(item) {
      this.routeTo({
        name: "Milestone",
        query: {
          documentNo: item.documentNo,
          milestoneLine: item.lineNo,
        },
      });
    },
  },
};
</script>
