<template>
  <v-container>
    <v-toolbar color="primary" sticky>
      <v-toolbar-title class="justify-center text-h6 white--text">
        {{ caption }}
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <template>
      <v-card class="mx-auto">
        <v-list dense>
          <v-list-item-group
            v-model="selected"
            color="primary"
            active-class="primary--text text--accent-4"
          >
            <template>
              <v-list-item
                v-for="(item, i) in handbooks"
                :key="i"
                dense
                class="my-2"
                @click="openHandbook(item)"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold primary--text overline"
                      >{{ item.code }}</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      {{ item.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="checkIfHandbookIsRead(item.code)">
                    <v-chip label>
                      <v-icon class="mx-2" color="green" dark right>
                        mdi-checkbox-marked-circle
                      </v-icon>
                      Read
                    </v-chip>
                  </v-list-item-action>

                  <v-list-item-action>
                    <v-btn @click="openHandbook(item)" :text="!active">
                      View
                      <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
            <v-divider />
          </v-list-item-group>
        </v-list>
      </v-card>
    </template>
  </v-container>
</template>
<style>
.handbook--btn > .v-btn__content {
  font-size: 16px;
  text-transform: none;
}
</style>
<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
export default {
  name: "HandBookList",
  data: function () {
    return {
      numPages: undefined,
      agree: false,
      isValid: false,
      dialog: false,
      selected: null,
      formData: {
        readHandbook: null,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.$route.name === "HandBookList") {
        v.$store.dispatch("handbook/getHandbook", v.$route.params.employee);
        v.$store.dispatch("handbook/allReadHandbooks");
        v.$store.dispatch("profile/getEmployee", {
          number: v.$store.getters["auth/user"].employee,
        });
      }
    });
  },
  computed: {
    employeeData: function () {
      const employee = this.$store.getters["profile/employee"][0];
      return employee || {};
    },
    handbooks() {
      return this.$store.getters["handbook/handbook"];
    },
    HandbookLogs() {
      return this.$store.getters["handbook/allReadhandbooks"];
    },
    caption() {
      const user = AuthService.user;
      return user.captions.handbook_caption;
    },
  },
  watch: {
    employeeData() {
      this.formData.readHandbook = this.employeeData.readHandbook;
    },
  },
  methods: {
    submit() {
      if (!this.isValid) {
        this.$refs.submitForm.validate();
      } else {
        var { readHandbook, number } = this.employeeData;
        readHandbook = this.formData.readHandbook;
        const data = { readHandbook, number };
        this.$store.dispatch("handbook/readHandbook", { ...data });
      }
    },
    openHandbook: function (item) {
      this.$router.push({
        name: "HandBookCard",
        params: { code: btoa(item.code) },
      });
    },

    checkIfHandbookIsRead(val) {
      return this.HandbookLogs.find((d) => d.handbookCode === val)
        ? this.HandbookLogs.find((d) => d.handbookCode === val).hasRead
        : false;
    },
  },
};
</script>
