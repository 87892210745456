import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";
import { Auth } from "@kinetics254/cassandra-base/modules/auth";
import Timesheet from "./views/Timesheet";
import TimesheetCard from "./components/TimesheetCard";
import TimesheetList from "./components/TimesheetList";
import TimesheetReport from "./components/TimesheetReport";
export default [
  {
    path: "/timesheet",
    component: AppDashboard,
    children: [
      {
        path: "",
        redirect: "/timesheet/list",
      },
      {
        path: "list/:month",
        name: "TimesheetList",
        components: {
          view: TimesheetList,
        },
        meta: { middleware: Auth },
      },
      {
        path: "card/:month",
        name: "TimesheetCard",
        components: {
          view: TimesheetList,
          action: TimesheetCard,
        },
        meta: { middleware: Auth },
      },
      {
        path: "report/:period",
        name: "TimesheetReport",
        components: {
          view: TimesheetList,
          action: TimesheetReport,
        },
        meta: { middleware: Auth },
      },
    ],
  },
];
