<template>
  <v-card class="mx-auto mt-10" flat>
    <v-card-title
      >Expense Items
      <v-spacer />
      <v-btn
        color="primary"
        class="ml-2 white--text text-capitalize"
        @click="addNew"
        v-if="displayPettyCashActions"
      >
        <v-icon dark>mdi-plus</v-icon>Add Expense Item
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="expenseItemsList"
      :search="search"
      class="elevation-0"
      fixed-header
    >
      <v-divider inset></v-divider>
      <!--eslint-disable-next-line -->
      <template v-slot:item.code="{ item }">
        <v-autocomplete
          @change="selectExpenseCode"
          @focusout="autosaveExpenseItem"
          v-model="expenseItem.expenseCode"
          :items="expenseItems"
          :item-text="(item) => `${item.code} - ${item.description}`"
          :item-value="(item) => item.code"
          :hide-details="false"
          label="Select Expense Item"
          dense
          single-line
          v-if="displayConditionally(item)"
          class="mb-n3"
        ></v-autocomplete>
        <span v-else>{{ item.expenseCode }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.description="{ item }">
        <v-text-field
          @focusout="autosaveExpenseItem"
          v-model="expenseItem.description"
          :hide-details="true"
          dense
          single-line
          v-if="displayConditionally(item)"
        ></v-text-field>
        <span v-else>{{ item.description }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.amount="{ item, index }">
        <v-text-field
          @focusout="autosaveExpenseItem"
          v-model.number="expenseItem.amount"
          :hide-details="true"
          dense
          single-line
          v-if="displayConditionally(item)"
          type="number"
          onkeyup="if(this.value<0){this.value= this.value * -1}"
          @input="assignUnitCost($event, index)"
          min="1"
          :disabled="hasTransportCode"
        ></v-text-field>
        <span v-else>{{ item.amount }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.invoiceRef="{ item }">
        <v-text-field
          @focusout="autosaveExpenseItem"
          v-model="expenseItem.invoiceRef"
          :hide-details="true"
          dense
          single-line
          v-if="displayConditionally(item)"
        ></v-text-field>
        <span v-else>{{ item.invoiceRef }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.dimensions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color="primary"
              small
              class="ml-2 white--text text-caption font-weight-thin"
              @click="addDimensions(item)"
              v-bind="attrs"
              v-on="on"
            >
              Dimensions
            </v-chip>
          </template>
          <span>click to edit</span>
        </v-tooltip>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.from="{ item }">
        <v-text-field
          :hide-details="true"
          @focusout="autosaveExpenseItem"
          v-if="displayConditionally(item)"
          v-model="expenseItem.from"
          :disabled="expenseItem.description.toLowerCase() !== 'travel'"
          dense
          single-line
        />
        <span v-else>{{ item.from }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.to="{ item }">
        <v-text-field
          :hide-details="true"
          v-if="displayConditionally(item)"
          @focusout="autosaveExpenseItem"
          v-model="expenseItem.to"
          :disabled="expenseItem.description.toLowerCase() !== 'travel'"
          dense
          single-line
        />
        <span v-else>{{ item.to }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.allocationCode="{item}" v-if="showAllocationCode">
        <v-autocomplete
          @focusout="autosaveExpenseItem"
          v-model="expenseItem.allocationCode"
          :items="allocationCodes"
          :item-text="(item) => `${item.no} - ${item.description}`"
          :item-value="(item) => item.no"
          :hide-details="false"
          label="Select Allocation Code"
          dense
          single-line
          v-if="displayConditionally(item)"
          class="mb-n3"
        ></v-autocomplete>
        <span v-else>{{ item.allocationCode }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }" v-if="displayPettyCashActions">
        <div>
          <div v-if="displayConditionally(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                  class="mr-3"
                  @click="close"
                >
                  mdi-window-close
                </v-icon>
              </template>
              <span>Close Editing</span>
            </v-tooltip>
            <v-icon color="red" @click="deleteLine(item)"> mdi-delete </v-icon>
          </div>
          <div v-else>
            <v-icon color="green" class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="red" @click="deleteLine(item)"> mdi-delete </v-icon>
          </div>
        </div>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:body.append>
        <tr>
          <th class="text-center"><h3>TOTAL AMOUNT</h3></th>
          <th><h3></h3></th>
          <th><h3></h3></th>
          <th class="text-center">
            <h3>{{ pettyCashTotal | currencyFormat }}</h3>
          </th>
        </tr>
      </template>
    </v-data-table>
    <DimensionsDialog
      :itemDialog="itemDialog"
      :selectedExpenseItem="expenseItem"
      :formData="formData"
      v-on:close-dialog="closeDialog"
      :locations="locations"
      :dimensionValues="dimensionValues"
      :displayPettyCashActions="displayPettyCashActions"
      @updateDimensions="updateDimensions"
    />
  </v-card>
</template>
<script>
import DimensionsDialog from "./PettyCashDimensionsDialog.vue";
import PettyCashMethods from "../pettyCashMethods.js";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
export default {
  name: "expenseItems",
  components: {
    DimensionsDialog,
  },
  props: {
    selectedExpenseItem: Object,
    formData: Object,
    locations: Array,
    dimensionValues: {
      type: Array,
      default: () => [],
    },
    displayPettyCashActions: Boolean,
    destinations: Array,
    transportRates: Array,
    isEdit: String,
    currencyCode: {
      type: String,
      default: "KES",
    },
    allocationCodes: Array,
  },
  data() {
    return {
      search: "",
      itemDialog: false,
      expenseItemIndex: -1,
      expenseItem: {
        id: 0,
        documentType: "Petty Cash Expenditure",
        expenseCode: "",
        description: "",
        documentNo: "",
        amount: 1,
        transportCode: "",
        invoiceRef: "",
        shortcutDimension1Code: "",
        shortcutDimension2Code: "",
        shortcutDimension3Code: "",
        shortcutDimension4Code: "",
        globalDimension5Code: "",
        globalDimension6Code: "",
        globalDimension7Code: "",
        globalDimension8Code: "",
        dimensionSpeedkeyCode: "",
        fundNo: "",
        from: "",
        to: "",
        allocationCode: "",
      },
      defaultExpenseItem: {
        id: 0,
        documentType: "Petty Cash Expenditure",
        expenseCode: "",
        description: "",
        documentNo: "",
        amount: 1,
        transportCode: "",
        shortcutDimension1Code: "",
        shortcutDimension2Code: "",
        shortcutDimension3Code: "",
        shortcutDimension4Code: "",
        globalDimension5Code: "",
        globalDimension6Code: "",
        globalDimension7Code: "",
        globalDimension8Code: "",
        dimensionSpeedkeyCode: "",
        invoiceRef: "",
        fundNo: "",
        from: "",
        to: "",
      },
    };
  },
  computed: {
    /**
     * Total amount of the pettycash
     */
    pettyCashTotal() {
      return this.formData.pettyCashLinesAPI.reduce((acc, a) => {
        return parseFloat(acc) + parseFloat(Number(a.amount));
      }, 0);
    },
    expenseItems() {
      return this.$store.getters["pettycash/pettycashstate"]("expenseCodes");
    },
    expenseItemsList: {
      get() {
        return this.formData.pettyCashLinesAPI;
      },
      set(val) {
        return val;
      },
    },
    headers: function () {
      return [
        {
          text: "Code",
          value: "code",
          sortable: false,
          align: "center",
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
          align: "center",
        },
        {
          text: `Amount (${this.defaultCurrencyCaption})`,
          sortable: true,
          value: "amount",
          align: "center",
        },
        {
          text: "invoiceRef",
          sortable: true,
          value: "invoiceRef",
          align: "center",
        },
        {
          text: "From",
          value: "from",
          sortable: false,
          align: "center",
        },
        {
          text: "To",
          value: "to",
          sortable: false,
          align: "center",
        },
        {
          text: this.showAllocationCode ? "Allocation" : "",
          value: this.showAllocationCode ? "allocationCode" : "",
          sortable: false,
          align: "center",
        },
        {
          text: "Dimensions",
          value: "dimensions",
          sortable: false,
          align: "center",
        },
        {
          text: this.displayPettyCashActions ? "Actions" : "",
          sortable: true,
          value: this.displayPettyCashActions ? "actions" : "",
        },
      ];
    },
    setDialog: {
      get() {
        return this.itemDialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    hasTransportCode() {
      if (this.expenseItem.transportCode) {
        return true;
      } else {
        return false;
      }
    },
    settings() {
      let settings = this.$store.getters["Admin/adminGetters"]("settings");
      return Object.keys(settings).length > 0
        ? JSON.parse(settings.cashmgt_settings)
        : null;
    },
    defaultCurrencyCaption() {
      return this.settings
        ? this.settings.default_currency_caption
        : this.currencyCode;
    },
    showAllocationCode() {
      return this.settings ? this.settings.showAllocationCode : true;
    },
  },
  methods: {
    ...PettyCashMethods,
    assignUnitCost(val, index) {
      this.expenseItemsList[index].amount = val;
    },
    updateDimensions(val) {
      if ("lineNo" in val) {
        Object.assign(
          this.expenseItemsList[
            this.expenseItemsList.findIndex((el) => el.lineNo === val.lineNo)
          ],
          this.expenseItem
        );
      } else if ("id" in val) {
        Object.assign(
          this.expenseItemsList[
            this.expenseItemsList.findIndex((el) => el.id === val.id)
          ],
          this.expenseItem
        );
      }
    },
  },
};
</script>

<style>
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  /* background: #555; */
  color: #fff;
}
.w-100 {
  width: 100%;
}
</style>
