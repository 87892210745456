import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VDialog,{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[(_vm.handbookDocument !== '')?_c(VCardText,_vm._l((_vm.getPages(_vm.handbookDocument)),function(i){return _c('pdf',{key:i,attrs:{"src":_vm.handbookDocument,"page":i}})}),1):_c(VCardText,[_c('p',{staticClass:"font-weight-black mt-3"},[_vm._v("Nothing to Preview")])]),_c(VDivider),_c(VCardActions,{staticClass:"handbookcardactions"},[_c(VBtn,{staticClass:"mx-3 error lighten-4 error--text text--darken-4",attrs:{"dense":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,{staticClass:"mx-2"},[_vm._v("mdi-arrow-left")]),_vm._v(" Back ")],1),_c(VSpacer),(!_vm.checkIfHandbookIsRead(_vm.handbook.code))?_c(VForm,{ref:"submitForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c(VCheckbox,{staticClass:"mx-5",attrs:{"color":"primary","label":"I have Read the Employee Document","rules":_vm.rules.required},model:{value:(_vm.formData.hasRead),callback:function ($$v) {_vm.$set(_vm.formData, "hasRead", $$v)},expression:"formData.hasRead"}}),_c('div',[_c(VBtn,{staticClass:"handbook--btn mt-3 mr-5",attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }