import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6 text-capitalize"},[_vm._v(" "+_vm._s(_vm.params.title)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.params.text)+" ")]),_c(VCardText,[(_vm.params.input)?_c(VTextarea,{attrs:{"placeholder":_vm.params.label},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}):_vm._e(),(_vm.params.numberInput)?_c(VTextField,{attrs:{"outlined":"","dense":"","placeholder":_vm.params.label,"persistent-placeholder":"","type":"number","min":"0"},on:{"keypress":function($event){return _vm.filter($event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.params.label))]),_c('span',{staticClass:"red--text"},[_vm._v("*")])]},proxy:true}],null,false,3682155974),model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}}):_vm._e(),(_vm.params.fileInput)?_c(VFileInput,{ref:"fileInput",attrs:{"placeholder":"Attach file","outlined":"","dense":"","persistent-placeholder":"","type":"file","accept":"image/*, application/pdf","show-size":"","prepend-icon":"attach_file","label":"Attach file","value":_vm.fileAttachment},on:{"change":function($event){return _vm.uploadFile($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [(_vm.file)?_c(VChip,{attrs:{"label":"","close":"","color":"primary","dark":""},on:{"input":function($event){_vm.file = null}}},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(text))])]):_vm._e()]}}],null,false,3655609993)}):_vm._e()],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.params.rejectLabel || "No")+" ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirm}},[_vm._v(" "+_vm._s(_vm.params.acceptLabel || "Yes")+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }