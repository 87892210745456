import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6","sm":"12"}},[_c(VHover,{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:{ 'on-hover': hover },attrs:{"flat":"","elevation":hover ? 16 : 0}},[_c(VCardText,{staticClass:"d-flex justify-start"},[_c('div',[_c('vue-ellipse-progress',{attrs:{"determinate":false,"size":160,"thickness":6,"lineMode":"normal","legend":false,"animation":"rs 700 1000","fontSize":"1.5rem","loading":false,"no-data":false,"data":_vm.data,"gap":5}},[_c('span',{staticStyle:{"color":"rgb(0, 68, 139)"},attrs:{"slot":"legend-caption"},slot:"legend-caption"},[_c('span',{staticClass:"text-lg-h6 stats-info"},[_vm._v(" "+_vm._s(_vm.numberPipe(_vm.total))+" ")])])])],1),_c(VList,{attrs:{"dense":"","max-width":"60%"}},[(!_vm.userData.is_navuser)?_c(VListItem,[_c(VBadge,{staticClass:"mr-n8 mt-n3",attrs:{"color":"#239B56","left":"","inline":""}},[_c(VListItemAction)],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"stats-info"},[_vm._v("User Requisition")]),_c(VListItemSubtitle,{staticClass:"stats-info"},[_vm._v("Items: "+_vm._s(_vm.User)+"; Total: "+_vm._s(_vm.numberPipe(_vm.totalUserAmount)))])],1)],1):_vm._e(),_c(VListItem,[_c(VBadge,{staticClass:"mr-n8 mt-n3",attrs:{"color":"#E67E22","left":"","inline":""}},[_c(VListItemAction)],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"stats-info"},[_vm._v("Purchase Requisition")]),_c(VListItemSubtitle,{staticClass:"stats-info"},[_vm._v("Items: "+_vm._s(_vm.Purchase)+"; Total: "+_vm._s(_vm.numberPipe(_vm.totalPurchaseAmount))+" ")])],1)],1)],1)],1)],1)]}}])})],1),_c(VCol,{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('div',[_c(VLayout,{attrs:{"row":"","wrap":""}},_vm._l((_vm.storageStats),function(item,i){return _c(VFlex,{key:i,staticClass:"req--custom--stats",attrs:{"s6":"","sm4":"","md3":"","xl2":""}},[_c(VHover,{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mx-2 mt-3",class:{ 'on-hover': hover },staticStyle:{"background-color":"#ffffff"},attrs:{"flat":"","elevation":hover ? 16 : 0}},[_c(VList,[_c(VListItem,{staticClass:"stats"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-lg-h5 stats-info"},[_vm._v(_vm._s(item.count))]),_c(VListItemSubtitle,{staticClass:"stats-info"},[_vm._v(_vm._s(item.name))])],1),_c(VListItemAction,[_c(VAvatar,{attrs:{"color":item.avatarColor,"size":"53"}},[_c(VIcon,{attrs:{"color":item.iconColor}},[_vm._v(_vm._s(item.icon))])],1)],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }