<template>
  <v-container fluid class="mt-10">
    <v-card elevation="0" tile>
      <v-card-title class="overline">
        <v-btn
          text
          @click="$router.back()"
          v-if="$vuetify.breakpoint.smAndDown"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>

        Appraisal Periods
        <v-spacer />

        <!-- templates -->

        <v-spacer />

        <!-- <v-chip x-small dark color="green"> Open </v-chip> -->
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="12">
            <v-list subheader>
              <v-subheader class="font-weight-bolder text-h6">
                Periods
                <!-- <v-spacer /> -->

                <v-chip small dark class="blue ml-2">{{
                  filteredPeriods.length
                }}</v-chip>
              </v-subheader>
            </v-list>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="filteredPeriods"
          v-if="isDataLoaded"
          :sort-by="['code']"
          :sort-desc="[true]"
        >
          <template v-slot:item.status="{ item }">
            <v-chip :color="item.current ? 'success' : 'primary'">
              {{ item.current ? "current" : "closed" }}
            </v-chip>
          </template>
          <template v-slot:item.closeDate="{ item }">
            <td v-if="item.closeDate" style="text-align: center">
              {{ item.closeDate !== "0001-01-01" ? item.closeDate : "" }}
            </td>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              color="success"
              text
              @click="
                item.current ? appraisalAppHandler(item) : viewPeriod(item)
              "
              :disabled="!item.current && !hasClosedApplications"
            >
              {{ item.current ? "View" : "View" }}
              <v-icon small class="mr-1">mdi-arrow-right</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-skeleton-loader
          type="table"
          v-else
          :loading="true"
          :rows="10"
          :headers="headers"
        ></v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RouterMixin from '@kinetics254/cassandra-base/mixins/RouterMixin';

export default {
  name: "Templates",
  mixins:[RouterMixin],
  data: function () {
    return {
      selected: [],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("appraisal/getPeriods");
      //get applications
      v.$store.dispatch("appraisal/getApplications");
      v.$store.dispatch(
        "appraisal/getArchivedReviewDocument",
        v.userData.employee
      );
    });
  },

  computed: {
    isDataLoaded() {
      return this.$store.getters["appraisal/appraisalGetter"]("isDataLoaded");
    },
    userData() {
      return this.$store.getters["auth/user"];
    },
    headers() {
      const headers = [
        { text: "Period", value: "code", align: "center" },
        { text: "Starting Date", value: "startingDate", align: "center" },
        { text: "End Date", value: "endDate", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ];

      if (this.periods.some((period) => period.closed)) {
        headers.push({
          text: "Close Date",
          value: "closeDate",
          align: "center",
        });
      }
      headers.push({ text: "", value: "action", align: "center" });

      return headers;
    },
    periods() {
      return this.$store.getters["appraisal/appraisalGetter"]("periods");
    },
    applications() {
      return this.$store.getters["appraisal/appraisalGetter"]("applications");
    },
    archivedReviewDocument() {
      return this.$store.getters["appraisal/appraisalGetter"](
        "archivedReviewDocument"
      );
    },
    hasApplicationForCurrentPeriod() {
      const currentPeriod = this.periods.find((period) => period.current);

      const application = this.applications.find(
        (app) =>
          app.period === currentPeriod.code &&
          app.employeeNo === this.userData.employee
      );

      if (!application || !currentPeriod) {
        return false;
      }

      if (currentPeriod) {
        const currentStage = currentPeriod.periodStages.find(
          (stage) => stage.current
        );
        if (currentStage) {
          return (
            currentStage.stage === application.stage &&
            currentStage.period === currentPeriod.code
          );
        }
      }

      return false;
    },
    hasClosedApplications() {
      return this.archivedReviewDocument.some(
        (app) => app.employeeNo === this.userData.employee
      );
    },

    filteredPeriods() {
      const employeeNo = this.userData.employee;
      return this.periods.filter((period) => {
        //check if employee has an applic for the current period
        return (
          (period.current && !this.hasApplicationForCurrentPeriod) ||
          this.applications.some(
            (app) => app.period === period.code && app.employeeNo === employeeNo
          ) ||
          this.archivedReviewDocument.some(
            (app) => app.period === period.code && app.employeeNo === employeeNo
          )
        );

        // For closed periods, check if the employee had an application in the past
      });
    },
    //to be passed on route as params
    currentApplication() {
      const currentPeriod = this.periods.find((period) => period.current);
      const currentStage = currentPeriod
        ? currentPeriod.periodStages.find((stage) => stage.current)
        : null;

      const currentApp = currentStage
        ? this.applications.find(
            (app) =>
              app.period === currentPeriod.code &&
              app.stage === currentStage.stage
          )
        : null;

      return currentApp ? currentApp : null;
    },
    currentPeriod() {
      return this.periods.find((period) => period.current);
    },
  },
  methods: {
    async appraisalAppHandler(period) {
      if (this.hasApplicationForCurrentPeriod) {
        await this.redirectToAcceptanceForm(period);
      } else {
        const params = {
          employeeNo: this.userData.employee,
        };
        await this.$store.dispatch("appraisal/createApplication", params);

        await this.redirectToAcceptanceForm(period);
      }
    },
    redirectToAcceptanceForm({ code }) {
      this.$store.dispatch("appraisal/setSelectedPeriod", {
        code: code,
      });

      this.$router.push({
        name: "AcceptanceForm",
        params: {
          period: this.encodeRoute(this.currentPeriod.code),
          applicationNo: this.encodeRoute(this.currentApplication.no) 
        },
      });
    },

    viewPeriod(period) {
      const doc = this.$store.dispatch("appraisal/setSelectedPeriod", {
        code: period.code,
      });

      this.$router.push({
        name: "closedPeriodAppraisal",
        params: { period: period.code },
      });
    },
  },
};
</script>
