<template>
  <v-container fluid>
    <v-card flat class="projects--card">
      <v-toolbar dense flat>
        <v-toolbar-title class="font-weight-bold"
          >Milestone - {{ milestone.documentNo }} -
          {{ milestone.description }} | Tasks</v-toolbar-title
        >
        <v-spacer />
        <v-btn
          color="secondary"
          class="mr-2"
          @click="
            routeTo({
              name: 'InvoicesList',
              query: {
                documentNo: milestone.documentNo,
                milestoneLine: milestone.lineNo,
              },
            })
          "
          v-if="canRequestForPay.length !== 0"
        >
          request for payment
          <v-icon class="ml-2">sell</v-icon>
        </v-btn>
        <v-btn
          class="teal white--text text-capitalize mx-2"
          @click="addActivity"
        >
          <v-icon left>add</v-icon>
          Add Activity
        </v-btn>
        <v-btn class="primary text-capitalize" @click="addNewTask">
          <v-icon left>add</v-icon>
          Add Task
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-toolbar dense flat>
        <v-tabs v-model="tab" color="primary" dense>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="(item, i) in milestoneTabs"
            :key="i"
            class="text-capitalize font-weight-bold"
            dense
          >
            <v-icon class="mx-2">{{ item.icon }}</v-icon>
            {{ item.text }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <MilestoneSearch />
      </v-toolbar>
      <v-divider />
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <MilestoneTaskList />
        </v-tab-item>
        <v-tab-item>
          <MilestoneTaskGantt />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script>
import MilestoneTaskGantt from "./MilestoneTaskGantt.vue";
import MilestoneTaskList from "./MilestoneTaskList.vue";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import MilestoneSearch from "./MilestoneSearch.vue";
import RouteMixin from "../mixins/RouteMixin";
import DateMixin from "../mixins/DateMixin";
export default {
  name: "Milestone",
  components: {
    MilestoneTaskGantt,
    MilestoneTaskList,
    MilestoneSearch,
  },
  mixins: [RouteMixin, DateMixin],
  data: function () {
    return {
      tab: 0,
      today: new Date(),
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.decryptRoute(to).query) {
        v.$store.dispatch("Projects/getActivities", {
          documentNo: v.decryptRoute(to).query.documentNo,
          milestoneLine: v.decryptRoute(to).query.milestoneLine,
        });
      }
      v.$store.dispatch("Dashboard/setMiniDrawer", true);
      v.$store.dispatch("Projects/getMilestone", {
        documentNo: v.decryptRoute(to).query.documentNo,
        lineNo: v.decryptRoute(to).query.milestoneLine,
      });
    });
  },
  mounted() {
    console.log(this);
  },
  computed: {
    milestoneTabs() {
      return this.$store.getters["Projects/projectGetters"]("milestoneTabs");
    },
    milestone: function () {
      const milestone =
        this.$store.getters["Projects/projectGetters"]("milestone");
      return milestone ? milestone : {};
    },
    user() {
      return AuthService.user;
    },
    canRequestForPay() {
      return this.milestone
        ? this.milestone.milestoneInvoices
          ? this.milestone.milestoneInvoices.filter(
              (i) => i.requestForPayment === false
            )
          : []
        : [];
    },
  },
  methods: {
    addNewTask: function () {
      this.routeTo({
        name: "Task",
        query: {
          milestoneLine: parseInt(this.$route.query.milestoneLine),
          documentNo: this.decryptRoute(this.$route).query.documentNo,
        },
      });
    },
    requestForPayment() {
      this.milestone.requestForPayment = true;
      this.$store.dispatch("Projects/requestPay", this.milestone);
    },
    addActivity() {
      this.routeTo({
        name: "Activity",
        query: {
          milestoneLine: parseInt(this.$route.query.milestoneLine),
          documentNo: this.decryptRoute(this.$route).query.documentNo,
        },
      });
    },
  },
};
</script>
<style>
@import url("../styles.css");
</style>
