<template>
  <v-container>
    <v-card>
      <v-card-title class="font-weight-medium">
        <v-icon class="mr-2">mdi-credit-card</v-icon>
        Imprest Card
        <v-spacer />
        <v-btn class="pa-2" text @click="$router.back()" color="error">
          <v-icon left>mdi-arrow-left</v-icon>
          Go Back
        </v-btn>
        <div>
          <ImprestCardActions
            :approve="approve"
            :formData="formData"
            :imprestsDetails="imprestsDetails"
            :isEdit="isEdit"
            :isIssued="isIssued"
            :rejectImprest="rejectImprest"
            :routeParams="routeParams"
            :returnImprest="returnImprest"
            @approveImprest="approveImprest"
            @cancelApprovalRequest="cancelApprovalRequest"
            @deleteImprest="deleteImprest"
            @saveImprest="saveImprest"
            @sendForApproval="sendForApproval"
            @CheckBudget="CheckBudget"
            @cancelLiquidation="cancelLiquidation"
            @ReopenDocument="ReopenDocument"
            :saveButtonCaption="saveButtonCaption"
            :imprestActionsPosition="imprestActionsPosition"
            v-if="imprestActionsPosition === 'top'"
          />
        </div>
      </v-card-title>
      <v-divider />

      <v-row class="flex-nowrap">
        <v-col cols="12" md="12" sm="12">
          <v-card flat class="pa-3">
            <!--BEGIN form-->
            <v-card-text
              :class="imprestActionsPosition === 'bottom' ? 'mb-n2' : 'mb-n6'"
            >
              <div class="mt-n4 mb-3 d-flex justify-end" v-if="routeParams">
                <v-chip
                  v-if="$route.params.approveCode"
                  class="ma-2"
                  color="primary"
                  >Made By: {{ getEmployee(formData.employeeNo) }}</v-chip
                >
                <v-card-title class="mt-n2 font-weight-medium"
                  >STATUS:
                </v-card-title>
                <v-chip
                  class="ma-2"
                  :color="getStatusColor(imprestsDetails.imprestStatus).color"
                  text-color="white"
                >
                  {{
                    imprestsDetails.imprestStatus === "Draft"
                      ? "Open"
                      : imprestsDetails.imprestStatus
                  }}
                </v-chip>
              </div>
              <v-form v-model="isValid" ref="entryForm">
                <v-row>
                  <!--BEGIN Imprest type-->
                  <v-col cols="12" md="6" sm="12" class="mb-n6">
                    <v-autocomplete
                      v-model="formData.imprestType"
                      :items="imprestTypes"
                      dense
                      outlined
                      :item-text="(item) => item.description"
                      :item-value="(item) => item.code"
                      :readonly="
                        routeParams && formData.imprestStatus !== 'Draft'
                      "
                      :rules="[rules.required]"
                    >
                      <template v-slot:label>
                        <label v-if="!routeParams"
                          >Select Imprest Type
                          <span class="required">*</span></label
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!--END imprest type-->

                  <!--BEGIN Purpose-->
                  <v-col cols="12" md="6" sm="12" class="mb-n6">
                    <v-text-field
                      dense
                      outlined
                      required
                      :rules="[rules.required]"
                      v-model="formData.Purpose"
                      persistent-placeholder
                      :readonly="
                        routeParams && formData.imprestStatus !== 'Draft'
                      "
                    >
                      <template v-slot:label>
                        <label v-if="!routeParams"
                          >Purpose<span class="required">*</span></label
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                  <!--END Reference Number-->

                  <!--BEGIN Training Imprest-->
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="mb-n6"
                    v-if="
                      formData.imprestType === 'TRAINING' &&
                      settings.is_training_imprest_fields
                    "
                  >
                    <v-select
                      v-model="formData.methodOfStudy"
                      :items="settings.training_methods"
                      dense
                      outlined
                      :readonly="
                        routeParams && formData.imprestStatus !== 'Draft'
                      "
                      :rules="[rules.required]"
                    >
                      <template v-slot:label>
                        <label v-if="!routeParams"
                          >Method Of Training<span class="required"
                            >*</span
                          ></label
                        >
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="mb-n6"
                    v-if="
                      formData.imprestType === 'TRAINING' &&
                      settings.is_training_imprest_fields
                    "
                  >
                    <v-text-field
                      dense
                      outlined
                      :rules="[rules.required]"
                      v-model="formData.TrainingProvider"
                      persistent-placeholder
                      :readonly="
                        routeParams && formData.imprestStatus !== 'Draft'
                      "
                    >
                      <template v-slot:label>
                        <label v-if="!routeParams"
                          >Training Povider<span class="required"
                            >*</span
                          ></label
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                  <!--END Training Imprest-->

                  <!--BEGIN FROM (where you travelling from)-->
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="mb-n6"
                    v-if="formData.imprestType === 'TRAVEL'"
                  >
                    <v-text-field
                      v-if="routeParams && formData.imprestStatus !== 'Draft'"
                      dense
                      outlined
                      label="From"
                      readonly
                      v-model="formData.From"
                      persistent-placeholder
                    />

                    <ImprestLocationAutoComplete
                      v-else
                      @update="update"
                      :routeParams="routeParams"
                      :type="1"
                      :value="
                        formData.From.length > 0 ? imprestsDetails.From : ''
                      "
                    />
                  </v-col>
                  <!--BEGIN FROM (where you travelling from)-->

                  <!--BEGIN TO (where you travelling to)-->
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="mb-n6"
                    v-if="formData.imprestType === 'TRAVEL'"
                  >
                    <v-text-field
                      v-if="routeParams && formData.imprestStatus !== 'Draft'"
                      dense
                      outlined
                      label="To"
                      readonly
                      v-model="formData.To"
                      persistent-placeholder
                    />
                    <ImprestLocationAutoComplete
                      v-else
                      @update="update"
                      :routeParams="routeParams"
                      :type="2"
                      :value="formData.To.length > 0 ? imprestsDetails.To : ''"
                    />
                  </v-col>
                  <!--END TO (where you travelling to)-->

                  <!--BEGIN Travel Type-->
                  <v-col
                    cols="12"
                    md="6"
                    class="mb-n6"
                    v-if="formData.imprestType === 'TRAVEL'"
                  >
                    <v-select
                      :items="travelTypes"
                      :item-text="(item) => item.name"
                      :item-value="(item) => item.name"
                      dense
                      v-model="formData.Type"
                      outlined
                      :rules="[rules.required]"
                      persistent-placeholder
                      :readonly="
                        routeParams && formData.imprestStatus !== 'Draft'
                      "
                      ><template v-slot:label>
                        <label v-if="!routeParams"
                          >Type<span class="required">*</span></label
                        >
                      </template></v-select
                    >
                  </v-col>
                  <!--END Travel Type-->

                  <!--BEGIN Mode of transport-->
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="mb-n6"
                    v-if="formData.imprestType === 'TRAVEL'"
                  >
                    <v-select
                      :items="modeOfTransport"
                      :item-text="(item) => item.description"
                      :item-value="(item) => item.code"
                      v-model="formData.modeOfTransportCode"
                      dense
                      title="Select Mode Of Transport (Optional)"
                      outlined
                      :readonly="
                        routeParams && formData.imprestStatus !== 'Draft'
                      "
                      persistent-placeholder
                    >
                      <template v-slot:label>
                        <label v-if="!routeParams"
                          >Mode Of Transport<span class="required"
                            >*</span
                          ></label
                        >
                      </template></v-select
                    >
                  </v-col>
                  <!--END Mode of transport-->

                  <!--BEGIN Is it an emergency-->
                  <v-col
                    cols="12"
                    :md="6"
                    class="mb-n6"
                    v-if="
                      (!routeParams && formData.imprestType === 'TRAVEL') ||
                      (routeParams && this.formData.emergency === true)
                    "
                  >
                    <v-select
                      :items="emergencies"
                      :item-text="(item) => item.text"
                      :item-value="(item) => item.value"
                      dense
                      v-model="formData.emergency"
                      outlined
                      persistent-placeholder
                      :readonly="
                        routeParams && formData.imprestStatus !== 'Draft'
                      "
                      ><template v-slot:label>
                        <label v-if="!routeParams"
                          >Emergency(Optional)<span class="required"></span
                        ></label> </template
                    ></v-select>
                  </v-col>
                  <!--END Is it an emergency-->

                  <!--BEGIN Is it an emergency-->
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="mb-n6"
                    v-if="
                      (!routeParams && formData.imprestType === 'TRAVEL') ||
                      (routeParams && this.formData.emergency === true)
                    "
                  >
                    <v-text-field
                      dense
                      outlined
                      required
                      v-model="formData.emergencyReason"
                      persistent-placeholder
                      :rules="
                        formData.emergency === true ? [rules.required] : null
                      "
                      :readonly="
                        routeParams && formData.imprestStatus !== 'Draft'
                      "
                    >
                      <template v-slot:label>
                        <label v-if="!routeParams"
                          >Emergency Reason<span class="required"></span
                        ></label>
                      </template>
                    </v-text-field>
                  </v-col>
                  <!--END Is it an emergency-->

                  <!--BEGIN start date pickers-->
                  <v-col
                    cols="12"
                    :md="isMultiCurrencyEnabled ? '4' : '6'"
                    sm="12"
                    class="mb-n6 mt-2"
                  >
                    <v-menu
                      v-model="formData.startDateCalendarButtonIcon"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formData.startDate"
                          prepend-inner-icon="mdi-calendar"
                          color="primary"
                          dense
                          outlined
                          :rules="[rules.required]"
                          v-bind="attrs"
                          v-on="on"
                          :readonly="
                            routeParams && formData.imprestStatus !== 'Draft'
                          "
                        >
                          <template v-slot:label>
                            <label v-if="!routeParams"
                              >Start date<span class="required">*</span></label
                            >
                          </template></v-text-field
                        >
                      </template>
                      <v-date-picker
                        v-model="formData.startDate"
                        @input="formData.startDateCalendarButtonIcon = false"
                        :min="formData.emergency === true || company === 'KISM' ? 0 : startDateByType"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!--END start dates pickers-->

                  <!--BEGIN end date pickers-->
                  <v-col
                    cols="12"
                    :md="isMultiCurrencyEnabled ? '4' : '6'"
                    sm="12"
                    class="mb-n6 mt-2"
                  >
                    <v-menu
                      v-model="formData.endDateCalendarButtonIcon"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formData.endDate"
                          prepend-inner-icon="mdi-calendar"
                          color="primary"
                          dense
                          outlined
                          :rules="[rules.required]"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="formData.startDate === ''"
                          :readonly="
                            routeParams && formData.imprestStatus !== 'Draft'
                          "
                          ><template v-slot:label>
                            <label v-if="!routeParams"
                              >End date<span class="required">*</span></label
                            >
                          </template></v-text-field
                        >
                      </template>
                      <v-date-picker
                        v-model="formData.endDate"
                        @input="formData.endDateCalendarButtonIcon = false"
                        :min="formData.startDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!--END end dates pickers-->
                  <!--BEGIN currency picker -->
                  <v-col
                    cols="12"
                    md="4"
                    v-if="isMultiCurrencyEnabled"
                    sm="12"
                    class="mb-n6 mt-2"
                  >
                    <v-autocomplete
                      :items="currencies"
                      :item-text="(item) => `${item.symbol}${item.displayName}`"
                      :item-value="(item) => item.code"
                      v-model="formData.currencyCode"
                      dense
                      title="Select Currency (Optional)"
                      outlined
                      :readonly="
                        routeParams && formData.imprestStatus !== 'Draft'
                      "
                      persistent-placeholder
                    >
                      <template v-slot:label>
                        <label v-if="!routeParams">Select Currency</label>
                      </template></v-autocomplete
                    >
                  </v-col>
                  <!--END currency picker -->
                  <!--BEGIN purpose of the purpose of the event-->
                  <v-col cols="12" md="12" sm="12" class="mt-n2">
                    <v-textarea
                      rows="4"
                      dense
                      outlined
                      required
                      auto-grow
                      clear-icon="mdi-close-circle"
                      :rules="[rules.required]"
                      v-model="formData.description"
                      :readonly="
                        routeParams && formData.imprestStatus !== 'Draft'
                      "
                    >
                      <template v-slot:label>
                        <label v-if="!routeParams"
                          >Purpose/Objective/Deliverables.<span class="required"
                            >*</span
                          ></label
                        >
                      </template>
                    </v-textarea>
                  </v-col>
                  <!--END purpose-->
                </v-row>

                <!--BEGIN dimension panel-->
                <v-row no-gutters class="mb-5">
                  <v-col cols="12">
                    <v-expansion-panels v-model="panel">
                      <v-expansion-panel>
                        <!--BEGIN dimensions-->
                        <v-expansion-panel-header>
                          <span class="table-font"
                            >Click to view &amp; add available dimensions</span
                          >
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <imprest-dimensions
                            :dimensionValues="dimensionValues"
                            :formData="formData"
                            :displayImprestLineActions="
                              displayImprestLineActions
                            "
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
                <!--END dimension panel-->

                <v-row v-if="formData.imprestStatus === 'Rejected'">
                  <v-col cols="12" md="12">
                    <v-textarea
                      rows="2"
                      dense
                      outlined
                      auto-grow
                      color="red"
                      clear-icon="mdi-close-circle"
                      label="Comments"
                      v-model="formData.rejectReason"
                      readonly
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <!--BEGIN attachment section-->
                  <v-col cols="12" md="12" sm="12">
                    <!--BEGIN attachment upload-->
                    <div class="mt-n3">
                      <v-file-input
                        accept="image/jpeg,image/png,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        v-model="attachmentBlobs"
                        multiple
                        prepend-icon=""
                        append-icon="mdi-paperclip"
                        outlined
                        :disabled="
                          routeParams && formData.imprestStatus !== 'Draft'
                        "
                        counter
                        :show-size="1000"
                        chips
                        :rules="rule.attachment"
                        :clearable="true"
                        label="Upload Attachment"
                      >
                        <template v-slot:selection="{ text, index, files }">
                          <v-chip small label v-if="index < 2" color="primary">
                            {{ text }}
                          </v-chip>
                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </div>
                    <!--END attachment upload-->

                    <!--BEGIN file attachments view-->
                    <div
                      v-if="attachmentBase64.length !== 0"
                      class="mt-n2 mb-2"
                    >
                      <v-icon small>mdi-paperclip</v-icon>
                      <span class="font-weight-regular">File Attachments</span>
                      <br />
                      <div class="d-flex flex-row">
                        <div v-for="(item, i) in attachmentBase64" :key="i">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                :key="i"
                                close
                                dark
                                class="mx-2"
                                color="green lighten-1"
                                @click="openDialog(item.attachment)"
                                @click:close="removeBase64(item.fileName)"
                                label
                                v-bind="attrs"
                                v-on="on"
                              >
                                <span class="table-font">{{
                                  item.fileName || "My Attachment"
                                }}</span>
                              </v-chip>
                            </template>
                            <span>click to open</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>
                    <!--END file attachments view-->
                  </v-col>
                  <!--END attachment section-->
                </v-row>

                <!--BEGIN expense items-->
                <expense-items
                  :itemDialog="itemDialog"
                  :expenseCodes="expenseCodes"
                  :formData="formData"
                  :settings="settings"
                  :autoFill="autoFill"
                  :routeParams="routeParams"
                  :dimensionValues="dimensionValues"
                  :isEdit="isEdit"
                  :displayImprestLineActions="displayImprestLineActions"
                  :currencyCode="
                    formData.currencyCode.length > 0
                      ? formData.currencyCode
                      : undefined
                  "
                />
                <!--END expense items-->
              </v-form>
              <!--END form-->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <ApprovalEntries
        v-if="routeParams && formData.imprestStatus !== 'Draft'"
        :approvalEntries="imprestsDetails.approvalEntry"
        class="mt-3"
      />
      <v-divider />
      <ImprestCardActions
        :approve="approve"
        :formData="formData"
        :imprestsDetails="imprestsDetails"
        :isEdit="isEdit"
        :isIssued="isIssued"
        :rejectImprest="rejectImprest"
        :routeParams="routeParams"
        :returnImprest="returnImprest"
        @approveImprest="approveImprest"
        @cancelApprovalRequest="cancelApprovalRequest"
        @deleteImprest="deleteImprest"
        @saveImprest="saveImprest"
        @sendForApproval="sendForApproval"
        @CheckBudget="CheckBudget"
        @ReopenDocument="ReopenDocument"
        :saveButtonCaption="saveButtonCaption"
        :imprestActionsPosition="imprestActionsPosition"
        v-if="imprestActionsPosition === 'bottom'"
        class="mt-2"
      />
    </v-card>
    <ImprestAttachmentPreview
      :base64="base64"
      :dialog="imgDialog"
      v-on:close-dialog="closeDialog"
      :routeParams="routeParams"
      :isEdit="isEdit"
    />
  </v-container>
</template>

<script>
import expenseItems from "./ImprestExpenseItemsTable.vue";
import ImprestMixin from "../ImprestMixin.js";
import ApprovalEntries from "./ApprovalEntries.vue";
import ImprestAttachmentPreview from "./ImprestAttachmentPreview.vue";
import ImprestLocationAutoComplete from "./ImprestLocationAutoComplete.vue";
import ImprestDimensions from "./ImprestDimensions.vue";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
import ImprestCardActions from "./ImprestCardActions.vue";
export default {
  name: "ImprestCard",
  mixins: [ImprestMixin],
  components: {
    ApprovalEntries,
    ImprestLocationAutoComplete,
    ImprestAttachmentPreview,
    expenseItems,
    ImprestDimensions,
    ImprestCardActions,
  },
  data: function () {
    return {
      isEdit: false,
      base64: "",
      isValid: true,
      panel: [0],
      returnImprest: "returnImprest",
      rejectImprest: "rejectImprest",
      approve: "approve",
      time: "", // vuetify timepicker return value to model
      itemDialog: false,
      imgDialog: false,
      travelTypes: [{ name: "Local" }, { name: "International" }],
      imprestType: [
        { text: "Travel", value: "Travel" },
        { text: "Other", value: "Other" },
      ],
      emergencies: [{ text: "Emergency", value: true }],
      attachmentBlobs: [],
      attachmentBase64: [],
      formData: {
        imprestType: "",
        documentType: "Imprest",
        postingDate: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        employeeNo: "",
        description: "",
        referenceNo: "",
        imprestLinesAPI: [],
        attachments: [],
        Type: "",
        modeOfTransportCode: "",
        From: "",
        To: "",
        no: "",
        Purpose: "",
        startDate: "",
        endDate: "",
        currencyCode: "",
        shortcutDimension1Code: "",
        shortcutDimension2Code: "",
        shortcutDimension3Code: "",
        shortcutDimension4Code: "",
        dimensionSpeedkeyCode: "",
        fundNo: "",
        globalDimension3Code: "",
        globalDimension4Code: "",
        globalDimension5Code: "",
        globalDimension6Code: "",
        globalDimension7Code: "",
        globalDimension8Code: "",
        emergency: false,
        methodOfStudy: "",
        TrainingProvider: "",
      },
      rules: {
        attachment: [
          (value) => !!value || "Required field",
          (value) =>
            !value ||
            value.size < 3000000 ||
            "file size ize should be less than 3 MB!",
        ],
        required: (value) => !!value || "Required.",
      },
      company: process.env.VUE_APP_COMPANY
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("imprest/setBreadCrumbs", to);
      v.$store.dispatch("imprest/getExpenseCodes");
      v.$store.dispatch("imprest/getDimensionValues");
      v.$store.dispatch("imprest/getModeOfTransport");
      v.$store.dispatch("imprest/getUnitsOfMeasure");
      v.$store.dispatch("imprest/getCurrencies");
      v.$store.dispatch("imprest/getfunds");
      v.$store.dispatch("imprest/getdimensionSpeedKey");
      v.$store.dispatch("imprest/getImprestTypeApi");
      if (to.params.code || to.params.approveCode) {
        v.isEdit = true;
        v.$store.dispatch(
          "imprest/getImprestsDetails",
          to.params.code || to.params.approveCode
        );
      }
    });
  },
  mounted() {
    Event.$on("getImprestAfterSave", () => {
      this.$router.go();
    });
  },
  computed: {
    rule() {
      return {
        attachment: [(value) => !!value || "Attachment is required!"],
      };
    },
    readOnlyCheck() {
      return this.imprestStatus !== "Draft" && this.isEdit;
    },
    expenseCodes() {
      return this.$store.getters["imprest/expenseCodes"];
    },
    dimensionValues() {
      return this.$store.getters["imprest/imprestGetters"]("dimensions");
    },
    imprestTypes() {
      return this.$store.getters["imprest/imprestGetters"]("imprestTypes");
    },
    currencies() {
      return this.$store.getters["imprest/imprestGetters"]("currencies");
    },
    settings() {
      let settings = this.$store.getters["Admin/adminGetters"]("settings");
      return Object.keys(settings).length > 0
        ? JSON.parse(settings.cashmgt_settings)
        : null;
    },
    isMultiCurrencyEnabled() {
      return this.settings ? this.settings.multiple_currency : false;
    },
    saveButtonCaption() {
      return this.settings ? this.settings.cashmgt_save_caption : "Save";
    },
    imprestActionsPosition() {
      return this.settings ? this.settings.cashmgt_actions_position : "top";
    },
    startDateByType() {
      const today = new Date();
      return this.formData.imprestType === "TRAVEL" && this.settings
        ? new Date(
            today.getTime() +
              this.settings.imprest_travel_request_lock_days *
                24 *
                60 *
                60 *
                1000
          )
            .toISOString()
            .substr(0, 10)
        : today.toISOString().substr(0, 10);
    },
    employees() {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
    filteredDimensionValues() {
      return this.dimensionValues.filter((dimension) => {
        return (
          dimension.code === this.formData.shortcutDimension1Code ||
          dimension.code === this.formData.shortcutDimension2Code ||
          dimension.code === this.formData.shortcutDimension3Code ||
          dimension.code === this.formData.shortcutDimension4Code
        );
      });
    },
    modeOfTransport() {
      return this.$store.getters["imprest/modeOfTransport"];
    },
    locations() {
      return this.$store.getters["imprest/locations"];
    },
    routeParams() {
      return this.$route.params.code || this.$route.params.approveCode;
    },
    employee() {
      return this.$store.getters["auth/user"].profile;
    },
    imprestsDetails() {
      return this.$store.getters["imprest/imprestsDetails"];
    },
    displayImprestLineActions() {
      return !this.routeParams || this.formData.imprestStatus === "Draft";
    },
    isIssued: function () {
      return this.imprestsDetails.imprestStatus === "Issued";
    },
    autoFill: function () {
      return this.formData.imprestType !== ""
        ? this.formData.imprestType
        : null;
    },
  },

  methods: {
    removeBase64(item) {
      const itemIndex = this.attachmentBase64.findIndex(
        (el) => el.fileName === item
      );
      const itemBlobIndex = this.attachmentBase64.findIndex(
        (file) => file.name === item
      );
      this.attachmentBase64.splice(itemIndex, 1);
      this.attachmentBlobs.splice(itemBlobIndex, 1);
    },
    /**
     * a method to save an imprest in BC
     */
    saveImprest: function () {
      if (!this.isValid) {
        this.$refs.entryForm.validate();
      } else {
        delete this.formData.amountToPayHeader;
        const data = { ...this.formData };
        data.employeeNo = this.employee.number;
        Array.prototype.push.apply(data.attachments, this.attachmentBase64);
        this.isEdit
          ? this.$store.dispatch("imprest/updateImprest", data)
          : this.$store.dispatch("imprest/saveImprest", data);
      }
    },
    /**
     * Remove an imprest record from the list
     */
    deleteImprest: function () {
      const params = {
        title: ` imprest ID ${this.formData.no}`,
        text: "Are you sure you want to remove this imprest request?",
        onConfirm: () => {
          this.$store.dispatch("imprest/deleteImprest", { ...this.formData });
        },
      };
      this.$confirm.show(params);
    },
    /**
     * Send request for approval
     */
    sendForApproval: function (method) {
      if (!this.isValid) {
        this.$refs.entryForm.validate();
      } else {
        const data = {
          docNo: this.imprestsDetails.no,
          ...this.formData,
          method,
        };
        Array.prototype.push.apply(data.attachments, this.attachmentBase64);
        data.employeeNo = this.employee.number;
        const params = {
          title: "Send Approval Request",
          text: "Are you sure you want to send this imprest request for approval?",
          onConfirm: () => {
            this.$store.dispatch("imprest/updateImprestCard", data);
          },
        };
        this.$confirm.show(params);
      }
    },
    cancelApprovalRequest: function () {
      const data = {
        docNo: this.imprestsDetails.no,
      };
      const params = {
        title: ` Imprest ID ${this.imprestsDetails.no}`,
        text: "Are you sure you want to cancel this imprest`s approval request?",
        onConfirm: () => {
          this.$store.dispatch("imprest/cancelApprovalRequest", { ...data });
        },
      };
      this.$confirm.show(params);
    },
    CheckBudget: function () {
      this.$store.dispatch("imprest/CheckBudget", {
        documentNo: this.imprestsDetails.no,
      });
    },
    ReopenDocument: function () {
      const data = {
        docNo: this.imprestsDetails.no,
      };
      const params = {
        title: ` Imprest ID ${this.imprestsDetails.no}`,
        text: "Are you sure you want to reopen this imprest document?",
        onConfirm: () => {
          this.$store.dispatch("imprest/ReopenDocument", { ...data });
        },
        onCancel: () => {
          this.$store.dispatch("imprest/getImprests");
        },
      };
      this.$confirm.show(params);
    },
    /**
     * Expense imprest line total amount
     * @param item
     * @returns {number}
     */
    approveImprest(status) {
      this.$store.commit("imprest/SET_APPROVAL_MODAL", {
        imprest_code: this.imprestsDetails.no,
        approvalEntryNo: this.imprestsDetails.approvalEntry[0].entryNo,
        open: true,
        status: status,
      });
    },
    closeDialog: function (val) {
      this.itemDialog = val;
      this.imgDialog = val;
    },
    update: function (value) {
      value.type === 1
        ? (this.formData.From = value.val)
        : (this.formData.To = value.val);
    },
    openDialog(val) {
      this.imgDialog = true;
      this.base64 = val;
    },
    deleteAttachment: function (data) {
      this.$confirm.show({
        title: `Entry No: ${data.fileName}`,
        text: "Are you sure you want to remove this?",
        onConfirm: () => {
          this.$store.dispatch("imprest/deleteAttachment", { ...data });
        },
        OnCancel: (e) => {
          return e;
        },
      });
    },
    getStatusColor: function (status) {
      return helpers.getColor(status);
    },
    getEmployee(no) {
      const employee = this.employees.filter((el) => el.number === no);
      return employee.length !== 0
        ? employee[0].firstName + " " + employee[0].lastName
        : "";
    },
    getFileBlob: async function (url, fileName) {
      return await helpers.getFileBlob(
        process.env.VUE_APP_FILE_API + url,
        fileName
      );
    },
    cancelLiquidation() {
      this.$confirm.show({
        title: ` imprest ID ${this.imprestsDetails.no}`,
        text: "Are you sure you want to cancel the liquidation process of this imprest?",
        onConfirm: () => {
          const formData = {
            docNo: this.imprestsDetails.no,
          };
          this.$store.dispatch("imprest/cancelApprovalRequest", formData);
        },
        onCancel: () => {
          this.$store.dispatch("imprest/getImprests");
        },
      });
    },
  },

  watch: {
    imprestsDetails: {
      handler: function (n, o) {
        if (this.routeParams) {
          this.formData = { ...this.imprestsDetails };

          const blob = [];
          this.formData.attachments.map((el) => {
            this.getFileBlob(el.attachment, el.fileName).then((res) => {
              blob.push(res);
            });
          });
          this.attachmentBlobs = blob;
          delete this.formData.imprestLinesAPI;
          this.formData.imprestLinesAPI = this.imprestsDetails.imprestLinesAPI;
        }
      },
    },
    attachmentBlobs: {
      handler(n, o) {
        if (n.length !== 0) {
          const base64File = [];
          for (const file of n) {
            Promise.all([helpers.toBase64(file)]).then((res) => {
              res = res[0].split(",")[1];
              base64File.push({ attachment: res, fileName: file.name });
            });
          }
          this.attachmentBase64 = base64File;
        } else {
          this.attachmentBase64 = [];
        }
      },
    },

    "formData.startDate": {
      handler: function (n, o) {
        if (n.length > 0 && !this.routeParams) {
          this.formData.endDate = "";
        }
      },
    },
  },
};
</script>

<style scoped>
@import url("../styles/style.css");
.required {
  color: red;
}
</style>
