export default {
  milestones: (params) => `projects/milestones${getParams(params)}`,
  tasks: (params) => `projects/tasks${getParams(params)}`,
  showMilestone: (params) => `projects/milestones/show${getParams(params)}`,
  task: "projects/tasks",
  showTask: (params) => `projects/tasks/show${getParams(params)}`,
  updateTask: "projects/tasks/update",
  deleteTask: "projects/tasks/delete",
  lines: (params) => `projects/lines${getParams(params)}`,
  saveLine: "projects/lines",
  updateLine: "projects/lines/update",
  deleteLine: "projects/lines/delete",
  attachments: (params) => `projects/attachments${getParams(params)}`,
  updateAttachment: "projects/attachments/update",
  deleteAttachment: "projects/attachments/delete",
  projects: (params) => `projects${getParams(params)}`,
  activities: (params) => `projects/activities${getParams(params)}`,
  showActivity: (params) => `projects/activities/show${getParams(params)}`,
  activity: "projects/activities",
  updateActivity: "projects/activities/update",
  deleteActivity: "projects/activities/delete",
};

function getParams(params) {
  let param = "";
  if (params) {
    param = `?${Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&")}`;
  }
  return param;
}
