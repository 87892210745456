import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-10",attrs:{"fluid":""}},[_c(VCard,{staticStyle:{"height":"600px"},attrs:{"elevation":"0","tile":""}},[_c(VCardTitle,{staticClass:"overline"},[(_vm.$vuetify.breakpoint.smAndDown)?_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Back ")],1):_vm._e(),_vm._v(" "+_vm._s(_vm.period.code)+" Appraisal Period ")],1),(!_vm.isReviewDocument)?_c(VSkeletonLoader,{attrs:{"type":"image","width":"100%","height":"500"}}):_c('v-text',{staticClass:"pa-0",staticStyle:{"height":"500px","overflow":"auto"}},[(_vm.isReviewDocument)?_c('iframe',{staticStyle:{"border":"none"},attrs:{"width":"100%","height":"100%","src":("data:application/pdf;base64," + _vm.reviewDocument)}}):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }