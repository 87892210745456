import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";
import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
import Payslip from "./views/Payslip";
import PayslipList from "./components/PayslipList";
import PayslipView from "./components/PayslipView";

export default [
  {
    path: "/payslip",
    component: AppDashboard,
    children: [
      {
        path: "",
        components: { view: Payslip },
        children: [
          {
            path: "",
            name: "Payslip List",
            component: PayslipList,
            meta: { middleware: [Auth] },
          },
          {
            path: "view/:code",
            name: "Payslip View",
            component: PayslipView,
            meta: { middleware: [Auth] },
          },
        ],
      },
    ],
  },
];
