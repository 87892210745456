import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VAppBar,{attrs:{"color":"transparent","dense":"","flat":"","elevation":"0"}},[_c(VBreadcrumbs,{staticClass:"ml-n6 breadcrumb-item",attrs:{"divider":"\\","items":_vm.breadcrumbs}}),_c(VSpacer),_c(VChip,[(!_vm.userData.is_navuser)?_c(VAvatar,{staticClass:"text-uppercase white--text mx-1 ml-n2 breadcrumb-item",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(("" + (_vm.userData.email[0]) + (_vm.userData.email[1])))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.userData.is_navuser ? _vm.userData.username : _vm.userData.employee)+" ")],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }