import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";
import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";

import ProjectList from "./components/ProjectList";
import MilestoneTaskCard from "./components/MilestoneTaskCard";
import MilestoneActivity from "./components/MilestoneActivity";
import Milestone from "./components/Milestone";

export default [
  {
    path: "/projects",
    component: AppDashboard,
    children: [
      {
        path: "",
        redirect: "/projects/list",
      },
      {
        path: "list",
        name: "ProjectList",
        components: {
          view: ProjectList,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "milestone",
        name: "Milestone",
        components: {
          view: Milestone,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "task",
        name: "Task",
        components: {
          view: Milestone,
          action: MilestoneTaskCard,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "activity",
        name: "Activity",
        components: {
          view: Milestone,
          action: MilestoneActivity,
        },
        meta: { middleware: [Auth] },
      },
    ],
  },
];
