<template>
  <v-card flat>
    <v-card-title>
      Programmes
      <v-spacer></v-spacer>
      <v-btn
        depressed
        color="primary"
        @click="addNewLine"
        v-if="
          !isNew &&
          (formData.submittedBy === user.number || formData.submittedBy === '')
        "
      >
        <v-icon>mdi-plus</v-icon>
        add programme
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="trainingLinesHeaders"
      :items="trainingLinesList"
      hide-default-footer
    >
      <!-- programme code -->
      <template v-slot:item.programmeCode="{ item }">
        <v-autocomplete
          v-if="displayConditionally(item)"
          @focusout="autosaveTrainingLine"
          v-model="line.programmeCode"
          :rules="rules.required"
          :items="courses"
          :item-text="(item) => `${item.no} - ${item.description}`"
          :item-value="(item) => item.no"
          :hide-details="false"
          label="Select Programme"
          dense
          single-line
          class="mb-n3"
        ></v-autocomplete>
        <span v-else>{{ item.programmeCode }}</span>
      </template>
      <!-- training details -->
      <template v-slot:item.trainingDetails="{ item }">
        <v-text-field
          @focusout="autosaveTrainingLine"
          v-model="line.trainingDetails"
          v-if="displayConditionally(item)"
          label="Description"
          dense
          single-line
          class="mb-n3"
        />
        <span v-else>{{ item.trainingDetails }}</span>
      </template>
      <!-- start date -->
      <!-- <template v-slot:item.startDate="{ item }">
        <v-menu
          v-if="displayConditionally(item)"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="line.startDate"
              label="Start date"
              single-line
              v-bind="attrs"
              v-on="on"
              :rules="rules.required"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="line.startDate"
            @change="save"
            :min="startDate"
            @click:date="autosaveTrainingLine"
          />
        </v-menu>
        <span v-else>{{ item.startDate }}</span>
      </template> -->
      <!-- start time -->
      <!-- <template v-slot:item.startTime="{ item }">
        <v-menu
          v-if="displayConditionally(item)"
          ref="menu3"
          v-model="menu3"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="line.startTime"
              label="Start time"
              single-line
              v-bind="attrs"
              v-on="on"
              :disabled="!line.startDate"
              :rules="rules.required"
            ></v-text-field>
          </template>
          <v-time-picker
            ampm-in-title
            v-model="line.startTime"
            :min="line.startDate === startDate ? startTime : null"
            @click:second="$refs.menu3.save(time)"
            @change="autosaveTrainingLine"
            use-seconds
          />
        </v-menu>
        <span v-else>{{ item.startTime }}</span>
      </template> -->
      <!-- end date -->
      <!-- <template v-slot:item.endDate="{ item }">
        <v-menu
          v-if="displayConditionally(item)"
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="line.endDate"
              :disabled="!line.startDate"
              :rules="rules.required"
              label="End date"
              single-line
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="line.endDate"
            @change="save2"
            :min="line.startDate"
            @click:date="autosaveTrainingLine"
          ></v-date-picker>
        </v-menu>
        <span v-else>{{ item.endDate }}</span>
      </template> -->
      <!-- End time -->
      <!-- <template v-slot:item.endTime="{ item }">
        <v-menu
          v-if="displayConditionally(item)"
          ref="menu4"
          v-model="menu4"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="line.endTime"
              label="End time"
              single-line
              v-bind="attrs"
              v-on="on"
              :disabled="!line.endDate"
              :rules="rules.required"
            ></v-text-field>
          </template>
          <v-time-picker
            ampm-in-title
            v-model="line.endTime"
            :min="line.endDate === line.startDate ? line.startTime : null"
            @click:second="$refs.menu4.save(time)"
            @change="autosaveTrainingLine"
            use-seconds
          ></v-time-picker>
        </v-menu>
        <span v-else>{{ item.endTime }}</span>
      </template> -->
      <template v-slot:item.trainers="{ item }">
        <span v-if="item.trainers">
          {{ item.trainers.name }}
        </span>
      </template>
      <!-- actions -->
      <template v-slot:item.actions="{ item }">
        <div>
          <div v-if="displayConditionally(item)">
            <!-- close -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                  class="mr-3"
                  @click="close"
                >
                  mdi-window-close
                </v-icon>
              </template>
              <span>Close Editing</span>
            </v-tooltip>
            <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
          </div>
          <div v-else>
            <v-icon color="green" class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
          </div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  name: "Programmes",
  props: {
    formData: Object,
    isEdit: Boolean,
    isSupervisor: Boolean,
    canEdit: Boolean,
    user: Object,
    isNew: Boolean,
  },
  data() {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      startTime: "",
      lineIndex: -1,
      headers: [
        {
          text: "Programme",
          value: "programmeCode",
          sortable: false,
          align: "start",
        },
        {
          text: "Description",
          value: "trainingDetails",
          sortable: false,
          align: "start",
        },
        {
          text: "Start Date",
          value: "startDate",
          sortable: false,
          align: "start",
        },
        {
          text: "Start Time (12hrs)",
          value: "startTime",
          sortable: false,
          align: "start",
        },
        {
          text: "End Date",
          value: "endDate",
          sortable: false,
          align: "start",
        },
        {
          text: "End Time(12hrs)",
          value: "endTime",
          sortable: false,
          align: "start",
        },
        {
          text: "Trainer",
          value: "trainers",
          sortable: false,
          align: "start",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "start",
        },
      ],
      line: {
        documentNo: "",
        endDate: "",
        programmeCode: "",
        programmeDescription: "",
        startDate: "",
        trainingDetails: "",
      },
      defaultLineItem: {
        documentNo: "",
        endDate: "",
        programmeCode: "",
        programmeDescription: "",
        startDate: "",
        trainingDetails: "",
      },
    };
  },
  computed: {
    trainingLinesHeaders() {
      if (!this.isEdit) {
        return this.headers.filter((item) =>
          ["programmeCode", "trainingDetails"].includes(item.value)
        );
      } else if (!this.canEdit && this.isEdit) {
        const newHeaders = this.headers.slice(0, 7);
        return newHeaders;
      } else {
        return this.headers;
      }
    },
    trainingLinesList: {
      get() {
        return this.formData.trainingNeedLines;
      },
      set(val) {
        return val;
      },
    },
    courses() {
      return this.$store.getters["Setup/setupGetter"]("trainingProgrammes");
    },
    rules() {
      return {
        required: [(v) => !!v || "field is required"],
      };
    },
  },
  methods: {
    displayConditionally(item) {
      if (item !== null) {
        if ("lineNo" in item) {
          if (item.lineNo === this.line.lineNo) {
            return true;
          }
          return false;
        } else if ("id" in item) {
          if (item.id === this.line.id) {
            return true;
          }
        }
      }
      return false;
    },
    addNewLine() {
      const addObj = Object.assign({}, this.defaultLineItem);
      addObj.id = this.trainingLinesList.length + 1;
      if (addObj.id > 1) {
        this.validDate(addObj.id);
      }
      this.trainingLinesList.unshift(addObj);
      this.editItem(addObj);
    },
    editItem(item) {
      this.lineIndex = this.trainingLinesList.indexOf(item);
      this.line = Object.assign({}, { ...item });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    save2(date) {
      this.$refs.menu2.save(date);
    },
    autosaveTrainingLine() {
      if (this.lineIndex > -1) {
        this.line.code = this.line.programmeCode;
        if (this.isEdit) {
          this.line.documentNo = this.formData.no;
        } else {
          delete this.line.documentNo;
        }
        Object.assign(this.trainingLinesList[this.lineIndex], this.line);
      }
    },
    close() {
      this.$router.back();
    },
    deleteItem: function (item) {
      if ("lineNo" in item) {
        this.$confirm.show({
          text: "Are you sure you want to remove this Item?",
          onConfirm: () => {
            this.$store.dispatch("Training/deleteTrainingLineItem", item);
          },
        });
      } else {
        for (const i in this.formData.trainingNeedLines) {
          if (
            this.formData.trainingNeedLines[i].programmeCode ===
            item.programmeCode
          ) {
            this.formData.trainingNeedLines.splice(i, 1);
            break;
          }
        }
      }
    },
    time() {
      const date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();

      this.startTime = hours + ":" + minutes + ":" + seconds;
    },
    validDate(id) {
      const item = this.formData.trainingNeedLines.filter((item) => {
        return item.id === id - 1;
      })[0];
      if (item) {
        this.startDate = item.endDate;
        this.startTime = item.endTime;
      }
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.activePicker2 = "YEAR"));
    },
  },
};
</script>
