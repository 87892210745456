import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";

import InventoryDashboard from "./components/InventoryDashboard";

export default [
  {
    path: "/inventory",
    component: AppDashboard,
    children: [
      {
        path: "",
        redirect: "/inventory/main",
      },
      {
        path: "main",
        name: "InventoryDashboard",
        components: {
          view: InventoryDashboard,
        },
        meta: { middleware: [Auth] },
      },
    ],
  },
];
