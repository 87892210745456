<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="text-h6 text-capitalize">
        {{ params.title }}
      </v-card-title>
      <v-card-text>
        {{ params.text }}
      </v-card-text>
      <v-card-text>
        <v-textarea
          v-if="params.input"
          v-model="input"
          :placeholder="params.label"
        />
        <v-text-field
          v-model="number"
          v-if="params.numberInput"
          outlined
          dense
          :placeholder="params.label"
          persistent-placeholder
          type="number"
          min="0"
          @keypress="filter($event)"
        >
          <template v-slot:label>
            <span class="text-capitalize">{{ params.label }}</span>
            <span class="red--text">*</span>
          </template>
        </v-text-field>
        <v-file-input
          v-if="params.fileInput"
          placeholder="Attach file"
          outlined
          dense
          persistent-placeholder
          type="file"
          accept="image/*, application/pdf"
          show-size
          prepend-icon="attach_file"
          label="Attach file"
          ref="fileInput"
          :value="fileAttachment"
          @change="uploadFile($event)"
        >
          <template v-slot:selection="{ text }">
            <v-chip
              v-if="file"
              label
              close
              @input="file = null"
              color="primary"
              dark
            >
              <span class="text-truncate">{{ text }}</span>
            </v-chip>
          </template>
        </v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" text @click="cancel">
          {{ params.rejectLabel || "No" }}
        </v-btn>
        <v-btn color="green darken-1" text @click="confirm">
          {{ params.acceptLabel || "Yes" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { helper } from "@/packages/cassandra-base/utilities";
export default {
  name: "Confirmation",
  data: function () {
    return {
      dialog: false,
      params: {
        title: "",
        text: "",
        input: false,
        label: "",
        rejectLabel: "",
        acceptLabel: "",
        onConfirm: {},
        onCancel: {},
        numberInput: false,
        fileInput: false,
      },
      input: "",
      file: null,
      fileAttachment: null,
      number: null,
      hasErrors: false,
    };
  },
  mounted() {
    Event.$on("show", (params) => {
      this.show(params);
    });
  },
  methods: {
    show: function (params) {
      this.params = params;
      this.dialog = true;
    },
    hide: function () {
      this.dialog = false;
    },
    confirm: function () {
      if (typeof this.params.onConfirm === "function") {
        if (this.params.input === true && this.input === "") {
          this.hasErrors = true;
        } else {
          this.hasErrors = false;
          this.params.onConfirm({
            input: this.input,
            number: this.number,
            file: this.file,
          });
          this.input = "";
          this.number = 0;
          this.file = null;
          this.hide();
        }
      } else {
        this.hide();
        this.input = "";
      }
    },
    cancel: function () {
      if (typeof this.params.onCancel === "function") {
        this.params.onCancel();
        this.hide();
        this.input = "";
      } else {
        this.hide();
        this.input = "";
      }
    },
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async uploadFile(event) {
      const file = event;
      if (file) {
        this.file = await helper.toBase64(file);
        this.file = this.file.split(",")[1];
        this.fileAttachment = file;
      }
    },
  },
};
</script>

<style scoped></style>
