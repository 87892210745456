export default {
  templates: "hrmis/appraisal/template",
  periods: "hrmis/appraisal/periods",
  reviews: "hrmis/appraisal/review",
  supervisorReviews: "hrmis/appraisal/review/peer-reviews",
  secondSupervisorReviews: "hrmis/appraisal/review/second-peer-reviews",
  reviewees: "hrmis/appraisal/review/reviewer",
  ratingScale: "hrmis/appraisal/rating-scale",
  kpi: "hrmis/appraisal/review/kpi",
  reviewObj: "hrmis/appraisal/review/reviewObj",
  appraisalReport: "hrmis/appraisal/review/reviewDocument",
  submitAppraisal: "hrmis/appraisal/review/submitAppraisal",
  reviewAppraisal: "hrmis/appraisal/review/reviewAppraisal",
  supervisorReport: "hrmis/appraisal/review/appraisedDocuments",
  reviewDocument: (param) => `hrmis/appraisal/review/${param}`,
  acceptReview: "hrmis/appraisal/v2/template/acceptReview",
  rejectReview: "hrmis/appraisal/v2/template/rejectReview",
  REVIEWER_COMMENT: "hrmis/appraisal/review/review-comment",
};
