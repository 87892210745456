import reportsLinks from "./reportsLinks";
import reportsStore from "./reportsStore";
import reportsRoute from "./reportsRoutes";

export default {
  install(vue, options) {
    if (options.router) {
      options.router.addRoute(...reportsRoute);
    }
    if (options.store) {
      options.store.registerModule("Reports", reportsStore);
      options.store.commit("Dashboard/ADD_LINK", reportsLinks);
    }
  },
};
