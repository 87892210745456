import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedKpi)?_c(VCard,{attrs:{"elevation":"0"}},[(_vm.NotSaved)?_c(VAlert,{staticClass:"mb-2 list-group-item",attrs:{"border":"left","type":"warning","dismissible":"","dense":""},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c(VBtn,{attrs:{"small":""},on:{"click":_vm.discard}},[_vm._v(" Discard Changes ")])]},proxy:true}],null,false,1000017660)},[_vm._v(" There are unsaved changes ")]):_vm._e(),_c(VCardTitle,{staticClass:"green--text list-group-item"},[_vm._v(" "+_vm._s(_vm.selectedKpi.kpiID)+" ")]),_c(VCardSubtitle,{staticClass:"text-justify my-2 list-group-item"},[_vm._v(" "+_vm._s(_vm.selectedKpi.description)+" ")]),_c(VDivider),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c('h1',{staticClass:"deep-orange--text text-center text-h6 list-group-item"},[_vm._v(" TARGET "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.selectedKpi.target)+"%")])])]),_c(VCol,[_c('h1',{staticClass:"list-group-item deep-orange--text text-center text-h6"},[_vm._v(" WEIGHT "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.selectedKpi.weight)+"/100")])])])],1)],1),_c(VDivider),_c(VCardText,[_c(VRow,[_c(VCol,{class:{ fullWidth: _vm.selfEdit },attrs:{"cols":"12","md":"6"}},[_c(VForm,{ref:"employeeForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"overline text-caption"},[_vm._v("Self Review")])],1),_c(VCardText,[(_vm.selectedKpi.ratingMethod === 'Rating Scale')?_c(VSelect,{attrs:{"disabled":!_vm.selfEdit,"dense":"","outlined":"","items":_vm.ratingScale,"item-value":"code","item-text":function (item) { return _vm.selectedKpi.ratingMethod === 'Rating Scale'
                    ? ((item.code) + " - " + (item.description))
                    : item.percent; },"label":"Rating","rules":_vm.rules.required},model:{value:(_vm.selectedKpi.selfRatingScore),callback:function ($$v) {_vm.$set(_vm.selectedKpi, "selfRatingScore", $$v)},expression:"selectedKpi.selfRatingScore"}}):_c(VTextField,{attrs:{"rules":_vm.rules.required,"disabled":!_vm.selfEdit,"dense":"","type":"number","outlined":"","label":"Score"},model:{value:(_vm.selectedKpi.selfRatingScore),callback:function ($$v) {_vm.$set(_vm.selectedKpi, "selfRatingScore", $$v)},expression:"selectedKpi.selfRatingScore"}}),_c(VTextarea,{attrs:{"rules":_vm.rules.required,"disabled":!_vm.selfEdit,"dense":"","rows":"5","outlined":"","label":"comments"},model:{value:(_vm.selectedKpi.selfComment),callback:function ($$v) {_vm.$set(_vm.selectedKpi, "selfComment", $$v)},expression:"selectedKpi.selfComment"}}),(!_vm.selectedKpi.selfAttachment)?_c(VFileInput,{ref:"selfAttachment",attrs:{"disabled":!_vm.selfEdit,"label":"Attachment","outlined":"","show-size":"","dense":"","prepend-icon":"","append-outer-icon":_vm.selectedKpi.selfAttachment ? 'mdi-download' : ''},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var text = ref.text;
return [_c(VChip,{attrs:{"small":"","label":"","color":"secondary lighten-2"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,false,2645698780),model:{value:(_vm.selfAttachment),callback:function ($$v) {_vm.selfAttachment=$$v},expression:"selfAttachment"}}):_vm._e(),(_vm.selectedKpi.selfAttachment)?_c(VChip,{staticClass:"text-truncate",attrs:{"close":"","label":"","block":"","color":"secondary lighten-2"},on:{"click:close":_vm.removeBase64,"click":function($event){return _vm.downloadLink(_vm.selectedKpi.selfAttachment)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Attachment ")],1):_vm._e()],1),_c(VCardActions,[_c(VSpacer),(_vm.selfEdit)?_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.saveEmployeeKpi}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Save ")],1):_vm._e()],1)],1)],1),(_vm.$vuetify.breakpoint.smAndDown)?_c(VCol,{attrs:{"cols":"12"}},[_c(VDivider)],1):_c(VDivider,{attrs:{"vertical":""}}),(_vm.$vuetify.breakpoint.mdAndUp || _vm.reviewerEdit)?_c(VCol,{directives:[{name:"show",rawName:"v-show",value:(!_vm.selfEdit),expression:"!selfEdit"}],attrs:{"cols":"12","md":"6"}},[_c(VForm,{ref:"supervisorForm",model:{value:(_vm.validity),callback:function ($$v) {_vm.validity=$$v},expression:"validity"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"overline text-caption"},[_vm._v("Supervisor Review")])],1),_c(VCardText,[(_vm.selectedKpi.ratingMethod === 'Rating Scale')?_c(VSelect,{attrs:{"rules":_vm.rules.required,"disabled":!_vm.reviewerEdit,"dense":"","outlined":"","items":_vm.ratingScale,"item-value":"code","item-text":function (item) { return _vm.selectedKpi.ratingMethod === 'Rating Scale'
                    ? ((item.code) + " - " + (item.description))
                    : item.percent; },"label":"Rating"},model:{value:(_vm.selectedKpi.reviewerRatingScore),callback:function ($$v) {_vm.$set(_vm.selectedKpi, "reviewerRatingScore", $$v)},expression:"selectedKpi.reviewerRatingScore"}}):_c(VTextField,{attrs:{"disabled":!_vm.reviewerEdit,"dense":"","outlined":"","type":"number","label":"Score"},model:{value:(_vm.selectedKpi.reviewerRatingScore),callback:function ($$v) {_vm.$set(_vm.selectedKpi, "reviewerRatingScore", $$v)},expression:"selectedKpi.reviewerRatingScore"}}),_c(VTextarea,{attrs:{"rules":_vm.rules.required,"disabled":!_vm.reviewerEdit,"dense":"","rows":"5","outlined":"","label":"comments"},model:{value:(_vm.selectedKpi.reviewerComment),callback:function ($$v) {_vm.$set(_vm.selectedKpi, "reviewerComment", $$v)},expression:"selectedKpi.reviewerComment"}}),(!_vm.selectedKpi.reviewerAttachment)?_c(VFileInput,{ref:"reviewerAttachment",attrs:{"label":"Attachment","outlined":"","dense":"","show-size":"","disabled":!_vm.reviewerEdit,"append-icon":"mdi-paperclip","prepend-icon":"","append-outer-icon":_vm.selectedKpi.reviewerAttachment ? 'mdi-download' : ''},on:{"click:append-outer":function($event){return _vm.downloadLink(_vm.selectedKpi.reviewerAttachment)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var text = ref.text;
return [_c(VChip,{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,false,590729088),model:{value:(_vm.reviewerAttachment),callback:function ($$v) {_vm.reviewerAttachment=$$v},expression:"reviewerAttachment"}}):_vm._e(),(_vm.selectedKpi.reviewerAttachment)?_c(VChip,{attrs:{"close":"","label":"","block":"","color":"secondary lighten-2"},on:{"click:close":_vm.removeBase64,"click":function($event){return _vm.downloadLink(_vm.selectedKpi.reviewerAttachment)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Attachment ")],1):_vm._e()],1),_c(VCardActions,[_c(VSpacer),(_vm.reviewerEdit)?_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.saveSupervisorKpi}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Save ")],1):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1):_c(VCard,{staticClass:"d-flex flex-row justify-center align-center",attrs:{"height":"100%","elevation":"0"}},[_c('span',{staticClass:"text-h5 font-weight-light"},[_vm._v("No KPI Selected")])])}
var staticRenderFns = []

export { render, staticRenderFns }