<template>
  <v-container>
    <v-dialog
      persistent
      overlay-opacity="0.5"
      v-model="approvalModal.open"
      width="30%"
    >
      <v-card class="mx-auto" tile>
        <v-btn
          icon
          :style="{
            float: 'right',
            color: '#000',
            marginTop: '1%',
          }"
          style="position: absolute; right: 1em"
          @click="approvalModal = false"
        >
          <v-icon size="30">mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          {{
            approvalModal.status === "rejectImprest"
              ? "Reject"
              : approvalModal.status === "approve"
              ? "Approve"
              : "Return"
          }}
          <!-- appcode -->
        </v-card-title>
        <v-card-text>
          <v-form ref="approveForm" v-model="isValid">
            <div v-if="approvalModal.status === 'approve'">
              Confirm Approval
            </div>
            <div v-else>
              <v-textarea
                placeholder="Leave your comments here..."
                :cols="5"
                :rows="5"
                focus="false"
                v-model="rejectReason"
                clearable
                class="mt-1 mx-4 leaveInputs"
                filled
                required
                :rules="[rules.common.required]"
              >
              </v-textarea>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-btn
                color="red"
                block
                text
                class="btnActions mx-3"
                style="float: right"
                @click="approvalModal = false"
              >
                Cancel
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <div v-if="approvalModal.status === 'rejectImprest'">
                <v-btn
                  color="red"
                  block
                  class="btnActions"
                  style="color: #fff"
                  :disabled="!isValid"
                  @click.prevent="approve('reject')"
                >
                  Reject
                  <v-icon class="mx-3">thumb_down_alt</v-icon>
                </v-btn>
              </div>
              <div v-else-if="approvalModal.status === 'approve'">
                <v-btn
                  color="success"
                  block
                  class="btnActions"
                  style="color: #fff"
                  @click.prevent="approve('approve')"
                >
                  Approve
                  <v-icon class="mx-3">thumb_up_alt</v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  color="primary"
                  block
                  class="btnActions"
                  style="color: #fff"
                  @click="approve('return')"
                  :disabled="!isValid"
                >
                  Return
                  <v-icon class="mx-3">mdi-undo-variant</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Approve",
  components: {},
  data: () => ({
    isValid: true,
    rejectReason: "",
    rules: {
      common: {
        required: (v) => !!v || "Required.",
      },
    },
  }),
  created() {},
  computed: {
    approvalModal: {
      get() {
        return this.$store.state.imprest.approvalModal;
      },
      set(value) {
        this.$store.commit("imprest/SET_APPROVAL_MODAL", {
          imprest_code: "",
          open: value,
          status: false,
          approvalEntryNo: "",
        });
      },
    },
  },
  methods: {
    ...mapActions({
      approve: function (dispatch, flag) {
        if (!this.isValid) {
          this.$refs.approveForm.validate();
        } else {
          if (flag === "approve") {
            const data = {
              docNo: this.approvalModal.imprest_code,
            };
            dispatch("imprest/approveImprest", data);
          } else if (flag === "reject") {
            const data = {
              approvalEntryNo: this.approvalModal.approvalEntryNo,
              comment: this.rejectReason,
            };
            console.log(data);
            dispatch("imprest/RejectImprest", data);
          }
          this.rejectReason = "";
        }
      },
    }),
  },
};
</script>
