<template>
  <v-toolbar flat dense>
    <v-spacer />
    <v-btn
      color="cyan darken-2"
      v-if="formData.status === 'Open' || !$route.query.edit"
      class="pettycash__buttons mx-2 white--text"
      elevation="0"
      @click="sendForApproval(!$route.query.edit ? 'post' : 'patch')"
    >
      <span class="text-white">Send For Approval</span>
    </v-btn>
    <v-btn
      v-if="formData.status === 'Pending Approval'"
      @click="cancelApprovalRequest"
      class="pettycash__buttons mx-2"
      elevation="0"
      color="orange darken-1"
    >
      <span class="text-white">Cancel Approval Request</span>
    </v-btn>
    <v-btn
      v-if="formData.status === 'Open'"
      class="pettycash__buttons mr-2"
      color="error"
      elevation="0"
      @click="deletePettyCash"
    >
      <v-icon small class="mx-2">delete</v-icon>
      <span>Delete</span>
    </v-btn>
    <v-btn
      v-if="displayPettyCashActions"
      @click="pettyCashForm"
      class="pettycash__buttons"
      elevation="0"
      color="primary"
    >
      <v-icon small class="mx-2"> mdi-content-save </v-icon>
      <span>
        {{ $route.query.edit ? `Save Changes` : saveButtonCaption }}
      </span>
    </v-btn>
  </v-toolbar>
</template>
<script>
export default {
  name: "PettycashCardActions",
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
    displayPettyCashActions: {
      type: Boolean,
      default: true,
    },
    saveButtonCaption: {
      type: String,
      default: "Save",
    },
    imprestActionsPosition: {
      type: String,
      default: "top",
    },
  },
  methods: {
    pettyCashForm() {
      this.$emit("pettyCashForm");
    },
    sendForApproval(method) {
      this.$emit("sendForApproval", method);
    },
    cancelApprovalRequest() {
      this.$emit("cancelApprovalRequest");
    },
    deletePettyCash() {
      this.$emit("deletePettyCash");
    },
  },
};
</script>
