import inventoryLinks from "./inventoryLinks";
import inventoryRoutes from "./inventoryRoutes";
import inventoryStore from "./inventoryStore";

export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoute(...inventoryRoutes);
    }

    if (options.store) {
      options.store.registerModule("Inventory", inventoryStore);
      options.store.commit("Dashboard/ADD_MENU_LINK", inventoryLinks);
    }
  },
};
