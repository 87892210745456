<template>
  <v-text-field
    dense
    outlined
    placeholder="Search"
    hide-details
    style="max-width: 300px"
    @input="searchList"
    append-icon="search"
  />
</template>
<script>
export default {
  name: "MilestoneSearch",
  data: function () {
    return {
      //
    };
  },
  methods: {
    searchList(value) {
      this.$store.dispatch("Projects/searchList", value);
    },
  },
};
</script>
