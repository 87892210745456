import call from "@kinetics254/cassandra-base/service/http";
import AppraisalConstants from "./AppraisalConstants";

export default {
  namespaced: true,
  state: {
    templates: [],
    periods: [],
    reviews: [],
    supervisorReviews: [], // peer
    ratingScale: [],
    kpi: [],
    review: false,
    isReviewer: false,
    reviewObj: [],
    appraisalReport: "",
    supervisorReport: "",
    secondSupervisorReviews: [],
    currentReview: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
    SET_TEMPLATES: (state, payload) => {
      state.templates = payload;
    },

    SET_PERIODS: (state, payload) => {
      state.periods = payload;
    },

    SET_REVIEWS: (state, payload) => {
      state.reviews = payload;
    },

    SET_KPIs: (state, payload) => {
      state.kpi = payload;
    },

    SET_REVIEW: (state, payload) => {
      state.review = payload;
    },

    SET_RATING_SCALE: (state, payload) => {
      state.ratingScale = payload;
    },

    SET_IS_REVIEWER: (state, payload) => {
      state.isReviewer = payload;
    },

    SET_REVIEW_OBJ: (state, payload) => {
      state.reviewObj = payload;
    },
  },
  getters: {
    appraisalGetter: (state) => (value) => state[value],
    templates: (state) => state.templates,
    periods: (state) => state.periods,
    ratingScale: (state) => state.ratingScale,
    reviews: (state) => state.reviews,
    review: (state) => state.review,
    kpi: (state) => state.kpi,
    isReviewer: (state) => state.isReviewer,
    reviewObj: (state) => state.reviewObj,
  },
  actions: {
    getTemplates: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AppraisalConstants.templates)
        .then((res) => {
          commit("SET_TEMPLATES", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getPeriods: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AppraisalConstants.periods)
        .then((res) => {
          commit("SET_PERIODS", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getReviews: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AppraisalConstants.reviews)
        .then((res) => {
          commit("SET_REVIEWS", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          console.log(error);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    // get all lists
    getPeerReviews: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AppraisalConstants.supervisorReviews)
        .then((res) => {
          commit("MUTATE", { state: "supervisorReviews", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getSecondPeerReviews: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AppraisalConstants.secondSupervisorReviews)
        .then((res) => {
          commit("MUTATE", {
            state: "secondSupervisorReviews",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /* get one record(current review) **/
    getByDocumentNo: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AppraisalConstants.reviewDocument(data))
        .then((res) => {
          commit("MUTATE", { data: res.data.data[0], state: "currentReview" });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getReviewees: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AppraisalConstants.reviewees)
        .then((res) => {
          commit("SET_REVIEWS", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    submitAppraisal: ({ commit, dispatch }, data) => {
      call("post", AppraisalConstants.submitAppraisal, data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Appraisal submitted");
          dispatch("getReviews");
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    reviewAppraisal: ({ commit, dispatch }, data) => {
      call("post", AppraisalConstants.reviewAppraisal, data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Review submitted");
          dispatch("getPeerReviews");
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getRatingScale: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AppraisalConstants.ratingScale)
        .then((res) => {
          commit("SET_RATING_SCALE", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    updateKpi: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", AppraisalConstants.kpi, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "KPI rated");
          dispatch("getByDocumentNo", res.data.data.shift().reviewID);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    updateSupervisorKpi: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", AppraisalConstants.kpi, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "KPI rated");
          dispatch("getByDocumentNo", res.data.data.shift().reviewID);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getKpi: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AppraisalConstants.kpi)
        .then((res) => {
          commit("SET_KPIs", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getReviewObj: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", AppraisalConstants.reviewObj)
        .then((res) => {
          commit("SET_REVIEW_OBJ", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getAppraisalReport: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", AppraisalConstants.appraisalReport, payload)
        .then((res) => {
          commit("MUTATE", {
            state: "appraisalReport",
            data: process.env.VUE_APP_FILE_API + res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getSupervisorReport: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", AppraisalConstants.supervisorReport, payload)
        .then((res) => {
          commit("MUTATE", {
            state: "supervisorReport",
            data: process.env.VUE_APP_FILE_API + res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    addEmployeeRemarks: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", AppraisalConstants.reviews, payload)
        .then((res) => {
          dispatch("submitAppraisal", {
            reviewNo: res.data.data[0].code,
            reviewerID: res.data.data[0].reviewerID,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    addSupervisorRemarks: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", AppraisalConstants.reviews, payload)
        .then((res) => {
          dispatch("reviewAppraisal", {
            reviewNo: res.data.data[0].code,
            employeeNo: res.data.data[0].employeeNo,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    acceptReview: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", AppraisalConstants.acceptReview, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "Review accepted");
          Event.$emit("goToAppraisal");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    rejectReview: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", AppraisalConstants.rejectReview, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "Review rejected");
          Event.$emit("goToAppraisal");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    submitComment({ commit, dispatch }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", AppraisalConstants.REVIEWER_COMMENT, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          Event.$emit("closeDialog");
          dispatch("getByDocumentNo", payload.reviewDocNo);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
