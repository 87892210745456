<template>
  <!-- dialog to edit kpis -->
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title class="">
        {{ editing ? "Edit" : "Add" }} Appraisal KPI
        <v-spacer></v-spacer>
        <v-btn fab outlined color="red" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="entity">
        <v-form @submit.prevent="submitForm">
          <v-row dense justify="space-between" class="mt-2">
            <!-- <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                disabled
                v-model="editedData.objectiveCode"
                label="Objective Code"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                disabled
                v-model="editedData.kpiCode"
                label="Code"
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="12">
              <v-text-field
                outlined
                dense
                v-model="editedData.kpiDescription"
                label="Description"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <v-select
                outlined
                dense
                v-model="editedData.ratingMethod"
                :items="[editedData.ratingMethod]"
                label="Rating Method"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                outlined
                dense
                v-model="editedData.targetCategory"
                :items="[editedData.targetCategory]"
                label="Target Category"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-checkbox
                dense
                disabled
                v-model="editedData.global"
                label="Global"
              ></v-checkbox>
            </v-col>-->
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                v-model="editedData.weight"
                label="Weight"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                v-model="editedData.target"
                label="Target Value"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn rounded type="submit" color="primary">Save</v-btn>
        </v-form>
      </v-card-text>
      <v-card-text v-else> Error while Loading the Form </v-card-text>
    </v-card>
  </v-dialog>
  <!-- end -->
</template>
<script>
export default {
  name: "EditTemplateDialog",
  props: {
    editTemplateDialog: Boolean,
    editing: Boolean,
    entity: { type: Object, default: () => ({}) },
  },
  data: function () {
    return {
      editedData: { ...this.entity },
    };
  },
  mounted() {
    this.editedData = { ...this.entity };
  },
  computed: {
    dialog: {
      get() {
        return this.editTemplateDialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
  },
  methods: {
    submitForm() {
      this.editedData.weight = parseInt(this.editedData.weight, 10);
      this.editedData.target = parseInt(this.editedData.target, 10);

      //emit fn save
      this.$emit(this.editing ? "edit-item" : "add-item", this.editedData);
    },
  },
  watch: {
    entity: {
      handler(newValue) {
        this.editedData = { ...newValue };
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
