import PettyCash from "./views/PettyCash";
import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";
import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
import PettyCashList from "./components/PettyCashList";
import PettyCashForm from "./components/PettyCashForm";
import PettycashDocument from "./components/PettycashDocument";

export default [
  {
    path: "/pettycash",
    component: AppDashboard,
    children: [
      {
        path: "",
        components: { view: PettyCash },
        children: [
          {
            path: "/",
            redirect: "/pettycash/list",
          },
          {
            path: "list",
            name: "PettyCashList",
            component: PettyCashList,
            meta: { middleware: [Auth] },
          },
          {
            path: "form",
            name: "PettyCashForm",
            component: PettyCashForm,
            meta: { middleware: [Auth] },
          },
          {
            path: "pettycash-document/:docNo",
            name: "PettycashDocument",
            component: PettycashDocument,
            meta: { middleware: [Auth] },
          },
        ],
      },
    ],
  },
];
