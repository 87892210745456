import call from "@kinetics254/cassandra-base/service/http";
import projectConstants from "./projectConstants";
export default {
  state: {
    activitys: [],
    activity: {},
  },
  actions: {
    getActivity({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", projectConstants.showActivity(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "activity", data: res.data.data.shift() });
        })
        .catch((err) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    saveActivity({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", projectConstants.activity, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
          Event.$emit("gotoMilestone");
        })
        .catch((err) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    updateActivity({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", projectConstants.updateActivity, payload)
        .then(() => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Activity updated successfully");
          Event.$emit("gotoMilestone");
        })
        .catch((err) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    deleteActivity({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", projectConstants.deleteActivity, payload)
        .then(() => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Activity deleted successfully");
          Event.$emit("back");
        })
        .catch((err) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
  },
};
