// import discLinks from "./discLinks";
import discRoutes from "./discRoutes";
import discStore from "./discStore";

export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoutes(discRoutes);
    }

    if (options.store) {
      options.store.registerModule("Disc", discStore);
      // options.store.commit("Dashboard/ADD_LINK", discLinks);
    }
  },
};
