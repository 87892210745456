import call from "@kinetics254/cassandra-base/service/http";
import approvalConstants from "./approvalConstants";

export default {
  namespaced: true,
  state: {
    approvalEntries: [],
    approvalEntry: {},
    statement: {},
    nestedAapprovalEntry: {},
    documentAttachments: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    approvalGetters: (state) => (val) => state[val],
  },
  actions: {
    getApprovalEntries: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", approvalConstants.approvalEntries(payload))
        .then((response) => {
          commit("MUTATE", {
            state: "approvalEntries",
            data: response.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          console.log(error);
        });
    },
    getApprovalEntry: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", approvalConstants.approvalEntries(payload))
        .then((response) => {
          commit("MUTATE", {
            state: "approvalEntry",
            data: response.data.data.shift(),
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          console.log(error);
        });
    },
    approve: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", approvalConstants.approve, payload)
        .then((response) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("approvalentries");
          Event.$emit("ApiSuccess", "Document approved successfully");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    reject: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", approvalConstants.reject, payload)
        .then((response) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("approvalentries");
          Event.$emit("ApiSuccess", "Document rejected successfully");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("error", error.response.data.message);
        });
    },
    delegate: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", approvalConstants.delegate, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("approvalentries");
          Event.$emit("ApiSuccess", "Document delegated successfully");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getStatement: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", approvalConstants.getStatement(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "statement", data: res.data.data[0] });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getNestedApprovalEntries: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", approvalConstants.nestedApprovalEntries(payload))
        .then((response) => {
          commit("MUTATE", {
            state: "nestedAapprovalEntry",
            data: response.data.data.shift(),
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          console.log(error);
        });
    },
    getDocumentAttachments: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", approvalConstants.getDocumentAttachments(payload))
        .then((response) => {
          commit("MUTATE", {
            state: "documentAttachments",
            data: response.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          console.log(error);
        });
    },
  },
};
