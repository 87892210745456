import call from "@kinetics254/cassandra-base/service/http";
import constants from "./ProfileConstants";

export default {
  namespaced: true,
  state: {
    employee: [],
    isDataLoaded: false,
  },
  mutations: {
    SET_EMPLOYEE: (state, payload) => {
      state.employee = payload;
    },
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    employee: (state) => state.employee,
    isDataLoaded: (state) => state.isDataLoaded,
  },
  actions: {
    getEmployee: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.leaveEmployee(data)).then((res) => {
        commit("loader/SET_LOADING", false, { root: true });
        commit("SET_EMPLOYEE", res.data.data);
        commit("MUTATE", { state: "isDataLoaded", data: true });
      });
    },
  },
};
