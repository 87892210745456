import LeaveLinks from "./modules/leave/LeaveLinks";
import handoverLinks from "./modules/handover/handoverLinks";
import allowanceLinks from "./modules/allowance/allowanceLinks";

const links = [...LeaveLinks, ...handoverLinks, ...allowanceLinks];

export default {
  order: 1,
  name: "Leave Management",
  icon: "date_range",
  links,
  hasSideLinks: true,
  enabled: true,
};
