import store from "../../../store";

class Document {
  loading = false;
  constructor(docNo, provider, entity) {
    this.docNo = docNo;
    this.provider = provider;
    this.entity = entity;

    // CALL THE REOPEN DOCUMENT FOR NOW
    this.reopen();
  }
  reopen() {
    this.loading = true;
    store.dispatch("Models/reopenRejectedDocument", {
      docNo: this.docNo,
    });
  }
}

export default Document;