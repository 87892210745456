export default {
  order: 5,
  name: "Requisition",
  icon: "receipt_long",
  to: "/requisition/ReqDashboard",
  enabled: true,
  subs: [
    {
      name: "User Requisition",
      icon: "recent_actors",
      to: "/requisition/list/VXNlciBSZXF1aXNpdGlvbg==",
      enabled: true,
    },
    {
      name: "Purchase Requisition",
      icon: "payments",
      to: "/requisition/list/UHVyY2hhc2UgUmVxdWlzaXRpb24=",
      enabled: true,
    },
    {
      name: "Store Requisition",
      icon: "archive",
      to: "/requisition/list/U3RvcmUgUmVxdWlzaXRpb24=",
      enabled: true,
    },
  ],
};
