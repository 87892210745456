<template>
  <v-container>
    <v-card class="mx-auto approver--card" flat v-if="!isEmpty()">
      <v-card-title>
        <v-btn text color="secondary" class="mx-2" @click="$router.back()">
          <v-icon class="mx-1">arrow_back</v-icon>
          back
        </v-btn>
        Approval Entry {{ entry.entryNo }}
        <v-spacer />
        <v-btn class="blue white--text mx-2" @click="delegate">
          <v-icon class="mx-2">mdi-account-arrow-right-outline</v-icon>
          delegate
        </v-btn>
        <v-btn class="green white--text mx-2" @click="approve">
          <v-icon class="mx-2">thumb_up</v-icon>
          Approve
        </v-btn>
        <v-btn class="red white--text" @click="rejectDialog = true">
          <v-icon class="mx-2">thumb_down</v-icon>
          Reject
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-toolbar
          color="primary"
          class="white--text text-capitalize text-body-1"
          dense
        >
          Approval Document - {{ entry.documentNo }}
          <v-spacer />
          <v-btn
            color="secondary"
            class="mx-2 text-capitalize"
            @click="downloadLink(getDocument)"
          >
            Download
            <v-icon class="mx-1">mdi-download</v-icon>
          </v-btn>
        </v-toolbar>
        <pdf
          v-for="i in getPages(getDocument)"
          :key="i"
          :src="getDocument"
          :page="i"
        />
      </v-card-text>
      <ApprovalDocuments :documents="documents" />
      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card class="mx-auto" width="500">
          <v-card-text>
            <div class="text-body-1">
              Sorry, you cannot approve this document. Please contact the
              sender/admin for more information.
            </div>
          </v-card-text>
          <v-card-actions>
            <div>Redirecting in {{ countDown }} seconds...</div>
            <v-spacer />
            <v-btn
              color="primary"
              small
              class="text-body-1 text-capitalize"
              link
              to="/"
            >
              <v-icon class="mx-2">dashboard</v-icon>
              Go to Dashboard
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- reject reason-->
      <v-dialog v-model="rejectDialog" persistent max-width="500">
        <v-card class="mx-auto" width="500">
          <v-card-title>
            <div class="text-body-1">Add reject reason</div>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="rejectReason"
              label="Reject Reason"
              outlined
              rows="3"
              maxlength="250"
              dense
              clearable
              hint="250 characters max"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              class="text-capitalize"
              small
              @click="rejectDialog = false"
            >
              <v-icon>mdi-close</v-icon>
              close
            </v-btn>
            <v-spacer />
            <v-btn class="red white--text" @click="reject">
              <v-icon class="mx-2">thumb_down</v-icon>
              Reject
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-card v-if="isEmpty()">
      <v-skeleton-loader
        v-bind="attrs"
        type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
      ></v-skeleton-loader>
    </v-card>
  </v-container>
</template>
<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import { helper } from "@kinetics254/cassandra-base/utilities";
import pdf from "vue-pdf";
import ApprovalDocuments from "./ApprovalDocuments.vue";
import FileMixin from "@/packages/cassandra-base/mixins/FileMixin";
export default {
  name: "ApprovalCard",
  mixins: [FileMixin],
  components: {
    pdf,
    ApprovalDocuments,
  },
  data() {
    return {
      dialog: false,
      countDown: 10,
      numPages: undefined,
      rejectDialog: false,
      rejectReason: "",
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
    };
  },
  mounted() {
    if (this.userData.employee !== atob(this.$route.params.approverID)) {
      this.dialog = true;
      this.calcCountDown();
    }
    Event.$on("approvalentries", () => {
      this.$router.push({ name: "ApprovalList" });
    });
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Approvals/getNestedApprovalEntries", {
        documentNo: atob(v.route),
        status: "Open",
      });
      v.$store.dispatch("Approvals/getDocumentAttachments", {
        no: atob(v.route),
      });
    });
  },
  computed: {
    route() {
      return this.$route.params.documentNo;
    },
    entry() {
      return this.$store.getters["Approvals/approvalGetters"](
        "nestedAapprovalEntry"
      );
    },
    getDocument() {
      return this.entry
        ? process.env.VUE_APP_FILE_API + this.entry.pdfAttachment
        : "";
    },
    documents() {
      return this.$store.getters["Approvals/approvalGetters"](
        "documentAttachments"
      );
    },
    userData() {
      return AuthService.user;
    },
    employees() {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
  },
  methods: {
    approve() {
      this.$confirm.show({
        title: "Approve",
        text: "Are you sure you want to approve this document?",
        onConfirm: () => {
          this.$store.dispatch("Approvals/approve", {
            ...this.entry,
          });
        },
      });
    },
    delegate() {
      this.$confirm.show({
        title: "Delegate",
        text: "Are you sure you want to delegate this document?",
        onConfirm: () => {
          this.$store.dispatch("Approvals/delegate", {
            ...this.entry,
          });
        },
      });
    },
    reject() {
      this.$confirm.show({
        title: "Reject",
        text: "Are you sure you want to reject this document?",
        onConfirm: () => {
          this.$store.dispatch("Approvals/reject", {
            ...this.entry,
            reason: this.rejectReason,
          });
        },
      });
    },
    calcCountDown() {
      const interval = setInterval(() => {
        this.countDown--;
        if (this.countDown === 0) {
          clearInterval(interval);
          this.$router.push("/");
        }
      }, 1000);
    },
    getPages: function (document) {
      if (document.length > 0) {
        document = pdf.createLoadingTask(document);
        document.promise.then((pdf) => {
          this.numPages = pdf._pdfInfo.numPages;
        });
        return this.numPages;
      }
    },
    isEmpty() {
      return this.entry === undefined || Object.keys(this.entry).length === 0;
    },
  },
  watch: {
    route() {
      this.$store.dispatch("Approvals/getNestedApprovalEntries", {
        documentNo: atob(this.route),
        status: "Open",
      });
      this.$store.dispatch("Approvals/getDocumentAttachments", {
        no: atob(this.route),
      });
    },
  },
};
</script>
<style>
.approver--card > .v-card__title {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.approver--card > .v-divider {
  position: sticky;
  top: 70px;
  z-index: 1;
}
</style>
