import { Auth } from "@kinetics254/cassandra-base/modules/auth";
import AllowanceList from "./components/AllowanceList";
import allowanceForm from "./components/AllowanceForm";
import allowanceApprovalList from "./components/AllowanceApprovalList";
import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";
import Allowance from "./views/Allowance";

export default [
  {
    path: "/leaveallowance",
    component: AppDashboard,
    children: [
      {
        path: "",
        components: { view: Allowance },
        children: [
          {
            path: "",
            redirect: "/leaveallowance/list",
            meta: {
              middleware: Auth,
            },
          },
          {
            path: "list",
            name: "Allowance List",
            component: AllowanceList,
            meta: {
              middleware: Auth,
              breadcrumb: [
                { text: "leave", disabled: true, href: "/" },
                {
                  text: "allowance",
                  disabled: false,
                  href: "/leaveallowance/list",
                },
              ],
            },
          },
          {
            path: "allowanceForm/:code?",
            component: allowanceForm,
            name: "Allowance Form",
            meta: {
              middleware: Auth,
              breadcrumb: [
                { text: "leave", disabled: true, href: "/" },
                {
                  text: "allowance Form",
                  disabled: false,
                  href: "/leaveallowance/allowanceForm",
                },
              ],
            },
          },
          {
            path: "allowanceApprovalList",
            component: allowanceApprovalList,
            name: "Allowance Approval List",
            meta: {
              middleware: Auth,
              breadcrumb: [
                { text: "leave", disabled: true, href: "/" },
                {
                  text: "Allowance Approval List",
                  disabled: false,
                  href: "/leaveallowance/allowanceApprovalList",
                },
              ],
            },
          },
        ],
      },
    ],
  },
];
