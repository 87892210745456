<template>
  <div>
    <v-app-bar class="mx-auto mt-5" dense elevation="0" color="transparent">
      <div class="headline text-lg-h6 header-title">
        Leave Allowance Approval Requests
      </div>
    </v-app-bar>
    <v-card elevation="0" class="mx-auto mt-4" flat>
      <v-card-text>
        <v-row>
          <v-col class="ml-md-4" cols="12" md="4" sm="12">
            <v-menu
              v-model="filterDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  color="primary"
                  dense
                  label="Filter by Posting Date"
                  prepend-inner-icon="filter_alt"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterDate"
                range
                @input="
                  (filterDatePicker = false),
                    (startDate = filterDate[0]),
                    (endDate = filterDate[1])
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer />
          <v-col class="mr-md-4" cols="12" md="4" sm="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="mt-n3"
              hide-details
              label="Search"
              single-line
            />
          </v-col>
        </v-row>
        <v-row class="no-gutters mt-3">
          <v-col cols="12" md="12">
            <v-sheet class="">
              <div class="px-3 py-3">
                <v-data-table
                  :headers="headers"
                  :items="allowances"
                  :sort-desc.sync="sortDesc"
                  ref="allowancetable"
                  multi-sort
                  class="elevation-0"
                  :items-per-page="8"
                  id="scollStyle"
                  :search="search"
                  :sort-by.sync="sortBy"
                >
                  <template v-slot:item.status="{ item }">
                    <v-chip small label :color="getColor(item.status).color">
                      <div style="color: #fff">{{ item.status }}</div>
                    </v-chip>
                  </template>
                  <template v-slot:item.employeeName="{ item }">
                    <v-chip small label class="black--text">
                      {{ item.employeeName }}
                    </v-chip>
                  </template>
                  <template v-slot:item.applicationDate="{ item }">
                    {{ getDateFormat(item.applicationDate) }}
                  </template>
                  <template v-slot:item.postingDate="{ item }">
                    {{ getDateFormat(item.postingDate) }}
                  </template>
                  <template v-slot:item.amount="{ item }">
                    {{ item.amount | currencyFormat }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      dense
                      small
                      class="mx-1"
                      color="primary"
                      :to="`allowanceForm?approval=${item.applicationCode}`"
                    >
                      <div class="px-2">View Allowance</div>
                      <v-icon small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import allowanceMixins from "../allowanceMixins";

export default {
  name: "AllowanceApprovalList",
  mixins: [RouterMixin, allowanceMixins],
  data: function () {
    return {
      status: "All Requests",
      details: {},
      filterDate: [],
      filterDatePicker: "",
      sortBy: "no",
      sortDesc: true,
      search: "",
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("allowance/pendingApprovals");
    });
  },
  computed: {
    headers: function () {
      return [
        {
          text: "#No.",
          align: "start",
          sortable: true,
          value: "applicationCode",
        },
        { text: "Status", sortable: true, value: "status" },
        { text: "Employee", sortable: true, value: "employeeName" },
        { text: "Application Date", sortable: true, value: "applicationDate" },
        { text: "Posting Date", sortable: true, value: "postingDate" },
        { text: "Amount (KES)", sortable: true, value: "amount" },
        { text: "Actions", sortable: false, value: "actions" },
      ];
    },
    dateRangeText() {
      return this.filterDate.join(" To ");
    },
    allowances: function () {
      const allowances =
        this.$store.getters["allowance/allowanceGetters"]("pendingApprovals");
      if (this.filterDate.length !== 0) {
        return this.status === "All Requests"
          ? allowances.filter(
              (a) =>
                new Date(a.applicationDate) >= this.startDate &&
                new Date(a.applicationDate) <= this.endDate
            )
          : allowances.filter(
              (a) =>
                new Date(a.applicationDate) >= this.startDate &&
                new Date(a.applicationDate) <= this.endDate &&
                a.status === this.status
            );
      }
      return this.status === "All Requests"
        ? allowances
        : allowances.filter((v) => v.status === this.status);
    },
  },
  methods: {
    filterlist(statusfilter) {
      this.status = statusfilter;
    },
  },
  watch: {
    filterDate() {
      if (this.filterDate.length === 1) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[0]);
      }
      if (this.filterDate.length === 2) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[1]);
      }
    },
  },
};
</script>

<style scoped>
@import url("../styles.css");
</style>
