<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="speedKey"
                :items="speedKeys"
                :item-text="(item) => item.speedkeyCode"
                :item-value="(item) => item.speedkeyCode"
                v-if="speedKeys.length > 0"
                label="Select Speed Key"
                :disabled="readOnlyCheck"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.fundNo"
                :items="funds"
                :item-text="(item) => item.no + ' - ' + item.name"
                :item-value="(item) => item.no"
                v-if="funds.length > 0"
                label="Select Fund"
                :disabled="readOnlyCheck"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="dimension1"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                :value="
                  formData.globalDimension1Code
                    ? formData.globalDimension1Code
                    : userData.profile.dimension1
                "
                @input="input($event, 'dimension1')"
                v-if="dimension1.length > 0"
                :label="`Select ${dimension1[0]['dimensionCode']}`"
                :disabled="readOnlyCheck"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="dimension2"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                :value="
                  formData.globalDimension2Code
                    ? formData.globalDimension2Code
                    : userData.profile.dimension2
                "
                @input="input($event, 'dimension2')"
                v-if="dimension2.length > 0"
                :label="`Select ${dimension2[0]['dimensionCode']}`"
                :disabled="readOnlyCheck"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.globalDimension3Code"
                :items="dimension3"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                v-if="dimension3.length > 0"
                :label="`Select ${dimension3[0]['dimensionCode']}`"
                :disabled="readOnlyCheck"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.globalDimension4Code"
                :items="dimension4"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                v-if="dimension4.length > 0"
                :label="`Select ${dimension4[0]['dimensionCode']}`"
                :disabled="readOnlyCheck"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.globalDimension5Code"
                :items="dimension5"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                v-if="dimension5.length > 0"
                :label="`Select ${dimension5[0]['dimensionCode']}`"
                :disabled="readOnlyCheck"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.globalDimension6Code"
                :items="dimension6"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                v-if="dimension6.length > 0"
                :label="`Select ${dimension6[0]['dimensionCode']}`"
                :disabled="readOnlyCheck"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.globalDimension7Code"
                :items="dimension7"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                v-if="dimension7.length > 0"
                :label="`Select ${dimension7[0]['dimensionCode']}`"
                :disabled="readOnlyCheck"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="formData.globalDimension8Code"
                :items="dimension8"
                :item-text="(item) => item.code + ' - ' + item.name"
                :item-value="(item) => item.code"
                v-if="dimension8.length > 0"
                :label="`Select ${dimension8[0]['dimensionCode']}`"
                :disabled="readOnlyCheck"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ReqMixin from "../ReqMixin.js";
export default {
  name: "RequisitionDimension",
  mixins: [ReqMixin],
  props: {
    dimensionValues: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
    userData: {
      type: Object,
    },
    readOnlyCheck: Boolean,
  },
  computed: {
    speedKey: {
      get() {
        return this.formData.dimensionSpeedkeyCode;
      },
      set(val) {
        const speedKey = this.speedKeys
          .filter((el) => el.speedkeyCode === val)
          .shift();
        this.formData.dimensionSpeedkeyCode = val;
        this.formData.fundNo = speedKey.fundNo;
        this.formData.globalDimension1Code = speedKey.globalDimension1Code;
        this.formData.globalDimension2Code = speedKey.globalDimension2Code;
        this.formData.globalDimension3Code = speedKey.globalDimension3Code;
        this.formData.globalDimension4Code = speedKey.globalDimension4Code;
        this.formData.globalDimension5Code = speedKey.globalDimension5Code;
        this.formData.globalDimension6Code = speedKey.globalDimension6Code;
        this.formData.globalDimension7Code = speedKey.globalDimension7Code;
        this.formData.globalDimension8Code = speedKey.globalDimension8Code;
      },
    },
  },
  methods: {
    input: function (event, key) {
      console.log(event);
      switch (key) {
        case "dimension1":
          this.formData.globalDimension1Code = event;
          break;

        case "dimension2":
          this.formData.globalDimension2Code = event;
          break;

        default:
          break;
      }
    },
  },
};
</script>
