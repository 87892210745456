import call from "@kinetics254/cassandra-base/service/http";
import constants from "./exitConstants";

export default {
  namespaced: true,
  state: {
    exit: {},
    exits: [],
    employeeItems: [],
    exitCodes: [],
    employeeChecklist: [],
    questionnaire: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    exitGetters: (state) => (val) => state[val],
  },
  actions: {
    getExits: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.exit)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "exits", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getExit: ({ commit }, no) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.showExit(no))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "exit", data: res.data.data[0] });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    sendForApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.sendForApproval, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Exit sent for approval successfully");
          dispatch("getExits");
          dispatch("getExit", data.no);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    cancelApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.cancelApproval, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Exit approval cancelled successfully");
          dispatch("getExits");
          dispatch("getExit", data.no);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    saveAndSendForApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.exit, data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Exit saved successfully");
          Event.$emit("back");
          if (data.saveAndSend) dispatch("sendForApproval", res.data.data[0]);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    updateAndSendForApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.updateExit, data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Exit updated successfully");
          Event.$emit("back");
          if (data.saveAndSend) dispatch("sendForApproval", res.data.data[0]);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteExit: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.deleteExit, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Exit deleted successfully");
          dispatch("getExits");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    employeeItems: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.employeeItems)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "employeeItems", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    exitCodes: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.exitCodes)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "exitCodes", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    employeeChecklist: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.employeeChecklist)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "employeeChecklist", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getHrSetup: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.questionnaire)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "questionnaire",
            data: res.data.data.shift(),
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
