import trainingLinks from "./trainingLinks.js";
import disciplinaryLinks from "../cassandra-disciplinary/discLinks.js";
import exitLinks from "../cassandra-exit/exitLinks.js";
import projectLinks from "../cassandra-projects/projectLinks.js";
import handbookLinks from "../cassandra-handbook/handbookLinks.js";

const links = [
  ...trainingLinks,
  ...disciplinaryLinks,
  ...projectLinks,
  ...handbookLinks,
  ...exitLinks,
];

export default {
  order: 4,
  name: "Personnel Management",
  icon: "people",
  links,
  enabled: true,
  hasSideLinks: true,
};
