import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{attrs:{"color":"primary","sticky":""}},[_c(VToolbarTitle,{staticClass:"justify-center text-h6 white--text"},[_vm._v(" "+_vm._s(_vm.caption)+" ")]),_c(VSpacer)],1),[_c(VCard,{staticClass:"mx-auto"},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary","active-class":"primary--text text--accent-4"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.handbooks),function(item,i){return _c(VListItem,{key:i,staticClass:"my-2",attrs:{"dense":""},on:{"click":function($event){return _vm.openHandbook(item)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold primary--text overline"},[_vm._v(_vm._s(item.code))]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(item.description)+" ")])],1),(_vm.checkIfHandbookIsRead(item.code))?_c(VListItemAction,[_c(VChip,{attrs:{"label":""}},[_c(VIcon,{staticClass:"mx-2",attrs:{"color":"green","dark":"","right":""}},[_vm._v(" mdi-checkbox-marked-circle ")]),_vm._v(" Read ")],1)],1):_vm._e(),_c(VListItemAction,[_c(VBtn,{attrs:{"text":!active},on:{"click":function($event){return _vm.openHandbook(item)}}},[_vm._v(" View "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)],1)]}}],null,true)})}),_c(VDivider)],2)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }