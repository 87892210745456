<template>
  <v-container class="">
    <v-card tile elevation="0">
      <v-card-title>
        <!-- back button -->
        <v-btn text @click="$router.back()">
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>
        <v-spacer />
        <!-- back button end -->
        <h6 class="heading">Expense Code: {{ imprest.expenseCode }}</h6>
        <v-spacer />
        <h6 class="heading">Description: {{ imprest.description }}</h6>
        <v-spacer />
        <v-spacer />
      </v-card-title>
      <v-divider class="mb-md-3" />
      <v-row>
        <v-col md="6.5">
          <v-card flat>
            <!-- data table start -->
            <v-data-table
              :headers="headers"
              :items="ImprestLineDetailed"
              item-key="name"
            >
              <!--eslint-disable-next-line -->
              <template v-slot:item.amount="{ item }">
                <div class="mr-4">
                  {{ item.amount | currencyFormat }}
                </div>
              </template>
              <!--eslint-disable-next-line -->
              <template v-slot:item.action="{ item }">
                <div class="d-flex justify-start">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        color="success"
                        dark
                        dense
                        small
                        label
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1 text--white"
                        @click="Display(item)"
                      >
                        <v-icon small left>mdi-eye</v-icon>
                        Preview
                      </v-chip>
                    </template>
                    <span>Preview Receipt</span>
                  </v-tooltip>

                  <!--BEGIN edit button-->
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        color="primary"
                        label
                        small
                        dense
                        @click="setDialog = { dialog: true, item: item }"
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1 text--white"
                      >
                        <v-icon left small class="white--text"
                          >mdi-pencil</v-icon
                        >
                        Edit
                      </v-chip>
                    </template>
                    <span>Edit entry</span>
                  </v-tooltip>
                  <!--END edit button-->
                  <!--BEGIN delete button-->
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        color="error"
                        label
                        small
                        dense
                        @click="deleteImage(item)"
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1 text--white"
                      >
                        <v-icon small left>mdi-trash-can</v-icon> delete
                      </v-chip>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>

                  <!--END delete button-->
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <!--BEGIN dialog button-->
      <v-dialog v-model="dialog" persistent max-width="400">
        <v-card>
          <v-app-bar flat dark dense color="primary" elevation="0">
            <v-app-bar-title class="text-lg-h6">
              <h6>Edit Receipt No: {{ formData.description }}</h6>
            </v-app-bar-title>
          </v-app-bar>
          <v-card-text>
            <v-form v-model="isValid" ref="Editform">
              <v-col cols="12" md="12">
                <p class="">Accounted amount:</p>
                <v-text-field
                  v-model.number="formData.amount"
                  label=""
                  single-line
                  outlined
                  min="0"
                  step="1"
                  dense
                  class=""
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <p class="mt-n8">Upload Document:</p>
                <v-file-input
                  accept="image/jpeg,image/png,application/pdf"
                  @change="uploadFile"
                  prepend-icon=""
                  append-icon="mdi-camera"
                  dense
                  outlined
                  label="Upload Document"
                />
              </v-col>
            </v-form>
          </v-card-text>
          <v-card-actions class="mt-n10">
            <v-spacer />

            <div class="action-buttons">
              <v-btn color="red darken-1" text @click="dialog = false">
                Cancel
              </v-btn>
              <v-btn
                color="green darken-1"
                class="white--text"
                small
                dense
                @click="PatchImage(), (dialog = false)"
              >
                Save
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--END dialog button-->
      <!--BEGIN dialog button-->
      <AttachmentPreview
        :base64="detail.attachment"
        :dialog="detaildialog"
        v-on:close-dialog="closeDialog"
      />
    </v-card>
  </v-container>
</template>

<script>
import AttachmentPreview from "./ImprestAttachmentPreview.vue";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
import ImprestMixin from "../ImprestMixin.js";
export default {
  name: "ReceiptAccounting",
  mixins: [ImprestMixin],
  components: {
    AttachmentPreview,
  },
  data: function () {
    return {
      attachment: "",
      detail: {},
      dialog: false,
      detaildialog: false,
      isValid: false,
      imageUrl: "",
      formData: {
        attachment: "",
        description: "",
        amount: 0,
        DetailedLineMgtLineNo: null,
        DetailedLineMgtDocNo: "",
        DetailedLineMgtDocType: null,
        entryNo: null,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("imprest/setBreadCrumbs", to);
      if (to.query.document) {
        v.$store.dispatch("imprest/getDetailedImprests", {
          lineNo: to.params.code,
          id: to.query.document,
        });
      }
    });
  },

  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.dialog = val.dialog;
        this.formData = val.item;
      },
    },
    headers() {
      return [
        {
          text: "Document No.",
          align: "",
          sortable: true,
          value: "DetailedLineMgtDocNo",
        },
        { text: "Entry No.", align: "", sortable: true, value: "entryNo" },
        {
          text: "Receipt No.",
          align: "",
          sortable: true,
          value: "description",
        },
        {
          text: `Amount (${this.Currency})`,
          align: "",
          sortable: true,
          value: "amount",
        },
        { text: "Actions", sortable: true, value: "action" },
      ];
    },
    ImprestLineDetailed() {
      const imprestLine =
        this.$store.getters["imprest/imprestsDetails"].imprestLinesAPI;
      return imprestLine
        ? imprestLine
            .filter((el) => el.lineNo === parseInt(this.$route.params.code))
            .shift().detailedImprestLines
        : [];
    },
    imprest() {
      const imprests = this.$store.getters["imprest/imprestsDetails"];
      return imprests.imprestLinesAPI
        ? imprests.imprestLinesAPI
            .filter((i) => i.lineNo === parseInt(this.$route.params.code))
            .shift()
        : [];
    },
    Currency() {
      const data = this.$store.getters["imprest/imprestsDetails"];
      return data ? data.currencyCode : "KES";
    },
  },
  methods: {
    deleteImage: function (data) {
      this.$confirm.show({
        title: `Entry No: ${data.entryNo}`,
        text: "Are you sure you want to delete this?",
        onConfirm: () => {
          this.$store.dispatch("imprest/deleteImage", data);
        },
        OnCancel: (e) => {
          return e;
        },
      });
    },
    getImage(base64) {
      this.attachment = base64;
      return helpers.detectMimeType(base64);
    },
    uploadFile: async function (value) {
      this.formData.attachment = await helpers.toBase64(value);
      this.formData.attachment = this.formData.attachment.split(",")[1];
    },
    PatchImage() {
      if (!this.isValid) {
        this.$refs.Editform.validate();
      } else {
        this.$store.dispatch("imprest/updateAccounting", {
          ...this.formData,
        });
      }
    },
    Display(val) {
      this.detail = val;
      this.detaildialog = true;
    },
    closeDialog: function (val) {
      this.detaildialog = val;
    },
    getFileBlob: async function (url, fileName) {
      return await helpers.getFileBlob(
        process.env.VUE_APP_FILE_API + url,
        fileName
      );
    },
  },
  watch: {
    ImprestLineDetailed: {
      handler(n, o) {
        n.forEach((object) => {
          this.getFileBlob(object.attachment, object.DetailedLineMgtDocNo).then(
            (res) => {
              object.attachment = res;
              helpers.toBase64(object.attachment).then((res) => {
                object.attachment = res.split(";base64,")[1];
              });
            }
          );
        });
        return n;
      },
    },
  },
};
</script>
<style scoped>
* {
  font-family: Poppins, sans-serif !important;
}
.action-buttons {
  width: 50%;
  text-align: right;
}
</style>
