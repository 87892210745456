import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c(VDataTable,{staticClass:"task--list",attrs:{"headers":_vm.headers,"items":_vm.entries,"search":_vm.search,"sort-desc":[false, true],"multi-sort":"","single-expand":true,"item-key":"entryNo","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.complete",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":item.complete ? 'success' : 'grey lighten-3'}},[_vm._v(" "+_vm._s(item.complete ? "Complete" : "Incomplete")+" ")])]}},{key:"item.activityTasks",fn:function(slotData){return [_c(VBadge,{staticClass:"white--text",attrs:{"content":slotData.item.activityTasks.length,"color":"secondary","overlap":""}},[_c(VBtn,{staticClass:"white--text text-capitalize",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewTasks(slotData)}}},[_vm._v(" View Tasks "),_c(VIcon,{staticClass:"mx-2"},[_vm._v("expand_more")])],1)],1),_c(VBtn,{staticClass:"white--text text-capitalize mx-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editActivity(slotData)}}},[_vm._v(" Edit "),_c(VIcon,{attrs:{"right":""}},[_vm._v("edit")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-2",attrs:{"colspan":headers.length}},[_c(VList,{attrs:{"dense":"","flat":"","outlined":""}},[_c(VListItem,{attrs:{"dense":""}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.activity)+" - Tasks ")])],1),_c(VDivider),_c(VDataTable,{staticClass:"task--list",attrs:{"headers":_vm.taskHeaders,"items":item.activityTasks,"search":_vm.search,"sort-desc":[false, true],"multi-sort":""},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.startDate))+" ")]}},{key:"item.finishDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.finishDate))+" ")]}},{key:"item.complete",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":item.complete ? 'success' : 'grey lighten-3'}},[_vm._v(" "+_vm._s(item.complete ? "Complete" : "Incomplete")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"white--text text-capitalize",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewTask(item)}}},[_vm._v(" View Task ")])]}}],null,true)})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }