import AppraisalStore from "./modules/appraisalv1/AppraisalStore";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";

const user = AuthService.user;

const caption = user
  ? user.captions
    ? user.captions.appraisal_caption
    : undefined
  : undefined;

export default {
  order: 3,
  name: "Performance Management",
  icon: "verified",
  hasSideLinks: true,
  enabled: true,
  links: [
    {
      name: "Appraisal Dashboard",
      icon: "mdi-account-check",
      to: "/appraisal/dashboard",
      enabled: true,
    },
    {
      name: `${caption ? caption : "Appraisal KPIs"}`,
      icon: "mdi-text-box",
      to: "/appraisal/templates/list",
      enabled: true,
    },
    {
      name: "My Reviews",
      icon: "mdi-playlist-edit",
      to: "/appraisal/applications/list",
      enabled: true,
    },
    {
      name: "Supervisor Reviews",
      icon: "mdi-account-multiple",
      to: "/appraisal/supervisor-reviews/list",
      enabled: true,
    },
    {
      name: "Second Supervisor Reviews",
      icon: "mdi-account-multiple",
      to: "/appraisal/second-supervisor-reviews/list",
      enabled: true,
    },
    {
      name: "My Reviewees",
      icon: "mdi-format-list-checks",
      to: "/appraisal/review",
      disableEdit: true,
      enabled: AppraisalStore.getters["appraisal/isReviewer"],
    },
  ].filter((l) => {
    return l.enabled;
  }),
};
