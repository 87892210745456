<template>
  <v-card-text>
    <v-row>
      <v-col cols="12" md="4">
        <v-card outlined>
          <v-card-text v-if="isProfileLoaded">
            <v-card-title class="d-flex flex-column">
              Employee Profile
              <!-- <span class="text-subtitle-1">{{ currentDate }} </span> -->
              <v-avatar size="72" color="grey">
                <v-avatar size="62">
                  <img
                    :src="
                      profilePicture !== ''
                        ? `data:application/jpg;base64,${profilePicture}`
                        : getUserImage()
                    "
                  />
                </v-avatar>
              </v-avatar>

              <v-chip small class="my-2">
                <v-icon color="success">mdi-circle-medium</v-icon>
                {{ user.status }}
              </v-chip>
              <span class="text-subtitle-2">
                {{
                  user.firstName +
                  " " +
                  user.middleName +
                  " " +
                  user.lastName
                }}
              </span>
              <span class="text-caption">{{ user.jobTitle }}</span>
            </v-card-title>
            <v-list dense>
              <template>
                <!-- <v-divider></v-divider> -->
                <v-list-item
                  v-for="(data, key) in hrEmployeeProfile"
                  :key="key"
                >
                  <!-- <v-list-item-avatar> &nbsp; </v-list-item-avatar> -->
                  <v-list-item-content class="d-flex flex-row">
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <v-list-item-title class="text-capitalize">
                          {{ key }}
                        </v-list-item-title>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-list-item-subtitle>
                          {{ computeDisplayData(key, data) }}
                        </v-list-item-subtitle>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <!-- reporting to -->
            <v-list dense v-if="hrEmployeeProfile.reportTo !== ''">
              <v-subheader>Reporting Person</v-subheader>
              <v-list-item>
                <v-list-item-avatar
                  ><img :src="getUserImage()" alt="John"
                /></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    hrEmployeeProfile.reportTo !== ""
                      ? getEmployeeName(hrEmployeeProfile.reportTo)
                      : "Not Specified"
                  }}</v-list-item-title>
                  <!-- <v-list-item-subtitle>role</v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-skeleton-loader
            v-else
            type="list-item-avatar, divider, list-item-three-line, card-heading"
            :loading="true"
          ></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-card elevation="0" tile>
          <v-card-title class="overline header-title">
            <div class="caption">
              <span class="description">Job Descriptions</span>
            </div>
            <v-chip x-small dark class="ml-2" color="primary">{{
              getJobPosition(hrEmployeeProfile.position)
            }}</v-chip>
          </v-card-title>
          <v-divider />
          <v-card-text class="background-color: white">
            <v-list-item class="mt-n1" v-if="isDataLoaded && jobDetails.length">
              <v-list-item-content>
                <v-list-item-title class="text-lg-h6 font-weight-bold">
                  Job Details
                </v-list-item-title>
                <v-row dense>
                  <v-col v-for="(data, key) in jobDetails[0]" :key="key">
                    <v-list-item-subtitle>
                      <span class="caption">{{ key }}</span>
                      <v-chip small dark class="ma-2" color="primary">
                        {{
                          data !== "" ? computeDisplayData(key, data) : "null"
                        }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-else-if="jobDetails.length <= 0">
              <v-list-item-content
                ><img
                  :src="getNoDataImage()"
                  alt="John"
                  style="max-width: 100%"
              /></v-list-item-content>
            </v-list-item>
            <v-skeleton-loader
              v-else
              type="list-item-two-line, divider, list-item-three-line, card-heading"
              :loading="true"
            ></v-skeleton-loader>

            <!-- {{ mergedProfile }} -->
            <v-expansion-panels
              v-if="
                isDataLoaded &&
                jobResponsibilities &&
                jobResponsibilities.length
              "
              multiple
              v-model="panel"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <v-row no-gutters>
                      <v-col cols="8">
                        <h6 class="text-lg-h6 font-weight-bold">
                          Job Roles and Reponsibilties
                        </h6>
                      </v-col>
                      <v-col cols="4" class="text--secondary">
                        <v-fade-transition leave-absolute>
                          <v-chip
                            v-if="open"
                            key="0"
                            x-small
                            dark
                            class="ma-2"
                            color="primary"
                          >
                            {{
                              hrEmployeeProfile.position !== ""
                                ? hrEmployeeProfile.position
                                : "null"
                            }}
                          </v-chip>
                        </v-fade-transition>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <v-list-item
                      v-for="responsibility in jobResponsibilities"
                      :key="responsibility.lineNo"
                    >
                      <v-list-item-avatar size="24">
                        <v-icon class="primary" dark>
                          mdi-checkbox-marked-circle-auto-outline
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          v-text="responsibility.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider inset></v-divider>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "Introduction",
  data() {
    return {
      currentDate: new Date().toISOString().substr(0, 10),
      profile: {
        gender: "",
        email: "",
        phoneNo: "",
      },
      panel: [0],
    };
  },

  computed: {
    isDataLoaded() {
      return this.$store.getters["appraisal/appraisalGetter"]("isDataLoaded");
    },
    isProfileLoaded() {
      return this.$store.getters["profile/isDataLoaded"];
    },
    user() {
      return this.$store.getters["profile/employee"][0];
    },
    profilePicture: function () {
      return this.$store.getters["Setup/setupGetter"]("profilePicture");
    },
    //hr emp profile
    hrEmployeeProfile() {
      const hrEmployee =
        this.$store.getters["appraisal/appraisalGetter"]("hrEmployeeWithJds");
      if (hrEmployee && hrEmployee.length > 0) {
        const { firstName, middleName, lastName, no, position, reportTo } =
          hrEmployee[0];

        return { firstName, middleName, lastName, no, position, reportTo };
      } else {
        // Handler case is(hrEmployee)==null || undefined
        return {};
      }
    },
    //job
    job() {
      return this.$store.getters["appraisal/appraisalGetter"]("jobs")|| [];
    },
    jobAttributes() {
      return this.job.length > 0 ? this.job[0].jobAttributes : [];
    },
    jobResponsibilities() {
      return this.job.length > 0 ? this.job[0].jobResponsibilities : [];
    },
    //get jobDescriptions and selected display attr
    jobDetails() {
      return this.job?.map(
        ({
          description,
          fullTitle,
          status,
          category,
          code,
          grade,
          reportsTo,
          shortTitle,
        }) => ({
          description,
          fullTitle,
          status,
          category,
          code,
          grade,
          reportsTo,
          shortTitle,
        })
      );
    },

    mergedProfile() {
      //merge props in hrEmp & user->profile
      const mergedProfile = { ...this.hrEmployeeProfile, ...this.profile };
      return mergedProfile;
    },
    selectedPeriod() {
      return this.$store.getters["appraisal/appraisalGetter"]("selectedPeriod");
    },

    allEmployees() {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
  },

  methods: {
    getUserImage() {
      return require("./../assets/user.png");
    },
    getNoDataImage() {
      return require("./../assets/noData.jpg");
    },
    getEmployeeName(no) {
      const reportsTo = this.allEmployees
        .filter((el) => el.number === no)
        .shift();

      return reportsTo
        ? `${reportsTo.firstName}  ${reportsTo.lastName}`
        : undefined;
    },
    getJobPosition(code) {
      return this.job?.find((j) => j.code === code).fullTitle ?? "";
    },
    computeDisplayData(key, data) {
      switch (key) {
        case "reportTo":
          return this.getEmployeeName(data);

        case "position":
          return this.getJobPosition(data);

        case "reportsTo":
          return this.getEmployeeName(data);

        default:
          return data;
      }
    },
  },
  watch: {
    user() {
      if (Object.keys(this.user).length !== 0) {
        this.profile.email = this.user.email || "--";
        this.profile.gender = this.user.gender || "--";
        this.profile.phoneNo = this.user.phone_no || "--";
      }
    },
  },
};
</script>
