import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
const AppDashboard = () =>
  import("@/packages/cassandra-base/app/layout/AppDashboard");
import Templates from "./components/Templates";
import AcceptanceForm from "./components/AcceptanceForm.vue";
import closedPeriodAppraisal from "./components/closedPeriodAppraisal.vue";
const AppraisalView = () =>
  import(
    "@/packages/cassandra-appraisal/modules/appraisalv3/views/AppraisalView.vue"
  );
import AppraisalDashboard from "@/packages/cassandra-appraisal/modules/appraisalv3/components/AppraisalDashboard.vue";
import AppraisalList from "./components/AppraisalApplicationList.vue";
import AppraisalSupervisorReviews from "./components/AppraisalSupervisorReviews.vue";
import SecondSupervisorReviews from "./components/SecondSupervisorReviews.vue";
import AppraisalApprovalCard from "./components/AppraisalApprovalCard.vue";
import AppraisalCard from "./components/AppraisalCard.vue";
import AppraisalAcceptReview from "./components/AppraisalAcceptReview.vue";

export default [
  {
    path: "/appraisal",
    component: AppDashboard,
    children: [
      {
        path: "",
        components: { view: AppraisalView },
        children: [
          {
            path: "",
            redirect: "/appraisal/dashboard",
          },
          {
            path: "dashboard",
            name: "AppraisalDashboard",
            components: { view: AppraisalDashboard },
            meta: { middleware: [Auth] },
          },
          {
            path: "templates/list",
            name: "Templates",
            components: {
              view: Templates,
            },
            meta: { middleware: [Auth] },
          },

          {
            path: "templates/form/:period/:applicationNo",
            name: "AcceptanceForm",
            components: {
              view: AcceptanceForm,
            },
            meta: { middleware: [Auth] },
          },
          {
            path: "periods/:period",
            name: "closedPeriodAppraisal",
            components: {
              view: closedPeriodAppraisal,
            },
            meta: { middleware: [Auth] },
          },
          // applications -(docs, approvals)
          {
            path: "applications/list",
            name: "Applications",
            components: {
              view: AppraisalList,
            },
            meta: { middleware: [Auth] },
          },
          {
            path: "supervisor-reviews/list",
            name: "AppraisalSupervisorReviews",
            components: { view: AppraisalSupervisorReviews },
            meta: { middleware: [Auth] },
          },
          {
            path: "second-supervisor-reviews/list",
            name: "SecondSupervisorReviews",
            components: { view: SecondSupervisorReviews },
            meta: { middleware: [Auth] },
          },
          {
            path: "approvalCard/:documentNo",
            name: "AppraisalApprovalCard",
            components: {
              view: AppraisalApprovalCard,
            },
            meta: { middleware: [Auth] },
          },

          {
            path: "card/:code/:objective?/:review?",
            name: "Appraisal Card",
            components: { view: AppraisalCard },
            meta: { middleware: [Auth] },
          },
          {
            path: "reviewee/:reviewerID",
            name: "AppraisalAcceptReview",
            components: {
              view: AppraisalAcceptReview,
            },
            meta: { middleware: [Auth] },
          },

          // {
          //   path: "report/:code",
          //   name: "AppraisalReport",
          //   component: AppraisalReport,
          //   meta: { middleware: [Auth] },
          // },
          // {
          //   path: "supervisor-report",
          //   name: "supervisorReport",
          //   component: SupervisorAppraisalReport,
          //   meta: { middleware: [Auth] },
          // },
        ],
      },
    ],
  },
];
