import call from "@kinetics254/cassandra-base/service/http";
import constants from "./allowanceConstants";

export default {
  namespaced: true,
  state: {
    allowances: [],
    allowance: {},
    allowanceDocument: "",
    pendingApprovals: [],
    dimensions: [],
    approvalModal: {
      open: false,
      application_code: "",
      status: false,
    },
    employeeAllowance: 0,
    leaveAllowancePermission: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
    SET_APPROVAL_MODAL: (state, payload) => {
      // eslint-disable-next-line camelcase
      const { open, application_code, status } = payload;
      state.approvalModal = { ...state, open, application_code, status };
    },
  },
  getters: {
    allowanceGetters: (state) => (val) => state[val],
  },
  actions: {
    getAllowances: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getAllowance)
        .then((res) => {
          commit("MUTATE", { state: "allowances", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getAllowance: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.showAllowance(data))
        .then((res) => {
          commit("MUTATE", { state: "allowance", data: res.data.data[0] });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    storeAllowance: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.storeAllowance, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiSuccess",
            "Successfully Saved leave allowance request"
          );
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    storeLeaveandAllowance: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.storeAllowance, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiSuccess",
            "Successfully Saved leave allowance request"
          );
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    patchAllowance: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", constants.patchAllowance(data), data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiSuccess",
            "Successfully Updated leave allowance request"
          );
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    RejectReason: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", constants.patchAllowance(data), data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    deleteAllowance: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("delete", constants.deleteAllowance(data.item), data.item)
        .then((res) => {
          Event.$emit(
            "ApiSuccess",
            "Successfully Deleted leave allowance request"
          );
          data.type === "list"
            ? dispatch("getAllowances")
            : Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    sendApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.sendApproval, data)
        .then((res) => {
          Event.$emit(
            "ApiSuccess",
            "Successfully sent leave allowance request for approval"
          );
          data.type === "list"
            ? dispatch("getAllowances")
            : dispatch("getAllowance", data.docNo);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    cancelApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.cancelApproval, data)
        .then((res) => {
          Event.$emit(
            "ApiSuccess",
            "Successfully cancelled leave allowance approval request"
          );
          data.type === "list"
            ? dispatch("getAllowances")
            : dispatch("getAllowance", data.docNo);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    allowanceDocument: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.allowanceDocument, data)
        .then((res) => {
          commit("MUTATE", { state: "allowanceDocument", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    reject: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.reject, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiSuccess",
            "Successfully Rejected leave allowance request"
          );
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    approve: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.approve, data)
        .then((res) => {
          Event.$emit(
            "ApiSuccess",
            "Successfully Approved leave allowance request"
          );
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    pendingApprovals: ({ commit, dispatch }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.pendingApprovals)
        .then((res) => {
          commit("MUTATE", { state: "pendingApprovals", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    saveAndSendforApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call(
        data.method,
        data.method === "post"
          ? constants.storeAllowance
          : constants.patchAllowance(data),
        data
      )
        .then((res) => {
          dispatch("sendApproval", { docNo: res.data.data[0].applicationCode });
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getDimensionValues: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.dimensions)
        .then((res) => {
          commit("MUTATE", { state: "dimensions", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    setApprovalModal: ({ commit }, data) => {
      commit("SET_APPROVAL_MODAL", data);
    },

    GetEmployeeAllowance: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.GetEmployeeAllowance)
        .then((res) => {
          commit("MUTATE", {
            state: "employeeAllowance",
            data: res.data.data[0],
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    CheckIfAppliedLeaveAllowance: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.CheckIfAppliedLeaveAllowance)
        .then((res) => {
          commit("MUTATE", {
            state: "leaveAllowancePermission",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
