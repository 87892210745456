<template>
  <v-container fluid>
    <AppBar :isApprover="isApprover" :path="path" />
    <v-row>
      <v-col cols="12" md="4">
        <div>
          <v-text-field
            v-model="listFilter"
            prepend-inner-icon="filter_alt"
            solo
            dense
            label="filter"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div style="margin-left: 17%">
          <v-btn
            outlined
            class="mr-4"
            :disabled="isCalender"
            color="primary"
            @click="isCalender = true"
          >
            <v-icon class="mr-2">date_range</v-icon>
            Calender
          </v-btn>
          <v-btn
            outlined
            class="mr-4"
            :disabled="!isCalender"
            color="primary"
            @click="isCalender = false"
          >
            <v-icon class="mr-2">view_day</v-icon>
            list
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn v-if="isApprover" style="float: right" color="primary" text>
          Approval Entries
        </v-btn>
        <v-btn
          style="float: right"
          color="primary"
          v-else
          link
          to="make_application"
        >
          <v-icon class="mx-3">add</v-icon>
          Make Application
        </v-btn>
      </v-col>
    </v-row>
    <div style="height: 100%">
      <LeaveCalender v-if="isCalender" :isApprover="isApprover" />
      <LeaveList :isApprover="isApprover" :isCalender="isCalender" />
      <!-- <handOverRequests :isCalender="isCalender" /> -->
    </div>
    <LeaveSheet />
    <Approve />
  </v-container>
</template>

<script>
import LeaveCalender from "./LeaveCalender";
import LeaveList from "./LeaveList";
// import handOverRequests from './HandOverRequests'
import Approve from "../components/Approve.vue";
import LeaveSheet from "../components/LeaveSheet";
import AppBar from "../components/AppBar";
import { SetupMixin } from "../../../mixins";
export default {
  name: "LeaveApplication",
  mixins: [SetupMixin],
  components: {
    LeaveCalender,
    LeaveList,
    Approve,
    LeaveSheet,
    AppBar,
  },
  data: function () {
    return {
      dateRange: [],
      actionsMenu: null,
      isApprover: this.$router.currentRoute.meta.isApprover,
      path: this.$router.currentRoute.meta.breadcrumb,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (to.meta.isApprover) {
        v.getApprovalApplications();
      } else {
        v.loadMyApplications();
      }
      /** get setups */
      v.getSetup();
    });
  },
  mounted() {},
  created() {},
  watch: {
    $route(to, from) {
      this.isApprover = to.meta.isApprover;
      this.path = to.meta.breadcrumb;
    },
  },
  computed: {
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    isCalender: {
      get() {
        return this.$store.getters["leave/isCalender"];
      },
      set(value) {
        this.$store.commit("leave/MUTATE", {
          stateField: "isCalender",
          data: value,
        });
      },
    },
    listFilter: {
      get() {
        return this.$store.state.leave.listFilterValue;
      },
      set(value) {
        this.$store.commit("leave/MUTATE", {
          stateField: "listFilterValue",
          data: value,
        });
      },
    },
    filter: {
      get() {
        return this.$store.state.leave.filterValue;
      },
      set(value) {
        this.$store.commit("leave/MUTATE", {
          stateField: "filterValue",
          data: value,
        });
      },
    },
  },
  methods: {
    getApprovalApplications: function () {
      this.$store.dispatch("leave/getApprovalApplications");
    },
    loadMyApplications: function () {
      const employeeNo = this.userData.employee;
      const data = { employeeNo };
      setTimeout(() => {
        this.$store.dispatch("leave/getApplications", data);
      }, 1000);
    },
    deleteApplication: function (id) {
      this.$store.dispatch("leave/deleteApplication", {
        id: id,
        data: { Status: "Canceled" },
      });
    },
  },
};
</script>

<style scoped></style>
