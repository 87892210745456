import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v("My Projects ")]),_c(VCardText,[_c(VDataTable,{staticClass:"task--list",attrs:{"headers":_vm.headers,"items":_vm.projects,"search":_vm.search,"sort-desc":[false, true],"multi-sort":"","single-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currencyCode.length > 0 ? item.currencyCode : "")+" "+_vm._s(_vm._f("currencyFormat")(item.amount))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.milestones",fn:function(slotData){return [_c(VBadge,{staticClass:"white--text",attrs:{"content":slotData.item.milestones.length,"color":"secondary","overlap":""}},[_c(VBtn,{staticClass:"white--text text-capitalize",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewMilestones(slotData)}}},[_vm._v(" View Milstones "),_c(VIcon,{staticClass:"mx-2"},[_vm._v("expand_more")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-2",attrs:{"colspan":headers.length}},[_c(VList,{attrs:{"dense":"","flat":"","outlined":""}},[_c(VListItem,{attrs:{"dense":""}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.projectNo)+" - Milestones ")])],1),_c(VDivider),_c('milestone-list-vue',{attrs:{"milestones":item.milestones}})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }