import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"ml-3"},[_vm._v(" Approval Entries ")]),_c(VTimeline,{staticClass:"ml-n3",attrs:{"dense":""}},_vm._l((_vm.formData.approvalEntry),function(item,i){return _c(VTimelineItem,{directives:[{name:"show",rawName:"v-show",value:(item.status !== 'Canceled'),expression:"item.status !== 'Canceled'"}],key:i,attrs:{"color":_vm.getColor(item.status).icon_color,"small":"","fill-dot":"","icon":_vm.getColor(item.status).icon}},[(item.status !== 'Canceled')?_c('div',{staticClass:"d-flex ml-n10"},[_c('div',{},[_c(VListItem,{attrs:{"dense":""}},[_c(VListItemAvatar,{staticClass:"pl-2",staticStyle:{"color":"rgb(0, 68, 139)"},attrs:{"color":_vm.getColor(item.status).icon_color,"size":"34"}},[_vm._v(" "+_vm._s(_vm.getEmployeeName(item.approverID).firstName[0] + _vm.getEmployeeName(item.approverID).lastName[0])+" ")]),_c(VListItemContent,[_c(VListItemTitle,[_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(" "+_vm._s(_vm.getEmployeeName(item.approverID).firstName + " " + _vm.getEmployeeName(item.approverID).lastName)+" ")]),_c(VChip,{staticClass:"mx-1",attrs:{"label":"","color":_vm.getColor(item.status).icon_color,"x-small":""}},[_c('span',{staticClass:"view-page"},[_vm._v(_vm._s(item.status))])])],1)]),_c(VListItemSubtitle,{},[_vm._v(" "+_vm._s(_vm.getEmployeeName(item.approverID).email)+" ")])],1)],1)],1),_c('div',{staticClass:"comments"},[_c(VListItem,{attrs:{"dense":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" Comment ")]),_vm._l((_vm.getComment(item)),function(item,i){return _c(VCard,{key:i,staticClass:"text--secondary",attrs:{"flat":""}},[_vm._v(" "+_vm._s(item.comment)+" ")])})],2)],1)],1)]):_vm._e()])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }