import call from "@kinetics254/cassandra-base/service/http";
import constants from "./timesheetConstants";

export default {
  namespaced: true,
  state: {
    timesheet: {},
    projects: [],
    calender: [],
    lines: [],
    period: [],
    loading: false,
    report: "",
    approvalEntries: [],
    isAppliedLeave: false,
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    timesheetGetters: (state) => (val) => state[val],
  },
  actions: {
    getProjects: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.projects)
        .then((res) => {
          commit("MUTATE", { state: "projects", value: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getCalender: ({ commit }, month) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.calender(month))
        .then((res) => {
          commit("MUTATE", { state: "calender", value: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getPeriod: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.period(payload))
        .then((res) => {
          commit("MUTATE", { state: "period", value: res.data.data.shift() });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getHeaders: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.headers(payload))
        .then((res) => {
          const timesheet =
            res.data.data.length > 0 ? res.data.data.shift() : {};
          commit("MUTATE", {
            state: "timesheet",
            value: timesheet,
          });
          console.log(timesheet);
          dispatch(
            "Timesheet/getApprovalEntries",
            {
              timesheetDocumetNo: timesheet.periodCode,
            },
            { root: true }
          );
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getLines: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.lines(payload))
        .then((res) => {
          commit("MUTATE", { state: "lines", value: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    addEntry: ({ commit }, payload) => {
      commit("MUTATE", { state: "loading", value: true });
      call("post", constants.line, payload)
        .then((res) => {
          commit("MUTATE", { state: "loading", value: false });
          Event.$emit(payload.type);
          const newLocal = res.data.data.length + "activity entrie(s) added";
          Event.$emit("ApiSuccess", newLocal);
        })
        .catch((error) => {
          commit("MUTATE", { state: "loading", value: false });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    editEntry: ({ commit }, payload) => {
      commit("MUTATE", { state: "loading", value: true });
      call("patch", constants.lines(payload), payload)
        .then((res) => {
          commit("MUTATE", { state: "loading", value: false });
          Event.$emit(payload.type);
          const newLocal = res.data.data.length + "activity entrie(s) added";
          Event.$emit("ApiSuccess", newLocal);
        })
        .catch((error) => {
          commit("MUTATE", { state: "loading", value: false });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    sendForApproval: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.sendForApproval, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("getApprovalEntries");
          Event.$emit("refetchTimesheets");
          Event.$emit("ApiSuccess", "Timesheet sent for approval");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          console.log("error in ticket", error);
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    cancelApproval: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.cancelApproval, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("getApprovalEntries");
          Event.$emit("refetchTimesheets");
          Event.$emit("ApiSuccess", "Timesheet approval request cancelled");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getTimesheetReport: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.timesheetReport, payload)
        .then((res) => {
          commit("MUTATE", { state: "report", value: res.data.data.shift() });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getApprovalEntries: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.approvalEntries(payload))
        .then((res) => {
          commit("MUTATE", { state: "approvalEntries", value: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getIsAppliedLeave: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.isAppliedLeave)
        .then((res) => {
          commit("MUTATE", {
            state: "isAppliedLeave",
            value: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    reOpenTimeSheet: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.reOpenTimeSheet, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Timesheet reopened");
          Event.$emit("getApprovalEntries");
          Event.$emit("refetchTimesheets");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
  },
};
