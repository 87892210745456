export default {
  items: (payload) =>
    `inventory/items${payload ? `${getParams(payload)}` : ""}`,
  categories: "inventory/categories",
  locations: "inventory/locations",
};

function getParams(params) {
  let param = "";
  if (params) {
    param = `?${Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&")}`;
  }
  return param;
}
