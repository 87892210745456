import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";
import Advance from "./views/Advance";
import AdvanceList from "./components/AdvanceList";
import AdvanceCard from "./components/AdvanceCard";
import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";

export default [
  {
    path: "/advance",
    component: AppDashboard,
    children: [
      {
        path: "",
        components: { view: Advance },
        children: [
          {
            path: "",
            redirect: "/advance/list",
            meta: { middleware: [Auth] },
          },
          {
            path: "list",
            name: "Advance List",
            component: AdvanceList,
            meta: { middleware: [Auth] },
          },
          {
            path: "card/:code?",
            name: "Advance Card",
            component: AdvanceCard,
            meta: { middleware: [Auth] },
          },
        ],
      },
    ],
  },
];
