import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";
import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
import HandBook from "./views/HandBook";
import HandBookList from "./components/HandBookList";
import HandBookCard from "./components/HandBookCard";

export default [
  {
    path: "/handbook",
    component: AppDashboard,
    children: [
      {
        path: "",
        components: { view: HandBook },
        children: [
          {
            path: "",
            redirect: "/handbook/list",
          },
          {
            path: "list",
            name: "HandBookList",
            components: {
              view: HandBookList,
            },
            meta: { middleware: [Auth] },
          },
          {
            path: "card/:code",
            name: "HandBookCard",
            components: {
              view: HandBookList,
              action: HandBookCard,
            },
            meta: { middleware: [Auth] },
          },
        ],
      },
    ],
  },
];
