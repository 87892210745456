<template>
  <v-dialog v-model="dialog" persistent width="50%">
    <v-card class="mx-auto">
      <v-card-title class="task--title">
        <span class="text-lg-h5"
          >{{ isDelete ? "Update" : "Create" }} Task</span
        >
        <v-spacer />
        <v-btn
          class="success text-capitalize white--text mx-2"
          @click="completeTask"
          v-if="isDelete"
        >
          <v-icon class="mx-2"> check </v-icon>
          Complete Task
        </v-btn>
        <v-btn
          class="deep-orange text-capitalize white--text mx-2"
          @click="deleteTask"
          v-if="isDelete"
        >
          <v-icon class="mx-2"> delete </v-icon>
          Delete
        </v-btn>
        <v-btn icon class="error" @click="$router.back()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form v-model="isValid" ref="taskCard">
          <v-row class="mt-3">
            <v-col cols="12" md="12">
              <v-autocomplete
                outlined
                v-model="formData.activityLine"
                dense
                placeholder="Enter Activity"
                persistent-placeholder
                prepend-inner-icon="local_activity"
                :items="activities"
                :rules="rules.activityLine"
                :item-text="(item) => item.activity"
                :item-value="(item) => item.lineNo"
              >
                <template v-slot:label>
                  <span class="text-capitalize">Activity</span>
                  <span class="red--text">*</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" class="mt-n6">
              <v-combobox
                outlined
                v-model="taskTitle"
                dense
                placeholder="Enter task title"
                persistent-placeholder
                prepend-inner-icon="subtitles"
                :items="taskSetup"
                :rules="rules.task"
                :return-object="false"
                @keyup="updateTaskDetails($event)"
              >
                <template v-slot:label>
                  <span class="text-capitalize">Task Title</span>
                  <span class="red--text">*</span>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" md="12" class="mt-n6">
              <v-textarea
                outlined
                v-model="formData.description"
                dense
                placeholder="Enter description"
                persistent-placeholder
                prepend-inner-icon="subtitles"
                :rules="rules.description"
                rows="2"
              >
                <template v-slot:label>
                  <span class="text-capitalize">Description</span>
                  <span class="red--text">*</span>
                </template>
              </v-textarea>
            </v-col>
            <v-col cols="12" md="6" class="mt-n6">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.startDate"
                    outlined
                    dense
                    placeholder="Enter from date"
                    persistent-placeholder
                    prepend-inner-icon="event"
                    :rules="rules.startDate"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <span class="text-capitalize">From Date</span>
                      <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.startDate"
                  :min="today"
                  @input="startDateMenu = false"
                >
                  <v-spacer />
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="mt-n6">
              <v-menu
                v-model="finishDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.finishDate"
                    outlined
                    dense
                    placeholder="Enter to date"
                    persistent-placeholder
                    prepend-inner-icon="event"
                    :rules="rules.finishDate"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <span class="text-capitalize">To Date</span>
                      <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.finishDate"
                  :min="formData.startDate"
                  @input="finishDateMenu = false"
                >
                  <v-spacer />
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
        <MilestoneAttachments
          :attachments="formData.attachments"
          :line="task"
          @addAttachment="addAttachment"
          @removeAttachment="removeAttachment"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions class="task--actions">
        <v-spacer />
        <v-btn color="primary" class="text-capitalize" @click="saveTask">
          <v-icon class="mx-2">save</v-icon>
          Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { helper } from "@/packages/cassandra-base/utilities";
import RouteMixin from "../mixins/RouteMixin";
import DateMixin from "../mixins/DateMixin";
import MilestoneAttachments from "./MilestoneAttachments";
export default {
  name: "MilestoneTaskCard",
  mixins: [RouteMixin, DateMixin],
  components: {
    MilestoneAttachments,
  },
  data() {
    return {
      dialog: true,
      isValid: false,
      startDateMenu: false,
      today: new Date().toISOString().substr(0, 10),
      finishDateMenu: false,
      formData: {
        task: "",
        description: "",
        startDate: "",
        finishDate: "",
        activityLine: "",
        attachments: [],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (to.query.lineNo) {
        v.$store.dispatch("Projects/getTask", {
          ...v.decryptRoute(to).query,
        });
      }
      v.$store.dispatch("Setup/getTaskSetup");
    });
  },
  mounted() {
    Event.$on("gotoMilestone", () => {
      this.routeTo({
        name: "Milestone",
        query: {
          documentNo: this.decryptRoute(this.$route).query.documentNo,
          milestoneLine: parseInt(this.$route.query.milestoneLine),
        },
      });
    });
  },
  computed: {
    rules() {
      return {
        task: [
          (v) =>
            (typeof v === "object"
              ? !!v.value && v.value.length <= 20
              : !!v && v.length <= 20) ||
            "Task Title must be less than or equal to 20 characters and not empty",
        ],
        activityLine: [(v) => !!v || "Activity is required"],
        description: [(v) => !!v || "Description is required"],
        startDate: [(v) => !!v || "Start date is required"],
        finishDate: [(v) => !!v || "Finnish date is required"],
      };
    },
    activities: function () {
      return this.$store.getters["Projects/projectGetters"]("activities");
    },
    task: function () {
      return this.$store.getters["Projects/projectGetters"]("task");
    },
    isEdit: function () {
      return this.$route.query.lineNo ? !this.formData.complete : true;
    },
    isDelete: function () {
      return this.$route.query.lineNo ? !this.formData.complete : false;
    },
    taskSetup: function () {
      const task = this.$store.getters["Setup/setupGetter"]("taskSetup");
      return task
        ? task.map((x) => ({ text: x.description, value: x.code }))
        : [];
    },
    taskTitle: {
      get() {
        const task = this.taskSetup.find((x) => x.value === this.formData.task);
        return task || this.formData.task;
      },
      set(value) {
        this.formData.task = value;
        const task = this.taskSetup.find((x) => x.value === value);
        this.formData.description = task ? task.text : "";
      },
    },
  },
  methods: {
    saveTask() {
      if (this.$refs.taskCard.validate()) {
        let data = { ...this.formData };
        data.milestoneLine = this.decryptRoute(this.$route).query.milestoneLine;
        data.documentNo = this.decryptRoute(this.$route).query.documentNo;
        data.activity = this.activities.find(
          (x) => x.lineNo === data.activityLine
        ).activity;
        if (this.$route.query.lineNo) {
          data = {
            ...data,
            ...this.decryptRoute(this.$route).query,
          };
          this.$store.dispatch("Projects/updateTask", data);
        } else {
          this.$store.dispatch("Projects/saveTask", data);
        }
      }
    },
    deleteTask() {
      this.$confirm.show({
        title: "Delete Task",
        text: "Are you sure you want to delete this task?",
        onConfirm: () => {
          this.$store.dispatch("Projects/deleteTask", {
            ...this.task,
          });
        },
      });
    },
    completeTask() {
      this.$confirm.show({
        title: "Complete Task",
        text: "Are you sure you want to complete this task? Kindly enter actual and insert an attachment if any.",
        numberInput: true,
        fileInput: true,
        label: "What Percentage of the task is completed?",
        onConfirm: (data) => {
          console.log(data);
          // check if number is an integer
          const { number, file } = data;
          if (Number.isInteger(parseInt(number))) {
            this.$store.dispatch("Milestone/updateTask", {
              ...this.task,
              complete: true,
              actual: parseInt(number),
              attachment: file,
            });
          }
        },
      });
    },
    updateTaskDetails(event) {
      const task = this.taskSetup.find((x) => x.value === event.target.value);
      if (!task) this.formData.description = "";
    },
    addAttachment(attachment) {
      this.formData.attachments.push({ ...attachment });
    },
    removeAttachment(attachment) {
      this.formData.attachments.splice(
        this.formData.attachments.findIndex((a) => a.name === attachment.name),
        1
      );
    },
  },
  watch: {
    task: {
      handler: function (val) {
        if (val) {
          this.formData = { ...val };
          this.formData.startDate === "0001-01-01"
            ? (this.formData.startDate = "")
            : "";
          this.formData.finishDate === "0001-01-01"
            ? (this.formData.finishDate = "")
            : "";
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.task--title {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.task--card.v-sheet.v-card {
  border-radius: 0 10px 10px 0 !important;
}

.task--actions {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
}
</style>
