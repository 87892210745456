<template>
  <div>
    <router-view name="view" />
    <router-view name="action" />
  </div>
</template>
<script>
export default {
  name: "HandBook",
  data: function () {
    return {};
  },
};
</script>
<style></style>
