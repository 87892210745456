const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default {
  order: 4,
  name: "Timesheet",
  icon: "pending_actions",
  to: `/timesheet/list/${monthNames[new Date().getMonth()]}`,
  enabled: true,
};
