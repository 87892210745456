<template>
  <v-card>
    <v-card-title class="justify-center">
      <v-btn v-if="!passmodal" text color="red" @click="$router.back()">
        <v-icon clas="mx-1">arrow_back</v-icon>
        back
      </v-btn>
      <v-spacer v-if="!passmodal" />
      {{
        passmodal && allowanceAmount !== 0
          ? "Leave Allowance Application"
          : "Leave Allowance"
      }}
    </v-card-title>

    <v-card-text class="mt-6">
      <v-form v-model="isValid" ref="allowanceForm">
        <v-row>
          <v-col :cols="passmodal && allowanceAmount !== 0 ? '6' : '3'">
            <v-text-field
              label="Employee Number"
              dense
              outlined
              :value="userData.employee"
              readonly
            ></v-text-field>
          </v-col>
          <v-col :cols="passmodal && allowanceAmount !== 0 ? '6' : '3'">
            <v-text-field
              label="Employee Name"
              dense
              outlined
              :value="`${userData.profile.firstName} ${userData.profile.middleName} ${userData.profile.lastName}`"
              readonly
            ></v-text-field>
          </v-col>
          <v-col :cols="passmodal && allowanceAmount !== 0 ? '6' : '3'">
            <v-text-field
              label="Leave Allowance Amount"
              dense
              outlined
              :value="allowanceAmount"
              readonly
            ></v-text-field>
          </v-col>
          <v-col :cols="passmodal && allowanceAmount !== 0 ? '6' : '3'">
            <v-text-field
              label="Posting Date"
              dense
              outlined
              type="date"
              min="1"
              readonly
              v-model="formData.postingDate"
              :rules="[rule.required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
            <v-col cols="12">
              <v-textarea
                label="Description"
                dense
                outlined
                rows="3"
                v-model="formData.description"
              ></v-textarea>
            </v-col>
          </v-row> -->
      </v-form>
    </v-card-text>
    <v-card-actions v-if="passmodal && allowanceAmount !== 0" class="mt-n4">
      <v-btn
        color="red"
        text
        style="background-color: #ffcdd2"
        class="mx-2"
        v-on:click="setDialog = false"
      >
        <v-icon class="mx-1">close</v-icon>
        close
      </v-btn>
      <v-spacer />
      <div class="d-flex justify-content-end">
        <v-btn class="primary mx-2" @click="saveLeaveandAllowance">
          <v-icon class="mx-1">save</v-icon>
          Save
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import allowanceMixins from "../allowanceMixins";
// import AllowanceDimensions from './AllowanceDimensions'
// import ApprovalEntries from './ApprovalEntries'
// import helpers from '@kinetics254/cassandra-base/utilities/helpers/helper'

export default {
  name: "AllowanceForm",
  props: {
    leaveData: Object,
    passmodal: Boolean,
    allowancedialog: Boolean,
  },
  mixins: [allowanceMixins],
  components: {},
  data: function () {
    return {
      isValid: true,
      panel: [0],
      type: "card",
      formData: {
        description: "",
        shortcutDimension1Code: "",
        shortcutDimension2Code: "",
        shortcutDimension3Code: "",
        shortcutDimension4Code: "",
        postingDate: "",
      },
      rule: {
        required: (val) => !!val || "Field is required",
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (to.query.edit) {
        v.$store.dispatch("allowance/getAllowance", atob(to.query.edit));
      }
      if (to.query.approval) {
        v.$store.dispatch("allowance/getAllowance", to.query.approval);
      }
      v.$store.dispatch("allowance/GetEmployeeAllowance");
    });
  },
  computed: {
    allowance() {
      return this.$store.getters["allowance/allowanceGetters"]("allowance");
    },
    displayActions() {
      return !this.$route.query.edit || this.formData.status === "Open";
    },
    displayApprovalActions() {
      return this.$route.query.approval;
    },
    allowanceAmount() {
      const value =
        this.$store.getters["allowance/allowanceGetters"]("employeeAllowance");
      return this.displayActions
        ? `KES ${parseFloat(value).toFixed(2)}`
        : `KES ${parseFloat(this.formData.amount).toFixed(2)}`;
    },
    setDialog: {
      get() {
        return this.allowancedialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
  },
  methods: {
    saveAllowance: function () {
      if (!this.isValid) {
        this.$refs.allowanceForm.validate();
      } else {
        if (this.$route.query.edit) {
          this.$store.dispatch("allowance/patchAllowance", {
            ...this.formData,
          });
        } else {
          this.$store.dispatch("allowance/storeAllowance", {
            ...this.formData,
          });
        }
      }
    },
    saveLeaveandAllowance: function () {
      if (!this.isValid) {
        this.$refs.allowanceForm.validate();
      } else {
        const allowancedata = { ...this.formData };
        const leavedata = this.leaveData;
        const alldata = { allowancedata, leavedata };
        // console.log(alldata)
        this.$store.dispatch("leave/submitAllowanceandApplication", alldata);
      }
    },
    patchAllowance: function () {
      if (!this.isValid) {
        this.$refs.allowanceForm.validate();
      } else {
        this.$store.dispatch("allowance/patchAllowance", { ...this.formData });
      }
    },
    saveAndSendApproval: function () {
      if (!this.isValid) {
        this.$refs.allowanceForm.validate();
      } else {
        const data = { ...this.formData };
        delete data.approvalEntries;
        data.method = this.$route.query.edit ? "patch" : "post";
        this.$confirm.show({
          title: "Send Approval Confirmation",
          text: "Are you sure you want to send this record for approval?",
          onConfirm: () => {
            this.$store.dispatch("allowance/saveAndSendforApproval", data);
          },
        });
      }
    },
    approve(status) {
      const data = {
        application_code: this.formData.applicationCode,
        open: true,
        status,
      };
      this.$store.dispatch("allowance/setApprovalModal", data);
    },
  },
  watch: {
    allowance: {
      handler: function (n, o) {
        if (this.$route.query.edit || this.$route.query.approval) {
          this.formData = { ...this.allowance };
        }
      },
    },
    allowanceAmount: {
      handler: function (n, o) {
        if (this.leaveData) {
          this.formData.postingDate = this.leaveData.start_date;
        }
      },
    },
  },
};
</script>

<style scoped></style>
