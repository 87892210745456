export default [
  {
    name: "Imprest Dashboard",
    icon: "mdi-account-cash",
    to: "/imprest/dashboard",
    enabled: true,
  },
  {
    name: "Imprest Approvals",
    icon: "mdi-playlist-edit",
    to: "/imprest/approvals",
    enabled: true,
  },
  {
    name: "Imprest Requests",
    icon: "mdi-account-cash",
    to: "/imprest/list",
    enabled: true,
  },
];
