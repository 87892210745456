import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
export default {
  methods: {
    /**
     * Check document type condition
     */
    checkRequisitionType(documentType, requisitionType) {
      if (documentType === requisitionType.purchaseRequisition) {
        requisitionType.isPurchaseRequisition = true;
      } else if (documentType === requisitionType.userRequisition) {
        requisitionType.isUserRequisition = true;
      } else if (documentType === requisitionType.storeRequisition) {
        requisitionType.isStoreRequisition = true;
      }
    },
    /**
     * Color codes
     * @param status
     * @returns {*}
     */
    getStatusColor: function (status) {
      return helpers.getColor(status);
    },
    /**
     * Remove an attachment
     * @param index
     */
    deleteAttachment(index) {
      this.formData.attachments.splice(index, 1);
    },
    /**
     * Send cancel request to the server
     */
    cancelRequisition(item) {
      if (this.$route.query.code) {
        this.$store.dispatch("Requisition/sendCancelRequest", {
          headerNo: this.$route.query.code,
        });
      } else {
        this.$store.dispatch("Requisition/sendCancelRequest", {
          headerNo: item.no,
        });
      }
    },
    /**
     * send the requisition for approval
     */
    sendApprovalRequest(method) {
      this.$confirm.show({
        title: `Requisition No:  ${this.formData.no}`,
        text: "Are you sure you want to send this requisition for approval?",
        onConfirm: () => {
          this.$store.dispatch("Requisition/sendApprovalRequest", {
            headerNo: this.formData.no,
          });
        },
      });
    },
    sendListApprovalRequest(val) {
      this.$confirm.show({
        title: `Requisition No:  ${val}`,
        text: "Are you sure you want to send this requisition for approval?",
        onConfirm: () => {
          this.$store.dispatch("Requisition/sendApprovalRequest", {
            headerNo: val,
          });
          // eslint-disable-next-line no-unused-expressions
        },
      });
    },
    /**
     * This method
     * @param data
     */
    deleteRequisition: function (data) {
      this.$confirm.show({
        title: this.$route.params.documentType
          ? `Requisition No:  ${data.no}`
          : "",
        text: "Are you sure you want to delete this requisition?",
        onConfirm: () => {
          this.$store.dispatch("Requisition/deleteRequisition", { data });
          // eslint-disable-next-line no-unused-expressions
          this.$route.query.code ? Event.$emit("back") : null;
        },
      });
    },
    /**
     * Get date format
     * @param date
     * @returns {string|*}
     */
    getDateFormat: function (date) {
      return helpers.shortDateFormat(date);
    },
    getFileBlob: async function (url, fileName) {
      return await helpers.getFileBlob(
        process.env.VUE_APP_FILE_API + url,
        fileName
      );
    },
    reOpenRequisition() {
      this.$confirm.show({
        title: "Reopen Requisition",
        text: "Are you sure you want to reopen this requisition?",
        onConfirm: () => {
          this.$store.dispatch("Requisition/ReopenDocument", {
            headerNo: this.formData.no,
          });
        },
      });
    },
  },
  computed: {
    dimension1() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 1 && item.dimensionValueType === "Standard"
      );
    },
    dimension2() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 2 && item.dimensionValueType === "Standard"
      );
    },
    dimension3() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 3 && item.dimensionValueType === "Standard"
      );
    },
    dimension4() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 4 && item.dimensionValueType === "Standard"
      );
    },
    dimension5() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 5 && item.dimensionValueType === "Standard"
      );
    },
    dimension6() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 6 && item.dimensionValueType === "Standard"
      );
    },
    dimension7() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 7 && item.dimensionValueType === "Standard"
      );
    },
    dimension8() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 8 && item.dimensionValueType === "Standard"
      );
    },
    funds() {
      return this.$store.getters["imprest/imprestGetters"]("funds");
    },
    speedKeys() {
      return this.$store.getters["imprest/imprestGetters"]("speedKeys");
    },
  },
};
