import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"projects--card",attrs:{"flat":""}},[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c(VToolbarTitle,{staticClass:"font-weight-bold"},[_vm._v("Milestone - "+_vm._s(_vm.milestone.documentNo)+" - "+_vm._s(_vm.milestone.description)+" | Tasks")]),_c(VSpacer),(_vm.canRequestForPay.length !== 0)?_c(VBtn,{staticClass:"mr-2",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.routeTo({
            name: 'InvoicesList',
            query: {
              documentNo: _vm.milestone.documentNo,
              milestoneLine: _vm.milestone.lineNo,
            },
          })}}},[_vm._v(" request for payment "),_c(VIcon,{staticClass:"ml-2"},[_vm._v("sell")])],1):_vm._e(),_c(VBtn,{staticClass:"teal white--text text-capitalize mx-2",on:{"click":_vm.addActivity}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" Add Activity ")],1),_c(VBtn,{staticClass:"primary text-capitalize",on:{"click":_vm.addNewTask}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" Add Task ")],1)],1),_c(VDivider),_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c(VTabs,{attrs:{"color":"primary","dense":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"primary"}}),_vm._l((_vm.milestoneTabs),function(item,i){return _c(VTab,{key:i,staticClass:"text-capitalize font-weight-bold",attrs:{"dense":""}},[_c(VIcon,{staticClass:"mx-2"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.text)+" ")],1)})],2),_c(VSpacer),_c('MilestoneSearch')],1),_c(VDivider),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c('MilestoneTaskList')],1),_c(VTabItem,[_c('MilestoneTaskGantt')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }