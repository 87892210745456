import call from "@kinetics254/cassandra-base/service/http";
import constants from "./handbookConstants";

export default {
  namespaced: true,
  state: {
    handbookDocument: [],
    allReadhandbooks: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    handbook: (state) => state.handbookDocument,
    allReadhandbooks: (state) => state.allReadhandbooks,
  },
  actions: {
    getHandbook: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.handbookDocument)
        .then((res) => {
          if (res.data.data) {
            commit("MUTATE", {
              state: "handbookDocument",
              data: res.data.data,
            });
            commit("loader/SET_LOADING", false, { root: true });
          }
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    readHandbook: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.readhandbook, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
          Event.$emit("back");
          dispatch("allReadHandbooks");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    allReadHandbooks: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.allReadhandbooks)
        .then((res) => {
          if (res.data.data) {
            commit("MUTATE", {
              state: "allReadhandbooks",
              data: res.data.data,
            });
            commit("loader/SET_LOADING", false, { root: true });
          }
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
