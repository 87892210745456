import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto mb-3",attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.isEdit ? "Upload" : "")+" Attachments "),_c(VSpacer),(_vm.isEdit)?_c(VBtn,{staticClass:"primary text-capitalize",attrs:{"rounded":""},on:{"click":_vm.attachFile}},[_vm._v("Add Attachment "),_c(VIcon,{attrs:{"right":""}},[_vm._v("attach_file")])],1):_vm._e()],1),_c(VDivider),_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.attachments,"dense":"","disable-pagination":"","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var item = ref.item;
return [(item.no)?_c('span',[_vm._v(_vm._s(item.no))]):_c('span',[_vm._v(_vm._s(item.fileName))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('div',{staticClass:"d-flex px-2"},[_c(VBtn,{staticClass:"primary text-capitalize mx-2",attrs:{"rounded":"","small":""},on:{"click":function($event){return _vm.downloadFile(item.attachment, item.fileName)}}},[_vm._v(" Download "),_c(VIcon,{attrs:{"right":""}},[_vm._v("download")])],1),(_vm.isEdit)?_c(VBtn,{staticClass:"error text-capitalize",attrs:{"rounded":"","small":""},on:{"click":function($event){return _vm.deleteAttachment(item)}}},[_vm._v(" Delete "),_c(VIcon,{attrs:{"right":""}},[_vm._v("delete")])],1):_vm._e()],1)])]}}])}),_c('input',{ref:"file",attrs:{"type":"file","hidden":"","accept":"application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/*"},on:{"change":_vm.uploadFile}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }