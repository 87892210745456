import handbookStore from "./handbookStore";
import handbookRoutes from "./handbookRoutes";
// import handbookLinks from "./handbookLinks";

export default {
  install(Vue, options) {
    if (options.router) options.router.addRoute(...handbookRoutes);
    if (options.store) options.store.registerModule("handbook", handbookStore);
    // options.store.commit("Dashboard/ADD_LINK", handbookLinks);
  },
};
