<template>
  <div>
    <v-row>
      <v-col cols="12" md="4" lg="4" sm="12">
        <v-data-table
          disable-pagination
          hide-default-footer
          :headers="headers"
          :items="entries"
          class="tasks--gantt--table--list"
        >
          <!-- eslint-disable vue/valid-v-slot -->
          <template v-slot:item.complete="{ item }">
            <v-chip :color="item.complete ? 'success' : 'grey lighten-3'">
              {{ item.complete ? "Complete" : "Incomplete" }}
            </v-chip>
          </template>
          <!-- eslint-disable vue/valid-v-slot -->
          <template v-slot:item.description="{ item }">
            <span class="text-truncate" style="max-width: 50%">{{
              item.description
            }}</span>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="8" lg="8" sm="12" class="scroll--col">
        <div class="left--scroll--card d-flex flex-column justify-center">
          <v-btn icon large @click="scrollLeft" class="white" elevation="1">
            <v-icon color="primary" size="30">navigate_before</v-icon>
          </v-btn>
        </div>
        <div class="right--scroll--card d-flex flex-column justify-center">
          <v-btn icon large class="white" elevation="1" @click="scrollRight">
            <v-icon color="primary" size="30">navigate_next</v-icon>
          </v-btn>
        </div>
        <div class="table-responsive" ref="ganttRef">
          <table class="tasks--gantt--table">
            <thead>
              <tr class="task--items--th">
                <th v-for="(item, i) in calendar" :key="i">
                  <v-card
                    flat
                    @click="addNewTask()"
                    :color="getColor(item)"
                    class="d-flex flex-column align-end px-3 mr-1 mb-2 mt-1"
                    :id="item.isCurrent ? 'current-date' : ''"
                  >
                    <div
                      class="text-body-1 text-no-wrap font-weight-medium my-1"
                    >
                      {{ item.week }}
                    </div>
                  </v-card>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in tasks" :key="i" class="task--items">
                <td v-for="p in entryColspan(item).weekNo" :key="p"></td>
                <td class="task--item">
                  <v-chip
                    color="primary"
                    class="white--text view--chip"
                    @click="viewTask(item)"
                    label
                  >
                    <span class="text--chip"
                      >{{ item.activity }} - {{ item.task }}</span
                    >
                    <v-chip
                      small
                      class="ml-3 chip--status"
                      right
                      :color="item.complete ? 'success' : 'grey lighten-3'"
                    >
                      {{ item.complete ? "Complete" : "Incomplete" }}
                    </v-chip>
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
import projectMixin from "../mixins/projectMixin";
import RouteMixin from "../mixins/RouteMixin";
import DateMixin from "../mixins/DateMixin";
export default {
  name: "MilestoneTaskGantt",
  mixins: [projectMixin, RouteMixin, DateMixin],
  data: function () {
    return {
      month: moment(new Date()).format("MMMM"),
    };
  },
  mounted() {
    this.scrollToCurrentDate();
  },
  computed: {
    headers: function () {
      return [
        {
          text: "Milestone",
          value: "milestone",
          align: "left",
          sortable: false,
        },
        {
          text: "Activity",
          value: "description",
          align: "left",
          sortable: false,
        },
        {
          text: "Status",
          value: "complete",
          align: "left",
          sortable: false,
        },
      ];
    },
    entries: function () {
      return this.search
        ? this.$store.getters["Projects/projectGetters"]("activities").filter(
            (item) =>
              item.projectCode
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              item.taskCode.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.$store.getters["Projects/projectGetters"]("activities");
    },
    tasks: function () {
      const tasks = this.entries
        ? this.entries.map((el) => el.activityTasks).shift()
        : [];
      // sort tasks by start date
      return tasks
        ? tasks.length > 0
          ? tasks.sort((a, b) => {
              return new Date(a.startDate) - new Date(b.startDate);
            })
          : []
        : [];
    },
    search: function () {
      return this.$store.getters["Projects/projectGetters"]("search");
    },
    calendar: function () {
      // get number of weeks in current year
      const weeks = moment().weeksInYear();

      const calenderWeeks = [];
      for (let i = 1; i <= weeks; i++) {
        const date = moment().week(i);
        calenderWeeks.push({
          date: this.formatDate(date),
          month: this.months()[date.month()],
          isCurrent: date.isSame(moment(), "day"),
          day: date.format("DD"),
          days: date.weekday() === 0 ? 7 : date.weekday(),
          week: `week ${date.week()} - ${date.format("MMM DD")} - ${date
            .add(6, "days")
            .format("MMM DD")}`,
        });
      }

      return calenderWeeks;
    },
  },
  methods: {
    getDay: function (date) {
      return this.getDayName(date);
    },
    getDayInt: function (date) {
      return this.getDayInt(date) + 1;
    },
    entryColspan: function (item) {
      return {
        weekNo: this.getWeekNumber(item.startDate) - 1,
      };
    },
    addNewTask: function () {
      this.routeTo({
        name: "Task",
        query: {
          milestoneLine: parseInt(this.$route.query.milestoneLine),
          documentNo: this.decryptRoute(this.$route).query.documentNo,
        },
      });
    },
    scrollToCurrentDate() {
      document.getElementById("current-date").scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
    getColor(item) {
      if (item.nonworking) {
        return "grey lighten-3";
      } else if (item.isCurrent) {
        return "primary lighten-2";
      } else {
        return "secondary";
      }
    },
  },
  watch: {
    entries: {
      handler: function () {
        this.scrollToCurrentDate();
      },
      deep: true,
    },
  },
};
</script>
<style>
@import url("../styles.css");
</style>
