import call from "@kinetics254/cassandra-base/service/http";
import projectConstants from "./projectConstants";
export default {
  state: {
    attachments: [],
    attachment: {},
  },
  actions: {
    getMilestoneAttachments({ commit }, params) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", projectConstants.attachments(params))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "attachments", data: res.data.data });
        })
        .catch((err) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getAttachment({ commit }, params) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", projectConstants.attachments(params))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "attachment", data: res.data.data });
        })
        .catch((err) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    deleteMilestoneAttachment({ commit, dispatch }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", projectConstants.deleteAttachment, payload.attachment)
        .then(() => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiSuccess",
            "Milestone account attachment deleted successfully"
          );
          dispatch("getMilestoneLine", {
            milestoneLineNo: payload.line.milestoneLineNo,
            milestoneTableID: payload.line.milestoneTableID,
            entryNo: payload.line.entryNo,
          });
        })
        .catch((err) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
  },
};
