import call from "@kinetics254/cassandra-base/service/http";
import constants from "./SetupConstants";

export default {
  namespaced: true,
  state: {
    leaveEmployees: [],
    hrEmployee: [],
    jobDescription: [],
    profilePicture: "",
    jobs: [],
    trainingProgrammes: [],
    trainees: [],
    taskSetup: [],
    activitiesSetup: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    setupGetter: (state) => (setup) => state[setup],
  },
  actions: {
    getLeaveEmployees: ({ commit }) => {
      call("get", constants.leaveEmployees)
        .then((response) => {
          commit("MUTATE", {
            state: "leaveEmployees",
            data: response.data.data,
          });
        })
        .catch((error) => {
          console.log("employee error", error.response.data.message);
        });
    },
    getHrmisEmployee: ({ commit }, data) => {
      call("get", constants.employee(data))
        .then((response) => {
          commit("MUTATE", {
            state: "hrEmployee",
            data: response.data.data,
          });
        })
        .catch((error) => {
          console.log("employee error", error.response.data.message);
        });
    },
    getJobDescription: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.getJobDescription, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "jobDescription",
            data: res.data.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getProfilePic: ({ commit }, data) => {
      call("post", constants.profilePicture, data)
        .then((res) => {
          commit("MUTATE", {
            state: "profilePicture",
            data: res.data.data === null ? "" : res.data.data,
          });
        })
        .catch((error) => {
          console.log("profile picture error", error.response.data.message);
        });
    },
    getHrmisJobs: ({ commit }) => {
      call("get", constants.jobs)
        .then((res) => {
          commit("MUTATE", {
            state: "jobs",
            data: res.data.data,
          });
        })
        .catch((error) => {
          console.log("jobs error", error.response.data.message);
        });
    },
    getTrainingProgrammes: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.TRAINING_PROGRAMMES, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "trainingProgrammes",
            data: res.data.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getTrainees: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.trainees)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "trainees", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getTaskSetup({ commit }) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.taskSetup)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "taskSetup", data: res.data.data });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getActivitiesSetup({ commit }) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.activitiesSetup)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "activitiesSetup", data: res.data.data });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
  },
};
