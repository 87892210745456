<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title>My Projects </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="projects"
          :search="search"
          :sort-desc="[false, true]"
          multi-sort
          single-expand
          class="task--list"
          :expanded.sync="expanded"
        >
          <!-- eslint-disable vue/valid-v-slot -->
          <template v-slot:item.amount="{ item }">
            {{ item.currencyCode.length > 0 ? item.currencyCode : "" }}
            {{ item.amount | currencyFormat }}
          </template>
          <!-- eslint-disable vue/valid-v-slot -->
          <template v-slot:item.description="{ item }">
            <div class="text-truncate" style="max-width: 200px">
              {{ item.description }}
            </div>
          </template>
          <!-- eslint-disable vue/valid-v-slot -->
          <template v-slot:item.milestones="slotData">
            <v-badge
              :content="slotData.item.milestones.length"
              color="secondary"
              class="white--text"
              overlap
            >
              <v-btn
                color="primary"
                class="white--text text-capitalize"
                @click="viewMilestones(slotData)"
              >
                View Milstones <v-icon class="mx-2">expand_more</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-2">
              <v-list dense flat outlined>
                <v-list-item dense>
                  <v-list-item-title>
                    {{ item.projectNo }} - Milestones
                  </v-list-item-title>
                </v-list-item>
                <v-divider />
                <milestone-list-vue :milestones="item.milestones" />
              </v-list>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import MilestoneListVue from "./MilestoneList.vue";
export default {
  name: "ProjectList",
  components: {
    MilestoneListVue,
  },
  data: function () {
    return {
      search: "",
      expanded: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Projects/getProjects", {
        programmeOfficer: v.user.employee,
      });
    });
  },
  computed: {
    headers: function () {
      return [
        {
          text: "No.",
          value: "no",
          align: "start",
        },
        {
          text: "Project No.",
          value: "projectNo",
        },
        {
          text: "amount",
          value: "amount",
        },
        {
          text: "description",
          value: "description",
        },
        {
          text: "Made On",
          value: "documentDate",
        },
        {
          text: "Funder",
          value: "funderName",
        },
        {
          text: "milestones",
          value: "milestones",
        },
      ];
    },
    projects: function () {
      return this.$store.getters["Projects/projectGetters"]("projects");
    },
    user() {
      return AuthService.user;
    },
    settings() {
      let settings = this.$store.getters["Admin/adminGetters"]("settings");
      return Object.keys(settings).length > 0
        ? JSON.parse(settings.cashmgt_settings)
        : null;
    },
    defaultCurrencyCaption() {
      return this.settings ? this.settings.default_currency_caption : "";
    },
  },
  methods: {
    viewMilestones(slotData) {
      const indexExpanded = this.expanded.findIndex((i) => i === slotData.item);
      if (indexExpanded > -1) {
        this.expanded.splice(indexExpanded, 1);
      } else {
        this.expanded.push(slotData.item);
      }
    },
  },
};
</script>
