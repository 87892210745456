import { ImprestRoutes, ImprestStore } from "./modules/imprest";
import links from "./links";

export default {
  install(Vue, options) {
    /* register store and routes */
    if (options.router) options.router.addRoute(...ImprestRoutes);
    if (options.store) options.store.registerModule("imprest", ImprestStore);

    options.store.commit("Dashboard/ADD_LINK", links);
  },
};
