import trainingRoutes from "./trainingRoutes";
import trainingStore from "./trainingStore";
import links from "./links";

export default {
  install(Vue, options) {
    /* register store and routes */
    if (options.router) options.router.addRoute(...trainingRoutes);
    if (options.store) options.store.registerModule("Training", trainingStore);

    /* register side links */
    options.store.commit("Dashboard/ADD_LINK", links);
  },
};
