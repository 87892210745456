<template>
  <v-toolbar dense rounded flat class="mb-2">
    <v-autocomplete
      v-model="category"
      :items="categories"
      :item-text="(item) => item.Description"
      :item-value="(item) => item.Code"
      @change="filter"
      label="Filter By Category"
      placeholder="Filter By Category"
      prepend-inner-icon="mdi-filter-variant"
      class="filter--category"
      outlined
      dense
      clearable
      hide-details
    />
    <v-autocomplete
      v-model="location"
      :items="locations"
      :item-text="(item) => item.name"
      :item-value="(item) => item.code"
      @change="filter"
      label="Filter By Location"
      placeholder="Filter By Location"
      prepend-inner-icon="mdi-filter-variant"
      class="mx-2 filter--category"
      outlined
      dense
      clearable
      hide-details
    />
    <v-text-field
      v-model="search"
      prepend-inner-icon="mdi-magnify"
      label="Search By Item Description"
      class="search--input"
      single-line
      hide-details
      dense
      clearable
      outlined
      @keyup.enter="filter"
      width="300"
      @click:clear="clearSearch"
    >
      <template v-slot:append>
        <v-btn @click="filter" class="primary text-capitalize search--btn">
          Search
          <v-icon right>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <v-switch
      inset
      v-model="filterByVariance"
      class="mt-4 mx-2"
      @change="filter"
    >
      <template v-slot:label>
        <span class="text-caption font-weight-bold"
          >View Items To Replenish</span
        >
      </template>
    </v-switch>
    <v-btn
      class="primary text-capitalize mx-2"
      @click="exportReplenishItemsToExcel"
    >
      Export To Excel
    </v-btn>
    <!-- <v-btn
      small
      icon
      class="primary ml-3 mr-1 white--text"
      @click="refreshList"
    >
      <v-icon>refresh</v-icon>
    </v-btn> -->
  </v-toolbar>
</template>
<script>
import inventoryMixin from "../inventoryMixin";
export default {
  name: "ListActions",
  mixins: [inventoryMixin],
  data() {
    return {
      search: "",
      category: "",
      location: "",
      filterByVariance: true,
    };
  },
  methods: {
    clearSearch() {
      this.search = "";
      this.filter();
    },
    refreshList() {
      this.$store.dispatch("Inventory/getItems");
      this.$store.dispatch("Inventory/getCategories");
      this.$store.dispatch("Inventory/getLocations");
    },
  },
};
</script>
<style>
.search--input > .v-input__control > .v-input__slot > .v-input__append-inner {
  margin: 0 0 0 0 !important;
}
.search--input {
  width: 20rem !important;
}
.filter--category {
  width: 15rem !important;
}
.search--input > .v-input__control > .v-input__slot {
  padding: 0 0 0 12px !important;
}
.search--btn.v-btn:not(.v-btn--round).v-size--default {
  height: 38.9px !important;
}
.search--btn.v-btn {
  border-radius: 0 4px 4px 0 !important;
}
</style>
