<template>
  <!--Acceptance Dialog-->
  <v-dialog
    v-model="dialog"
    max-width="800px"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="stage">
        <div class="px-2">{{ stage.acceptanceText }}</div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          class="white--text green mx-2"
          :loading="loading"
          :disabled="loading"
          @click="acceptAndSendForApproval"
        >
          <v-icon left color="white"> mdi-check </v-icon>
          ACCEPT
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RouteMixin from '@kinetics254/cassandra-base/mixins/RouterMixin';
export default {
  name: "Acceptance",
  mixins:[RouteMixin],
  props: {
    acceptanceDialog: Boolean,
  },
  data() {
    return {
      loader: null,
      loading: false,
    };
  },

  created() {
    // listener for the "redirect-applications" event
    Event.$on("redirect-applications", () => {
      this.$router.push({ name: "Applications" });
    });
  },
  computed: {
    userData() {
      return this.$store.getters["auth/user"];
    },
    selectedPeriod() {
      return this.$store.getters["appraisal/appraisalGetter"]("selectedPeriod");
    },
    stage() {
      const periods =
        this.$store.getters["appraisal/appraisalGetter"]("periods");
      const currentPeriod = periods?.find((period) => {
        return period.code === this.decodeRoute(this.$route.params.period); 
      });
      const stage = currentPeriod?.periodStages.find((stage) => {
        return stage.current === true;
      });
      return stage;
    },
    dialog: {
      get() {
        return this.acceptanceDialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    routeParams() {
      return this.decodeRoute(this.$route.params.applicationNo) ?? null;
    },
  },
  methods: {
    acceptAndSendForApproval() {
      this.$store.dispatch("appraisal/sendForApproval", {
        applicationNo: this.routeParams,
      });
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
  },
};
</script>
<style>
/** */
</style>
