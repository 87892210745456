import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" Approval Entries ")]),_c(VCardText,[(_vm.approvalEntries !== 0)?_c(VTimeline,{staticClass:"mx-10",attrs:{"dense":""}},_vm._l((_vm.approvalEntries),function(item,i){return _c(VTimelineItem,{key:i,attrs:{"color":_vm.getColor(item.status).icon_color,"small":"","fill-dot":"","icon":_vm.getColor(item.status).icon}},[_c(VRow,[_c(VCol,{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex justify-start"},[_c(VIcon,[_vm._v("schedule")]),_c('strong',[_vm._v(_vm._s(_vm.getEntryDateFormat(item.date_time_sent_for_approval)))])],1),_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticClass:"text-lg-h6"},[_vm._v("Entry: "+_vm._s(item.entry_no))]),_c(VChip,{staticClass:"mx-1 my-1",attrs:{"label":"","color":_vm.getColor(item.status).icon_color,"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]),_c(VCol,{attrs:{"cols":"7"}},[_c(VListItem,[_c(VListItemAvatar,{staticStyle:{"color":"rgb(0, 68, 139)"},attrs:{"color":_vm.getColor(item.status).icon_color,"size":"50"}},[_vm._v(" "+_vm._s(item.approver !== undefined ? item.approver.number : "N/A")+" ")]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.approver !== undefined ? item.approver.firstName : "N/A")+" "+_vm._s(item.approver !== undefined ? item.approver.lastName : "N/A")+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(item.approver !== undefined ? item.approver.email : "N/A")+" ")])],1)],1)],1),_c(VListItem,{directives:[{name:"show",rawName:"v-show",value:(item.Comment),expression:"item.Comment"}]},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Comments ")]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.comments))])],1)],1)],1)],1)}),1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }