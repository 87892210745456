import call from "@kinetics254/cassandra-base/service/http";
import constants from "./inventoryConstants";

export default {
  namespaced: true,
  state: {
    items: [],
    categories: [],
    locations: [],
    isDataLoaded: false,
    excelLink: "",
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    inventoryGetters: (state) => (val) => state[val],
  },
  actions: {
    getItems: ({ commit }, payload) => {
      commit("MUTATE", { state: "isDataLoaded", data: false });
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.items(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "items", data: res.data.data });
          commit("MUTATE", { state: "isDataLoaded", data: true });
          if (payload.exportToExcel) {
            commit("MUTATE", { state: "excelLink", data: res.data.data });
          }
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    exportToExcel({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.items(payload))
        .then((res) => {
          commit("MUTATE", { state: "excelLink", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getCategories: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.categories)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "categories", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getLocations: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.locations)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "locations",
            data: res.data.data
              ? res.data.data.filter((l) => l.Is_Main_Store)
              : [],
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
