import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardText,[_c(VCard,{staticClass:"main-card",attrs:{"elevation":"0","tile":""}},[_c(VCardTitle,{staticClass:"overline header-title"},[_c('div',{staticClass:"caption"},[_c('span',{staticClass:"description"},[_vm._v("Appraisal Guidelines ")])]),_c(VChip,{staticClass:"ml-2",attrs:{"x-small":"","dark":"","color":"primary"}},[_vm._v(_vm._s(_vm.period))])],1),_c(VDivider),(_vm.isDataLoaded && _vm.appraisalGuideLines)?_c(VCardText,[(_vm.getAppraisalGuidelines() !== '')?_c(VCardText,{domProps:{"innerHTML":_vm._s(_vm.getAppraisalGuidelines())}}):_c(VCardText,[_vm._v(" No Guidelines Found ")])],1):_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VSkeletonLoader,{attrs:{"type":"card-heading, divider, list-item-three-line, card-heading","loading":true}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }