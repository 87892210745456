export default {
  namespaced: true,
  state: {},
  mutations: {
    MUTATE: (state, { type, data }) => (state[type] = data),
  },
  getters: {
    reportGetters: (state) => (payload) => state[payload.type],
  },
  actions: {},
};
