<template>
  <div>
    <v-row>
      <v-col lg="6" md="6" sm="12">
        <div class="mt-10 pt-3">
          <div class="d-flex flex-column mt-5">
            <Logo class="align-self-center" />
          </div>
          <div>
            <Login />
          </div>
          <v-footer absolute color="transparent" class="black--text">
            <v-col lg="6" md="6" sm="12">
              <div class="d-flex justify-space-between">
                <div class="text-body-2 align-self-start">
                  {{ appName | capitalize }} © {{ new Date().getFullYear() }} —
                  <strong>Kinetic Technology LTD</strong>
                </div>
                <div class="text-body-2 align-self-end">
                  {{ company.displayName }}
                </div>
              </div>
            </v-col>
          </v-footer>
        </div>
      </v-col>
      <v-col lg="6" md="6" sm="12" v-if="$vuetify.breakpoint.mdAndUp">
        <div class="landing--left">
          <div class="landing--pattern1">
            <div class="d-flex flex-column white--text">
              <div class="align-self-center mt-7">
                <v-img :src="getImage" height="100%" width="100%" />
              </div>
              <div class="align-self-center text-center text-h5 mt-5">
                Welcome to Employee Self Service!
              </div>
              <div class="align-self-center text-center text-body">
                Get employee services such as leave management, cash management
                and performance management online
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style>
@import url("../../styles.css");
</style>
<script>
import Logo from "../../../../app/components/Logo.vue";
import Login from "../components/login.vue";
import { helper } from "@/packages/cassandra-base/utilities";
export default {
  name: "Landing",
  components: { Login, Logo },
  data: function () {
    return {
      cycle: false,
      landingPageImage: null,
    };
  },
  computed: {
    company: function () {
      return this.$store.getters["auth/company"];
    },
    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },
    getImage() {
      return this.landingPageImage
        ? URL.createObjectURL(this.landingPageImage)
        : require("@/packages/cassandra-base/assets/landing_page.png");
    },
  },
  watch: {
    settings: {
      handler: async function (val) {
        if (val) {
          if (val.site_landing_page_image) {
            this.landingPageImage = await helper.getFileBlob(
              val.site_landing_page_image,
              "landing_page.png"
            );
          }
        }
      },
      deep: true,
    },
  },
};
</script>
