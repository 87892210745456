<template>
  <v-container fluid class="window">
    <iframe
      title="Membership Statistics report"
      width="100%"
      height="100%"
      src="https://app.powerbi.com/view?r=eyJrIjoiNzJlOThkYjEtNzE4ZC00ZTc0LTljMGEtYzdhMjhhZjJmM2UwIiwidCI6IjRkY2FkNjNlLTM5ZTgtNDRlMC1iNzM0LTBjNTgxZjcwNTU2MSIsImMiOjl9"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  </v-container>
</template>
<script>
export default {
  name: "DashboardReport",
};
</script>
<style scope>
.window {
  height: 80vh;
  width: 100vw;
}
iframe {
  width: 100%;
  height: 100%;
}
</style>
