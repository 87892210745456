import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{staticClass:"mx-auto mt-5",attrs:{"dense":"","elevation":"0","color":"transparent"}},[_c('div',{staticClass:"headline text-lg-h6 header-title"},[_vm._v(" Leave Allowance Approval Requests ")])]),_c(VCard,{staticClass:"mx-auto mt-4",attrs:{"elevation":"0","flat":""}},[_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"ml-md-4",attrs:{"cols":"12","md":"4","sm":"12"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"color":"primary","dense":"","label":"Filter by Posting Date","prepend-inner-icon":"filter_alt"},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.filterDatePicker),callback:function ($$v) {_vm.filterDatePicker=$$v},expression:"filterDatePicker"}},[_c(VDatePicker,{attrs:{"range":""},on:{"input":function($event){(_vm.filterDatePicker = false),
                  (_vm.startDate = _vm.filterDate[0]),
                  (_vm.endDate = _vm.filterDate[1])}},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}})],1)],1),_c(VSpacer),_c(VCol,{staticClass:"mr-md-4",attrs:{"cols":"12","md":"4","sm":"12"}},[_c(VTextField,{staticClass:"mt-n3",attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VRow,{staticClass:"no-gutters mt-3"},[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VSheet,{},[_c('div',{staticClass:"px-3 py-3"},[_c(VDataTable,{ref:"allowancetable",staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.allowances,"sort-desc":_vm.sortDesc,"multi-sort":"","items-per-page":8,"id":"scollStyle","search":_vm.search,"sort-by":_vm.sortBy},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c(VChip,{attrs:{"small":"","label":"","color":_vm.getColor(item.status).color}},[_c('div',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(item.status))])])]}},{key:"item.employeeName",fn:function(ref){
                  var item = ref.item;
return [_c(VChip,{staticClass:"black--text",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(item.employeeName)+" ")])]}},{key:"item.applicationDate",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.applicationDate))+" ")]}},{key:"item.postingDate",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.postingDate))+" ")]}},{key:"item.amount",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.amount))+" ")]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c(VBtn,{staticClass:"mx-1",attrs:{"dense":"","small":"","color":"primary","to":("allowanceForm?approval=" + (item.applicationCode))}},[_c('div',{staticClass:"px-2"},[_vm._v("View Allowance")]),_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}])})],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }