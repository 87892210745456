import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";
import Imprest from "./views/Imprest";
import ImprestList from "./components/ImprestList";
import AccountedList from "./components/accountedList";
import ImprestAccounting from "./components/ImprestAccounting";
import ReceiptAccounting from "./components/ImprestReceiptAccounting";
import ImprestPendingApprovalsList from "./components/ImprestPendingApprovalsList";
import ImprestCard from "./components/ImprestCard";
import ImprestDashboard from "./components/ImprestDashboard";
import ImprestDocument from "./components/ImprestDocument";
import ImprestCashComment from "./components/ImprestCashComment";
import { Auth } from "@kinetics254/cassandra-base/modules/auth";
import ImprestSurrenderDocument from "./components/ImprestSurrenderDocument";

export default [
  {
    path: "/imprest",
    component: AppDashboard,
    children: [
      {
        path: "",
        components: { view: Imprest },
        children: [
          {
            path: "",
            redirect: "/imprest/list",
            meta: {
              middleware: Auth,
            },
          },
          {
            path: "list",
            name: "Imprest List",
            component: ImprestList,
            meta: {
              middleware: Auth,
            },
          },
          {
            path: "approvals",
            name: "ApprovalsList",
            component: ImprestPendingApprovalsList,
            meta: {
              middleware: Auth,
            },
          },
          {
            path: "card/:code?",
            name: "ImprestCard",
            component: ImprestCard,
            meta: {
              middleware: Auth,
            },
          },
          {
            path: "account/:code?",
            name: "ImprestAccounting",
            component: ImprestAccounting,
            meta: {
              middleware: Auth,
              breadcrumb: [
                { text: "Dashboard", disabled: false, href: "/dashboard" },
                { text: "Imprest", disabled: false, href: "/imprest" },
                { text: "My Requests", disabled: false, href: "/imprest" },
                {
                  text: "Accounting",
                  disabled: true,
                  href: "/imprest/accounting",
                },
              ],
            },
          },
          {
            path: "Calc",
            name: "CalcImprest",
            component: AccountedList,
          },
          {
            path: "approve/:approveCode",
            name: "ImprestApprove",
            component: ImprestCard,
            meta: {
              middleware: Auth,
            },
          },
          {
            path: "account/receipt-accounting/:code?",
            name: "ReceiptAccounting",
            component: ReceiptAccounting,
            meta: {
              middleware: Auth,
            },
          },
          {
            path: "dashboard",
            name: "ImprestDashboard",
            component: ImprestDashboard,
            meta: {
              middleware: Auth,
            },
          },
          {
            path: "imprestSurrenderDoc/:docNo?",
            name: "ImprestSurrenderDocument",
            component: ImprestSurrenderDocument,
            meta: {
              middleware: Auth,
            },
          },
          {
            path: "imprestDoc/:docNo?",
            name: "ImprestDocument",
            component: ImprestDocument,
            meta: {
              middleware: Auth,
            },
          },
        ],
      },
    ],
  },
];
