export default {
  getAuthEmployee: "employee/getAuthenticatedEmployee",
  leaveEmployee: (params) => "hrmis/leave_employee?" + getParams(params),
};

function getParams(object) {
  return Object.keys(object)
    .map((key) => key + "=" + object[key])
    .join("&");
}
