import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4","lg":"4","sm":"12"}},[_c(VDataTable,{staticClass:"tasks--gantt--table--list",attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.entries},scopedSlots:_vm._u([{key:"item.complete",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":item.complete ? 'success' : 'grey lighten-3'}},[_vm._v(" "+_vm._s(item.complete ? "Complete" : "Incomplete")+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"50%"}},[_vm._v(_vm._s(item.description))])]}}])})],1),_c(VCol,{staticClass:"scroll--col",attrs:{"cols":"12","md":"8","lg":"8","sm":"12"}},[_c('div',{staticClass:"left--scroll--card d-flex flex-column justify-center"},[_c(VBtn,{staticClass:"white",attrs:{"icon":"","large":"","elevation":"1"},on:{"click":_vm.scrollLeft}},[_c(VIcon,{attrs:{"color":"primary","size":"30"}},[_vm._v("navigate_before")])],1)],1),_c('div',{staticClass:"right--scroll--card d-flex flex-column justify-center"},[_c(VBtn,{staticClass:"white",attrs:{"icon":"","large":"","elevation":"1"},on:{"click":_vm.scrollRight}},[_c(VIcon,{attrs:{"color":"primary","size":"30"}},[_vm._v("navigate_next")])],1)],1),_c('div',{ref:"ganttRef",staticClass:"table-responsive"},[_c('table',{staticClass:"tasks--gantt--table"},[_c('thead',[_c('tr',{staticClass:"task--items--th"},_vm._l((_vm.calendar),function(item,i){return _c('th',{key:i},[_c(VCard,{staticClass:"d-flex flex-column align-end px-3 mr-1 mb-2 mt-1",attrs:{"flat":"","color":_vm.getColor(item),"id":item.isCurrent ? 'current-date' : ''},on:{"click":function($event){return _vm.addNewTask()}}},[_c('div',{staticClass:"text-body-1 text-no-wrap font-weight-medium my-1"},[_vm._v(" "+_vm._s(item.week)+" ")])])],1)}),0)]),_c('tbody',_vm._l((_vm.tasks),function(item,i){return _c('tr',{key:i,staticClass:"task--items"},[_vm._l((_vm.entryColspan(item).weekNo),function(p){return _c('td',{key:p})}),_c('td',{staticClass:"task--item"},[_c(VChip,{staticClass:"white--text view--chip",attrs:{"color":"primary","label":""},on:{"click":function($event){return _vm.viewTask(item)}}},[_c('span',{staticClass:"text--chip"},[_vm._v(_vm._s(item.activity)+" - "+_vm._s(item.task))]),_c(VChip,{staticClass:"ml-3 chip--status",attrs:{"small":"","right":"","color":item.complete ? 'success' : 'grey lighten-3'}},[_vm._v(" "+_vm._s(item.complete ? "Complete" : "Incomplete")+" ")])],1)],1)],2)}),0)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }