<template>
  <v-card class="mx-auto mb-3" flat>
    <v-card-title class="text-body-2">
      {{ isEdit ? "Upload" : "" }} Attachments
      <v-spacer />
      <v-btn
        class="primary text-capitalize"
        v-if="isEdit"
        rounded
        @click="attachFile"
        >Add Attachment
        <v-icon right>attach_file</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-data-table
      :headers="headers"
      :items="attachments"
      class="elevation-0"
      dense
      disable-pagination
      hide-default-footer
      hide-default-header
    >
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.no="{ item }">
        <span v-if="item.no">{{ item.no }}</span>
        <span v-else>{{ item.fileName }}</span>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <div class="d-flex px-2">
            <v-btn
              rounded
              small
              class="primary text-capitalize mx-2"
              @click="downloadFile(item.attachment, item.fileName)"
            >
              Download
              <v-icon right>download</v-icon>
            </v-btn>
            <v-btn
              rounded
              small
              v-if="isEdit"
              class="error text-capitalize"
              @click="deleteAttachment(item)"
            >
              Delete
              <v-icon right>delete</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
    <input
      type="file"
      ref="file"
      @change="uploadFile"
      hidden
      accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/*"
    />
  </v-card>
</template>
<script>
import FileMixin from "@/packages/cassandra-base/mixins/FileMixin";
import { helper } from "@/packages/cassandra-base/utilities";
export default {
  name: "MilestoneAttachments",
  mixins: [FileMixin],
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    line: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      //
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          value: "no",
        },
        {
          text: "fileName",
          value: "fileName",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ];
    },
  },
  methods: {
    deleteAttachment(attachment) {
      if (attachment.tableID) {
        this.$confirm.show({
          title: "Delete Attachment",
          text: "Are you sure you want to delete this attachment? This action cannot be undone.",
          onConfirm: () => {
            this.$store.dispatch("Milestone/deleteMilestoneAttachment", {
              attachment: attachment,
              line: this.line,
            });
          },
        });
      } else {
        this.$emit("removeAttachment", attachment);
      }
    },
    attachFile() {
      this.$refs.file.click();
    },
    async uploadFile(e) {
      const file = e.target.files[0];
      if (file) {
        let base64File = await helper.toBase64(file);
        base64File = base64File.split(",")[1];
        this.$emit("addAttachment", {
          attachment: base64File,
          fileName: file.name,
        });
      }
    },
  },
};
</script>
