import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800px","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),(_vm.stage)?_c(VCardText,[_c('div',{staticClass:"px-2"},[_vm._v(_vm._s(_vm.stage.acceptanceText))])]):_vm._e(),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{staticClass:"white--text green mx-2",attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.acceptAndSendForApproval}},[_c(VIcon,{attrs:{"left":"","color":"white"}},[_vm._v(" mdi-check ")]),_vm._v(" ACCEPT ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }